import React, { useState } from 'react';
import { Link as DropdownLink, useLocation } from 'react-router-dom';
import { useAuth } from 'context/authContext';
import { doSignOut } from 'firebaseConfig/authentication';
// import { useAuth0 } from '@auth0/auth0-react';

import {
  MdAccountBox,
  MdExitToApp,
  MdInfo,
  MdAttachMoney,
  MdMail,
  MdHome,
  MdSearch,
  MdLibraryBooks,
  MdGavel,
} from 'react-icons/md';
import { FaBrain } from 'react-icons/fa';
import { FaBookOpen } from 'react-icons/fa6';
import { IThemedComponentProps } from 'interfaces/interfaces';

export default function Dropdown({ darkMode }: IThemedComponentProps): JSX.Element {
    const [overHome, setOverHome] = useState(false);
    const [overKanjiSearch, setOverKanjiSearch] = useState(false);
    const [overFlashcards, setOverFlashcards] = useState(false);
    const [overTheory, setOverTheory] = useState(false);
    const [overAbout, setOverAbout] = useState(false);
    const [overAccount, setOverAccount] = useState(false);

    const location = useLocation();
    const { userLoggedIn } = useAuth();
    
    // AUTH0
    // const {
    //     isAuthenticated,
    //     loginWithRedirect,
    //     logout,
    // } = useAuth0();

    const pathname = location.pathname;
    const homePath = '/';
    const kanjiSearchPath = '/language/kanjisearch';
    const flashcardsPath = '/language/flashcards';
    const selfStudyPath = '/language/selfstudy';
    const theoryPath = '/language/theory';
    const aboutPath = '/siteinfo/about';
    const contactPath = '/siteinfo/contact';
    const donatePath = '/siteinfo/donate';
    const legalPath = '/siteinfo/legal';
    const profilePath = '/profile';
    const dropdownAboutPaths = [aboutPath, contactPath, donatePath, legalPath];
    const iconStyles = { marginTop: '4px' , color: darkMode ? 'var(--darkBgColor)' : 'var(--whiteColor)' };
    const aboutIconStyles = { marginTop: '0px', color: darkMode ? 'var(--darkBgColor)' : 'var(--whiteColor)' };
    const menuIconStyles = { margin: '0 8px', color: darkMode ? 'var(--mutedRedColor)' : 'var(--redColor)' };
    const selectedLinkColor = darkMode ? 'var(--mutedRedColor)' : 'var(--redColor)';
    const unselectedLinkColor = darkMode ? 'var(--mediumGrayColor)' : 'var(--blackColor)';

    interface IAboutIconProps {
        pathname: string;
    }

    function AboutIcon({ pathname }: IAboutIconProps): JSX.Element {
        switch (pathname) {
            case aboutPath:
                return <MdInfo size="18px" style={aboutIconStyles}/>;
            case contactPath:
                return <MdMail size="18px" style={aboutIconStyles}/>;
            case donatePath:
                return <MdAttachMoney size="19px" style={aboutIconStyles}/>;
            case legalPath:
                return <MdGavel size="17px" style={aboutIconStyles}/>;
            default:
                return <div/>
        }
    }

    function handleSignInOut(): void {
        if (userLoggedIn) {
            doSignOut();
        }
    }

    return (
        <div className="dropdown-container">
            {/* HOME */}
            <li className="dropdown-tab">
                <DropdownLink
                    className="dropdown-tab-link"
                    to={homePath}
                    onMouseEnter={() => setOverHome(true)}
                    onMouseLeave={() => setOverHome(false)}
                    style={{ color: pathname === homePath || overHome ? selectedLinkColor : unselectedLinkColor }}
                >
                    Home
                    <div className="dropdown-underline" style={{ display: pathname === homePath ? 'block' : 'none'}}>
                        <MdHome size="18px" style={iconStyles}/>
                    </div>
                </DropdownLink>
            </li>

            {/* FLASHCARDS */}
            <li className="dropdown-tab">
                <DropdownLink
                    className="dropdown-tab-link"
                    to={flashcardsPath}
                    onMouseEnter={() => setOverFlashcards(true)}
                    onMouseLeave={() => setOverFlashcards(false)}
                    style={{ color: pathname === flashcardsPath || overFlashcards ?  selectedLinkColor : unselectedLinkColor }}
                >
                    Flashcards
                    <div className="dropdown-underline" style={{ display: pathname === flashcardsPath ? 'block' : 'none'}}>
                        <MdLibraryBooks size="17px" style={iconStyles}/>
                    </div>
                </DropdownLink>
            </li>

            {/* SELF STUDY */}
            <li className="dropdown-tab">
                <DropdownLink
                    className="dropdown-tab-link"
                    to={selfStudyPath}
                    onMouseEnter={() => setOverFlashcards(true)}
                    onMouseLeave={() => setOverFlashcards(false)}
                    style={{ color: pathname === selfStudyPath || overFlashcards ?  selectedLinkColor : unselectedLinkColor }}
                >
                    Self Study
                    <div className="dropdown-underline" style={{ display: pathname === selfStudyPath ? 'block' : 'none'}}>
                        <FaBookOpen size="16px" style={iconStyles}/>
                    </div>
                </DropdownLink>
            </li>

            {/* KANJI SEARCH */}
            <li className="dropdown-tab">
                <DropdownLink
                    className="dropdown-tab-link"
                    to={kanjiSearchPath}
                    onMouseEnter={() => setOverKanjiSearch(true)}
                    onMouseLeave={() => setOverKanjiSearch(false)}
                    style={{ color: pathname === kanjiSearchPath || overKanjiSearch ?  selectedLinkColor : unselectedLinkColor }}
                >
                    Kanji Search
                    <div className="dropdown-underline" style={{ display: pathname === kanjiSearchPath ? 'block' : 'none'}}>
                        <MdSearch size="21px" style={iconStyles}/>
                    </div>
                </DropdownLink>
            </li>

            {/* THEORY */}
            <li className="dropdown-tab">
                <DropdownLink
                    className="dropdown-tab-link"
                    to={theoryPath}
                    onMouseEnter={() => setOverTheory(true)}
                    onMouseLeave={() => setOverTheory(false)}
                    style={{ color: pathname === theoryPath || overTheory?  selectedLinkColor : unselectedLinkColor }}
                >
                    Theory
                    <div className="dropdown-underline" style={{ display: pathname === theoryPath ? 'block' : 'none'}}>
                        <FaBrain size="17px" style={iconStyles}/>
                    </div>
                </DropdownLink>
            </li>

            {/* ABOUT DROPDOWN */}
            <li className="dropdown-tab">
                <div
                    className="dropdown-option"
                    onMouseEnter={() => setOverAbout(true)}
                    onMouseLeave={() => setOverAbout(false)}
                    style={{ color: pathname === aboutPath || overAbout ?  selectedLinkColor : unselectedLinkColor }}
                >
                    About
                </div>
                <div className="dropdown-underline about" style={{ display: dropdownAboutPaths.includes(pathname) ? 'block' : 'none' }}>
                    <AboutIcon pathname={pathname}/>
                </div>
                <div className="dropdown-submenu-container about">
                    <div className="dropdown-submenu">
                        <ul>
                            <li>
                                <DropdownLink className="dropdown-submenu-link" to={aboutPath}>
                                    <MdInfo size="18px" style={menuIconStyles} title="account profile"/>
                                    About
                                </DropdownLink>
                            </li>
                            <li>
                                <DropdownLink className="dropdown-submenu-link" to={contactPath}>
                                    <MdMail size="18px" style={menuIconStyles} title="account profile"/>
                                    Contact
                                </DropdownLink>
                            </li>
                            <li>
                                <DropdownLink className="dropdown-submenu-link" to={donatePath}>
                                    <MdAttachMoney size="18px" style={menuIconStyles} title="account profile"/>
                                    Donate
                                </DropdownLink>
                            </li>
                            <li>
                                <DropdownLink className="dropdown-submenu-link" to={legalPath}>
                                    <MdGavel size="16px" style={menuIconStyles} title="account profile"/>
                                    Legal
                                </DropdownLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>

            {/* SIGN IN */}
            <li className="dropdown-tab sign-in">
                {userLoggedIn ?
                    <button
                        className="dropdown-sign-out"
                        onClick={() => handleSignInOut()}
                        onMouseEnter={() => setOverAccount(true)}
                        onMouseLeave={() => setOverAccount(false)}
                        style={{
                            color: pathname === profilePath || overAccount ?  selectedLinkColor : unselectedLinkColor,
                        }}
                    >
                            <MdExitToApp
                                size="22px"
                                style={menuIconStyles}
                            />
                        Sign Out
                    </button> :

                    <DropdownLink
                        to="/auth/signin"
                        className="dropdown-tab-link"
                        onMouseEnter={() => setOverAccount(true)}
                        onMouseLeave={() => setOverAccount(false)}
                        style={{
                            color: pathname === profilePath || overAccount ?  selectedLinkColor : unselectedLinkColor,
                            cursor: 'pointer',
                        }}
                    >
                            <MdAccountBox
                                size="22px"
                                style={menuIconStyles}
                            />
                        Sign In
                    </DropdownLink>
                }

                {/* <div className={`dropdown-underline account-sign-in ${pathname === profilePath ? 'show' : 'hide'}`}/> */}

                {/* {userLoggedIn &&
                    <div className="dropdown-submenu-container authenticated">
                        <div className="dropdown-submenu">
                            <ul>
                                <li>
                                    <DropdownLink className="dropdown-submenu-link" to={profilePath}>
                                        <MdAccountBox size="18px" style={menuIconStyles} title="account profile"/>
                                        Profile
                                    </DropdownLink>
                                </li>
                                <li>
                                    <button className="dropdown-submenu-link" onClick={() => {}}>
                                        <MdExitToApp size="18px" style={menuIconStyles} title="sign out"/>
                                        Sign Out
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                } */}
            </li>
        </div>
    );
};