import React, { useContext, useEffect} from 'react';
import { MdInfo } from 'react-icons/md';
import { Context } from 'context/context';
import { IContext } from 'interfaces/interfaces';

export default function About(): JSX.Element {
    const { setFlashcardsPage } = useContext(Context) as IContext;

    useEffect(() => {
        setFlashcardsPage(false);
    }, []);

    return (
        <div className="page-container">
            <div className="page-content">
                <h6>ABOUT KANJI REMASTERED</h6>
                <MdInfo size="100px" className="siteinfo-red-icon"/>
                <p>
                    A Japanese kanji learning resource with two main features: 
                    flashcards to learn 3,000 kanji and a searchable dictionary of kanji and mnemonic devices. Kanji Remastered is an independently owned and managed web project.
                </p>
                <br/><br/>

                <h6>FLASHCARDS</h6>
                <br/>
                <p>The flashcard system breaks down the 3,000 most commonly used Japanese kanji into three levels, Beginner, Intermediate, and Advanced, of 10 sets of 100 Kanji each, for a total of 1,000 kanji per level.</p>
                <br/>
                <p>
                    Each kanji flashcard has a mnemonic device that improves on the Heisig method, maximizing it with concise stories that teach the important concepts of kanji all at once: 
                    meaning, structure, and pronunciation. The mnemonic devices were created uniquely for the Kanji Remastered.
                </p>
                <br/><br/>

                <h6>KANJI SEARCH</h6>
                <br/>
                <p>A custom designed and searchable dictionary of Kanji Remastered's mnemonic devices. Each search for a kanji or radical will return all derivative kanjis that also contain it.</p>
                <br/><br/>

                <h6>THEORY</h6>
                <br/>
                <p>Learn the simple theory behind Kanji Remastered's flashcard system so you can remember kanji for life!</p>
                <br/>
            </div>
        </div>
    );
};