import { HIGHLIGHT_DELIMITER } from 'constants/constants';

function findPreviousBlank(segment: string): number {
    for (let blank = segment.length; blank >= 0; blank--) {
        if (segment.charAt(blank) === ' ' || segment.charAt(blank) === '#') {
            return blank;
        }
    }

    return -1;
}

function splitMnemonic(mnemonic: string, isMeaningRadicals: boolean, isOnyomiKunyomi: boolean): string {
    /*
        It's assumed that if the mnemonic has a delimiter that splits it between
        meaning-radical highlights and onyomi-kunyomi ones, there will only be
        delimiter with a blank space. The delimietr will have a single blank space
        before it and no spaces after it.

        EX: "One.m is just one horizontal line.r. @Each.o one is a hit.k."
    */
    const delimeter = '@';
    const index = mnemonic.indexOf(delimeter);

    // HAS A DELIMITER
    if (index > -1) {
        const meaningsRadicalsHalf = mnemonic.slice(0, index - 1); // delimiter -1 because of blank space before delimiter
        const onyomiKunyomiHalf = mnemonic.slice(index + 1);
        if (isMeaningRadicals && !isOnyomiKunyomi) {
            return meaningsRadicalsHalf;
        }
        if (!isMeaningRadicals && isOnyomiKunyomi) {
            return onyomiKunyomiHalf;
        }
        return `${meaningsRadicalsHalf} ${onyomiKunyomiHalf}`;
    }

    // IS A RADCICAL AND NO DELIMETER
    if (!isMeaningRadicals && isOnyomiKunyomi) {
        return 'This radical has no readings.';
    }

    // NEITHER RADIACAL NOR DELIMETER
    return mnemonic;
}

export default function Mnemonic(mnemonic: string, isMeaningRadicals = true, isOnyomiKunyomi = true): (JSX.Element | string)[] {
    // Highlights mnemonic text to match with mnemonic device

    const arr: (JSX.Element | string)[] = [];
    if (mnemonic) {
        mnemonic = splitMnemonic(mnemonic, isMeaningRadicals, isOnyomiKunyomi);

        const tags = ['.m', '.r', '.o', '.k'];
        let previousTagIndex = 0;

        for (let i=0; i < mnemonic.length - 1; i++) {
            const twoCharSlice = mnemonic.slice(i, i + 2);

            if (tags.includes(twoCharSlice)) {
                const highlight = twoCharSlice[1] + '-highlight';
                const blank = findPreviousBlank(mnemonic.slice(0, i));

                // Compounds highlighted word with unhighlighted one without the hashtag #
                if (mnemonic.charAt(blank) === HIGHLIGHT_DELIMITER) {
                    arr.push(mnemonic.slice(previousTagIndex, blank)); // strips the hashtag #
                } else {
                    arr.push(mnemonic.slice(previousTagIndex, blank + 1));
                }

                arr.push(<span className={highlight} key={i}>{mnemonic.slice(blank + 1, i)}</span>);
                /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
                previousTagIndex = i + 2;
            }
        }
        arr.push(mnemonic.slice(previousTagIndex));
    }

    return arr;
}