const kanaMap: { [key: string]: string; } = {
    '.': '.',

    // HIRAGANA, DAKUTEN AND HANDAKUTEN
    a: 'あ',
    i: 'い',
    u: 'う',
    e: 'え',
    o: 'お',

    ka: 'か',
    ki: 'き',
    ku: 'く',
    ke: 'け',
    ko: 'こ',

    ga: 'が',
    gi: 'ぎ',
    gu: 'ぐ',
    ge: 'げ',
    go: 'ご',

    sa: 'さ',
    shi: 'し',
    su: 'す',
    se: 'せ',
    so: 'そ',

    za: 'ざ',
    ji: 'じ',
    zu: 'ず',
    ze: 'ぜ',
    zo: 'ぞ',

    ta: 'た',
    chi: 'ち',
    tsu: 'つ',
    te: 'て',
    to: 'と',

    da: 'だ',
    dji: 'ぢ',
    dzu: 'づ',
    de: 'で',
    do: 'ど',

    na: 'な',
    ni: 'に',
    nu: 'ぬ',
    ne: 'ね',
    no: 'の',

    ha: 'は',
    hi: 'ひ',
    fu: 'ふ',
    he: 'へ',
    ho: 'ほ',

    ba: 'ば',
    bi: 'び',
    bu: 'ぶ',
    be: 'べ',
    bo: 'ぼ',

    pa: 'ぱ',
    pi: 'ぴ',
    pu: 'ぷ',
    pe: 'ぺ',
    po: 'ぽ',

    ma: 'ま',
    mi: 'み',
    mu: 'む',
    me: 'め',
    mo: 'も',

    ya: 'や',
    yu: 'ゆ',
    yo: 'よ',

    ra: 'ら',
    ri: 'り',
    ru: 'る',
    re: 'れ',
    ro: 'ろ',

    wa: 'わ',
    wo: 'を',

    n: 'ん',
    "n'": 'ん',

    hiraganaChiisaiTsu: 'っ',

    // YOUON
    kya: 'きゃ',
    kyu: 'きゅ',
    kyo: 'きょ',

    gya: 'ぎゃ',
    gyu: 'ぎゅ',
    gyo: 'ぎょ',

    cha: 'ちゃ',
    chu: 'ちゅ',
    cho: 'ちょ',

    sha: 'しゃ',
    shu: 'しゅ',
    sho: 'しょ',

    ja: 'じゃ',
    ju: 'じゅ',
    jo: 'じょ',

    nya: 'にゃ',
    nyu: 'にゅ',
    nyo: 'にょ',

    hya: 'ひゃ',
    hyu: 'ひゅ',
    hyo: 'ひょ',

    bya: 'びゃ',
    byu: 'びゅ',
    byo: 'びょ',

    pya: 'ぴゃ',
    pyu: 'ぴゅ',
    pyo: 'ぴょ',

    mya: 'みゃ',
    myu: 'みゅ',
    myo: 'みょ',

    rya: 'りゃ',
    ryu: 'りゅ',
    ryo: 'りょ',

    // KATAKANA, DAKUTEN AND HANDAKUTEN
    A: 'ア',
    I: 'イ',
    U: 'ウ',
    E: 'エ',
    O: 'オ',

    KA: 'カ',
    KI: 'キ',
    KU: 'ク',
    KE: 'ケ',
    KO: 'コ',

    GA: 'ガ',
    GI: 'ギ',
    GU: 'グ',
    GE: 'ゲ',
    GO: 'ゴ',

    SA: 'サ',
    SHI: 'シ',
    SU: 'ス',
    SE: 'セ',
    SO: 'ソ',

    ZA: 'ザ',
    JI: 'ジ',
    ZU: 'ズ',
    ZE: 'ゼ',
    ZO: 'ゾ',

    TA: 'タ',
    CHI: 'チ',
    TSU: 'ツ',
    TE: 'テ',
    TO: 'ト',

    DA: 'ダ',
    DJI: 'ヂ',
    DZU: 'ヅ',
    DE: 'デ',
    DO: 'ド',

    NA: 'ナ',
    NI: 'ニ',
    NU: 'ヌ',
    NE: 'ネ',
    NO: 'ノ',

    HA: 'ハ',
    HI: 'ヒ',
    FU: 'フ',
    HE: 'ヘ',
    HO: 'ホ',

    BA: 'バ',
    BI: 'ビ',
    BU: 'ブ',
    BE: 'ベ',
    BO: 'ボ',

    PA: 'パ',
    PI: 'ピ',
    PU: 'プ',
    PE: 'ペ',
    PO: 'ポ',

    MA: 'マ',
    MI: 'ミ',
    MU: 'ム',
    ME: 'メ',
    MO: 'モ',

    YA: 'ヤ',
    YU: 'ユ',
    YO: 'ヨ',

    RA: 'ラ',
    RI: 'リ',
    RU: 'ル',
    RE: 'レ',
    RO: 'ロ',

    WA: 'ワ',
    WO: 'ヲ',

    N: 'ン',
    "N'": 'ン',

    katakanaChiisaiTSU: 'ッ',

    // YOUON
    KYA: 'キャ',
    KYU: 'キュ',
    KYO: 'キョ',

    GYA: 'ギャ',
    GYU: 'ギュ',
    GYO: 'ギョ',

    CHA: 'チャ',
    CHU: 'チュ',
    CHO: 'チョ',

    SHA: 'シャ',
    SHU: 'シュ',
    SHO: 'ショ',

    JA: 'ジャ',
    JU: 'ジュ',
    JO: 'ジョ',

    NYA: 'ニャ',
    NYU: 'ニュ',
    NYO: 'ニョ',

    HYA: 'ヒャ',
    HYU: 'ヒュ',
    HYO: 'ヒョ',

    BYA: 'ビャ',
    BYU: 'ビュ',
    BYO: 'ビョ',

    PYA: 'ピャ',
    PYU: 'ピュ',
    PYO: 'ピョ',

    MYA: 'ミャ',
    MYU: 'ミュ',
    MYO: 'ミョ',

    RYA: 'リャ',
    RYU: 'リュ',
    RYO: 'リョ',
};

export default function toKana(romaji: string): string {
    let kana = '';
    const noChiisaiTsuKana = ['n', 'N', 'a', 'A', 'i', 'I', 'u', 'U', 'e', 'E', 'o', 'O'];

    let i = 0;
    while (i < romaji.length) {
        // check for small tsu っ or ッ
        if (romaji[i] === romaji[i + 1] && !noChiisaiTsuKana.includes(romaji[i])) {
            if (romaji[i] === romaji[i].toUpperCase()) {
                kana = `${kana}${kanaMap.katakanaChiisaiTSU}`;
            } else {
                kana = `${kana}${kanaMap.hiraganaChiisaiTsu}`;
            }
            i += 1;
        }
        // if 3-char kana
        else if (kanaMap[romaji.slice(i, i + 3)]) {
            kana = `${kana}${kanaMap[romaji.slice(i, i + 3)]}`;
            i += 3;
        }
        // if 2-char kana
        else if (kanaMap[romaji.slice(i, i + 2)]) {
            kana = `${kana}${kanaMap[romaji.slice(i, i + 2)]}`;
            i += 2;
        }
        // if 1-char kana
        else if (kanaMap[romaji.slice(i, i + 1)]) {
            kana = `${kana}${kanaMap[romaji.slice(i, i + 1)]}`;
            i += 1;
        }
        // if *
        else if (romaji.slice(i, i + 1) === '*') {
            kana = `${kana}*`;
            i += 1;
        }
        // no kana found
        else {
            i += 1;
        }
    }

    return kana;
}