import React, { useState } from 'react'
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { useAuth } from 'context/authContext';
import { doCreateUserWithEmailAndPassword } from 'firebaseConfig/authentication';

const Register = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { userLoggedIn } = useAuth();

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!isRegistering) {
            setIsRegistering(true);
            await doCreateUserWithEmailAndPassword(email, password);
        }
    };

    return (
        <>
            {userLoggedIn && (<Navigate to={'/language/flashcards'} replace={true} />)}

            <div className="page-container">
                <div className="page-content">
                    <h6>REGISTER</h6>

                    <div className="auth-container">
                        <form onSubmit={onSubmit}>
                            <div className="input-field">
                                <label>
                                    Email
                                </label>
                                <input
                                    type="email"
                                    autoComplete='email'
                                    required
                                    value={email} onChange={(e) => { setEmail(e.target.value) }}
                                />
                            </div>

                            <div className="input-field">
                                <label>
                                    Password
                                </label>
                                <input
                                    disabled={isRegistering}
                                    type="password"
                                    autoComplete='new-password'
                                    required
                                    value={password} onChange={(e) => { setPassword(e.target.value) }}
                                />
                            </div>

                            <div className="input-field">
                                <label>
                                    Confirm Password
                                </label>
                                <input
                                    disabled={isRegistering}
                                    type="password"
                                    autoComplete='off'
                                    required
                                    value={confirmPassword} onChange={(e) => { setconfirmPassword(e.target.value) }}
                                />
                            </div>

                            {errorMessage && (
                                <span className="auth-error-message">{errorMessage}</span>
                            )}

                            <button
                                type="submit"
                                disabled={isRegistering}
                                className={`sign-in ${isRegistering ? 'disabled' : 'enabled'}`}
                            >
                                {isRegistering ? 'Signing Up...' : 'Sign Up'}
                            </button>

                            <div className="auth-no-account">
                                <p>Already have an account? {'   '}</p>
                                <Link to={'/auth/signin'}>Continue</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;