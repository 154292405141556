import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    MdHome,
    MdSearch,
    MdLibraryBooks,
    MdInfo,
    MdAttachMoney,
    MdMail,
    MdGavel,
} from 'react-icons/md';
import { FaBrain } from 'react-icons/fa';
import { FaYoutube, FaPinterestP } from 'react-icons/fa'
import { useAuth0 } from '@auth0/auth0-react';
import { IThemedComponentProps } from 'interfaces/interfaces';


export default function Footer({ darkMode }: IThemedComponentProps): JSX.Element {
    const [youtube, setYoutube] = useState(false);
    const [pinterest, setPinterest] = useState(false);
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const unhilightedColor: string = darkMode ? 'var(--mediumGrayColor)' : 'var(--blueColor)';

    const iconStyles = {
        marginRight: '20px',
        width: '25px',
        color: darkMode ? 'var(--mutedRedColor)' : 'var(--redColor)',
    };

    return (
        <footer>
            <div className="footer-left">
                <div className="footer-content-container">
                    <div className="footer-link-columns">
                        {/* LEARN LINKS */}
                        <div className="footer-column">
                            <h5>Learn</h5>
                            <li>
                                <Link className="footer-link" to="/" tabIndex={900}>
                                    <MdHome size="20px" style={iconStyles}/>
                                    Home
                                </Link>
                            </li>

                            <li>
                                <Link className="footer-link" to="/language/flashcards" tabIndex={901}>
                                    <MdLibraryBooks size="19px" style={iconStyles}/>
                                    Flashcards
                                </Link>
                            </li>

                            <li>
                                <Link className="footer-link" to="/language/kanjisearch" tabIndex={902}>
                                    <MdSearch size="20px" style={iconStyles}/>
                                    Kanji Search
                                </Link>
                            </li>

                            <li>
                                <Link className="footer-link" to="/language/theory" tabIndex={903}>
                                    <FaBrain size="18px" style={iconStyles}/>
                                    Theory
                                </Link>
                            </li>
                        </div>

                        {/* SITE INFO LINKS */}
                        <div className="footer-column">
                            <h5>Site Info</h5>
                            <li>
                                <Link className="footer-link" to="/siteinfo/about" tabIndex={904}>
                                    <MdInfo size="20px" style={iconStyles}/>
                                    About
                                </Link>
                            </li>

                            <li>
                                <Link className="footer-link" to="/siteinfo/contact" tabIndex={905}>
                                    <MdMail size="20px" style={iconStyles}/>
                                    Contact
                                </Link>
                            </li>

                            <li>
                                <Link className="footer-link" to="/siteinfo/donate" tabIndex={906}>
                                    <MdAttachMoney size="21px" style={iconStyles}/>
                                    Donate
                                </Link>
                            </li>

                            <li>
                                <Link className="footer-link" to="/siteinfo/legal" tabIndex={907}>
                                    <MdGavel size="18px" style={iconStyles}/>
                                    Legal
                                </Link>
                            </li>
                        </div>
                    </div>

                    {/* SOCIAL MEDIA */}
                    <div className="footer-socialmedia">
                        <a
                            href="https://www.youtube.com"
                            rel="noopener noreferrer"
                            target="_blank"
                            onMouseEnter={() => setYoutube(true)}
                            onMouseLeave={() => setYoutube(false)}
                            tabIndex={910}
                        >
                            <FaYoutube size="25px" color={youtube ? '#FF0000' : unhilightedColor}/>
                        </a>
                        <a
                            href='https://www.pinterest.com/kanjiremastered/'
                            rel="noopener noreferrer"
                            target="_blank"
                            onMouseEnter={() => setPinterest(true)}
                            onMouseLeave={() => setPinterest(false)}
                            tabIndex={911}
                        >
                            <FaPinterestP size="25px" color={pinterest ? '#BD085C' : unhilightedColor}/>
                        </a>
                    </div>
                </div>
            </div>

            <div className="footer-center">
                <div className="footer-content-container">
                    <h5>Sign up for free to get full access!</h5>

                    {isAuthenticated ?
                        <Link to="/profile">
                            <div className="footer-signup-button">Account</div>
                        </Link> :

                        <button className="footer-signup-button" onClick={() =>  loginWithRedirect()}>
                            Sign Up
                        </button>
                    }

                    <div className="footer-logo">
                        KANJI
                        <br/>
                        REMASTERED
                    </div>

                    <div className="footer-kanjis">
                        <div className="footer-kanji-box">三</div>
                        <div className="footer-kanji-box">王</div>
                        <div className="footer-kanji-box">玉</div>
                        <div className="footer-kanji-box">国</div>
                    </div>

                    <div className="footer-copyright">Copyright © 2022 Kanji Remastered</div>
                </div>
            </div>

            <div className="footer-right">
                <div className="footer-background-image"/>
            </div>
        </footer>
    );
}
