import React from 'react';
// const linkDescription = `The link above is not advertisiment. Kanji Remastered currently does not receive any compensation from the parties linked.`;

interface ILargeAdProps {
    resourceTitle: string;
    resourceLink: string;
    linkDescription: string;
    description: JSX.Element;
}

function LargeAd ({
    resourceTitle,
    resourceLink,
    linkDescription,
    description
}: ILargeAdProps): JSX.Element {
    return (
        <>
            <div className="ads-large-ad">
                <div className="ads-large-link-content">
                    <h5><a href={resourceLink} target="_blank" rel="noreferrer">{resourceTitle}</a></h5>
                </div>
                <div className="ads-subtitle">
                    <p><a href={resourceLink} target="_blank" rel="noreferrer">{linkDescription}</a></p>
                </div>
            </div>
            <div className="ads-description">
                {description}
            </div>
        </>
    );
}

// FLASHCARD TEMPLATES
const Advertising = {
    KanjiSearch: function (): JSX.Element {
        return (
            <div className="ads-large-container">
                <div className="ads-heading-top">
                    <h5>ADDITIONAL RESOURCES</h5>
                </div>

                <LargeAd
                    resourceTitle="JISHO.org"
                    linkDescription="Online Japanese Dictionary"
                    resourceLink="https://jisho.org/"
                    description={<p>Jisho.org is the best and most user-friendly English-Japanese online dictionary hands down. There is nothing more to say.</p>}
                />

                <LargeAd
                    resourceTitle="KANJI.org"
                    linkDescription="Print Kanji Dictionaries and Guides"
                    resourceLink="http://www.kanji.org/"
                    description={<p>For those who must have printed dictionaries, the Kanji Dictionary Publishing Society has some invaluable resources.</p>}
                />

                <LargeAd
                    resourceTitle="TAE KIM'S GUIDE TO JAPANESE"
                    linkDescription="Tae Kim's Guide to Learning Japanese"
                    resourceLink="http://www.guidetojapanese.org/learn/"
                    description={<p>Probably the best and most comprehensive free online Japanese textbook covering beginner to upper intermediate grammar. Tae Kim's Guide to Japanese also takes a more common sense approach that typical textbooks don't.</p>}
                />

                <LargeAd
                    resourceTitle="MAGGIE SENSEI"
                    linkDescription="Japanese Language Explanations"
                    resourceLink="https://maggiesensei.com/"
                    description={<p>Maggie Sensei is another great reference for grammar points and the nuances of the Japanese langauge. So much free content that it's almost inexhaustible.</p>}
                />

                <LargeAd
                    resourceTitle="IMABI"
                    linkDescription="Japanese Grammar Reference"
                    resourceLink="https://www.imabi.net/"
                    description={<p>Every single grammar detail one could hope for is here, from colloquial basic Japanese to Keigo, Classical Japanese and even the Okinawan tongues. All the free content is quite frankly overwhelming.</p>}
                />
            </div>
        );
    },
};

export default Advertising;