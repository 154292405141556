import { DATABASE_URL } from 'constants/constants';
import {
    IUserAccountServicesProps,
    IUserAccountServicesRouteProps,
} from 'interfaces/interfaces';
import { Buffer } from 'buffer/';
/*
    This provides all the functions for the logging in,
    registering user accounts and for accessing restricted
    assets in the backend via Auth0 tokens.
*/

// function getSavedSets(): IUserSavedSet[] {
//     const cachedData = localStorage.getItem('user-saved-sets');
//     return cachedData ? JSON.parse(cachedData) : [];
// }

// function createSavedSet(data: IUserSavedSet): { sets: IUserSavedSet[], responseMessage: string } {
//     const cachedData = localStorage.getItem('user-saved-sets');
//     const currentSavedSets: IUserSavedSet[] = cachedData ? JSON.parse(cachedData) : [];

//     let isNewName = true;
//     currentSavedSets.forEach(({ setName }) => {
//         if (setName === data.setName) {
//             isNewName = false;
//         }
//     });

//     if (isNewName) {
//         const newSavedSets: IUserSavedSet[] = [...currentSavedSets, data];
//         localStorage.setItem('user-saved-sets', JSON.stringify(newSavedSets));
//         return {
//             sets: newSavedSets,
//             responseMessage: RESPONSE_MESSAGE.setSaved,
//         };
//     } else {
//         return {
//             sets: currentSavedSets,
//             responseMessage: RESPONSE_MESSAGE.nameTaken,
//         }
//     }
// }


function userAccountServicesRoute<T, K = undefined>({ token, user, data, url }: IUserAccountServicesRouteProps<K>): Promise<T> {
    const configs: RequestInit = {
        method: 'POST',
        body: JSON.stringify(data),
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Finder': Buffer.from(user).toString('base64'),
        },
    };

    return fetch(url, configs)
        .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json() as Promise<T>;
        })
        .then(data => data);
}

function saveCustomNotes<T, K>({ token, user, data }: IUserAccountServicesProps<K>): Promise<T> {
    return userAccountServicesRoute<T, K>({ token, user, data, url: `${DATABASE_URL}/api/savecustomnotes` });
}

function fetchCustomNotes<T, K>({ token, user, data }: IUserAccountServicesProps<K>): Promise<T> {
    return userAccountServicesRoute<T, K>({ token, user, data, url: `${DATABASE_URL}/api/fetchcustomnotes` });
}

function getSavedSets<T>({ token, user }: IUserAccountServicesProps): Promise<T> {
    return userAccountServicesRoute<T>({ token, user, url: `${DATABASE_URL}/api/getsavedsets` });
}

function createSavedSet<T, K>({ token, user, data }: IUserAccountServicesProps<K>): Promise<T> {
    return userAccountServicesRoute<T, K>({ token, user, data, url: `${DATABASE_URL}/api/createsavedset` });
}

function updateSavedSet<T, K>({ token, user, data }: IUserAccountServicesProps<K>): Promise<T> {
    return userAccountServicesRoute<T, K>({ token, user, data, url: `${DATABASE_URL}/api/updatesavedset` });
}

function deleteSavedSet<T, K>({ token, user, data }: IUserAccountServicesProps<K>): Promise<T> {
    return userAccountServicesRoute<T, K>({ token, user, data, url: `${DATABASE_URL}/api/deletesavedset` });
}

function getReviewSet<T, K>({ token, user, data }: IUserAccountServicesProps<K>): Promise<T> {
    return userAccountServicesRoute<T, K>({ token, user, data, url: `${DATABASE_URL}/api/retrievereviewset` });
}

function fetchTestProgress<T, K>({ token, user, data }: IUserAccountServicesProps<K>): Promise<T> {
    return userAccountServicesRoute<T, K>({ token, user, data, url: `${DATABASE_URL}/api/fetchtestprogress` });
}

function saveTestProgress<T, K>({ token, user, data }: IUserAccountServicesProps<K>): Promise<T> {
    return userAccountServicesRoute<T, K>({ token, user, data, url: `${DATABASE_URL}/api/savetestprogress` });
}

function sendContactMessage<T, K>(data: K): Promise<T> {
    const url = `${DATABASE_URL}/api/postcontactmessage`;
    const configs: RequestInit = {
        method: 'POST',
        body: JSON.stringify(data),
        mode: 'cors',
        headers: {'Content-Type': 'application/json'}
    };

    return fetch(url, configs)
        .then(response => {
            if (!response.ok) {
              throw new Error(response.statusText)
            }
            return response.json() as Promise<T>;
        })
        .then(data => data);
}

const userAccountServices = {
    auth: {
        saveCustomNotes,
        fetchCustomNotes,
        getSavedSets,
        createSavedSet,
        updateSavedSet,
        deleteSavedSet,
        getReviewSet,
        fetchTestProgress,
        saveTestProgress
    },

    forms: {
        sendContactMessage,
    },
};

export default userAccountServices;