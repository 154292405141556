import React, { useState, useEffect, MouseEvent } from 'react';
import { IRadicalTabProps } from 'interfaces/interfaces';

export default function RadicalTab({
    searchData,
    parentsData,
    // isRadicalData,
    createList,
    searchForRadicals,
    radical,
}: IRadicalTabProps): JSX.Element {
    const [tabStyles, setTabStyles] = useState(false);
    const [unselectedStyles, setUnselectedStyles] = useState('radical-tab-unselected');
    // const [styles, setStyles] = useState('radical-tab-unselected');

    useEffect(() => {
        function checkIfInSearchData(radical: string): void {
            /* DESCRIPTION If searchData is returned from the click of a radical tab,
            this function will gray-out and and deactivate the functionalty of
            the radical if its associated radical is not a component of any search
            data kanji or of a kanji's parent tree.
            */
            if (searchData && searchData.length) {
                // && isRadicalData) { // prevents search data from main search deactivating radical tabs
                setUnselectedStyles('radical-tab-deactivated');

                for (let i = 0; i < searchData.length; i++) {
                    let kanji = searchData[i];
                    if (kanji.meanings.includes(radical) || kanji.radicals.includes(radical)) {
                        setUnselectedStyles('radical-tab-unselected');
                        break;
                    }
                }
            }
            // else {
            //     setUnselectedStyles('radical-tab-unselected');
            // }
        }

        function checkIfInParentsData(radical: string): void {
            if (parentsData && parentsData.length) {
                // && isRadicalData) { // prevents search data from main search deactivating radical tabs
                for (let i = 0; i < parentsData.length; i++) {
                    if (radical === parentsData[i]) {
                        // console.log({ radical, parentRadical: parentsData[i] });
                        setUnselectedStyles('radical-tab-deactivated');
                        break;
                    }
                }
            }
        }

        checkIfInSearchData(radical[0]);
        checkIfInParentsData(radical[0]);
    }, [searchData]);

    const styles = tabStyles ? 'radical-tab-selected' : unselectedStyles;

    function handleClickRadicalTab(e: MouseEvent<HTMLButtonElement>): void {
        if (unselectedStyles === 'radical-tab-unselected') {
            const [name, char] = radical;
            const [radicalNames, radicalChars] = createList(name, char);
            searchForRadicals(radicalNames, radicalChars);
            setTabStyles(!tabStyles);
        }
    }

    return (
        <div>
            <button className={styles} type="button" onClick={(e) => handleClickRadicalTab(e)}>
                {radical[1]}
            </button>
        </div>
    );
}