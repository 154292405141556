import React from 'react';
import { IoIosSearch } from 'react-icons/io';
import pseudoRadicals from 'helpers/pseudoRadicals';
import { ISuggestionsProps } from 'interfaces/interfaces';

export default function Suggestions({ handleClickSuggestions, suggestions, darkMode }: ISuggestionsProps): JSX.Element {
    /* DATA STRUCTURE of suggestions:
        [
        [Id, Kanji, [...meanings] Column,, [...radicals], Match, Priority], 
        [...],
        [...],
        ...
        ]
    Example:
        [
        [43, '由', ['field','',''] 'Meaning', ['box','ten','',''], 'field', '1']
        [44, '甲', ['armor','',''] 'Radical', ['field','bar','',''], 'armor', '2']
        [45, '由', ['reason','',''], 'Radical', ['bar','field','',''], 'reason', '2']
        ]
    */

    return (
        <div className="suggestions-container">
            <div className="suggestions-topline">
                <IoIosSearch size="20px" color={darkMode ? 'var(--mediumGrayColor)' : 'var(--blueColor)'} title="clickable list of recent searches"/>
                <h1>Search Suggestions</h1>
            </div>

            {suggestions.slice(0,15).map((result, index) => (
                <button className="suggestions-button" key={`${index}_${result.meanings[0]}`} onClick={e => handleClickSuggestions(e, result.meanings[0], result.kanji)}>
                    <div className="suggestions-item">
                        {result.heading}: <span className="suggestions-match">{result.match}</span>
                    </div>

                    <div className="suggestions-item">
                        {pseudoRadicals.meanings.includes(result.meanings[0])
                            ? <img src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(result.meanings[0])][darkMode ? 'gray' : 'black']} alt="kanji character"/>
                            : <p>{result.kanji}</p>
                        }
                        {result.meanings[0]}
                    </div>
                </button>
            ))}
        </div>
    );
}