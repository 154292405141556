import React, { useContext } from 'react';
import { Context } from 'context/context';
import {
    MdLightMode,
    MdDarkMode,
    MdOutlineStar as DarkModePointsIcon,
    MdOutlineStarBorder as LightModePointsIcon,
} from 'react-icons/md';
// import Odometer from 'react-odometerjs';
import { Dropdown, Hamburger, ScoreOdometer } from 'components';
import { IThemedComponentProps, IContext } from 'interfaces/interfaces';
import { COLOR } from 'constants/constants';

interface INavbarProps extends IThemedComponentProps {
    toggleTheme(): void;
}

export default function Navbar({ darkMode, toggleTheme }: INavbarProps): JSX.Element {
    const {
        isStartFlashcards,
        isChallengeRound,
        totalScore,
        totalScoreDigits,
    } = useContext(Context) as IContext;

    const {
        blueColor,
        lightGrayColor,
        mediumGrayColor,
        mutedRedColor,
        redColor,
    } = COLOR;

    const darkModePointStyles = {
        color: totalScore < 0 ? mutedRedColor : lightGrayColor,
    }
    const lightModePointStyles = {
        color: totalScore < 0 ? redColor : blueColor,
    }
    const iconSize = '25px';

    return (
        <nav>
            <div className="navbar-brand-mark">
                <div className="navbar-logo">
                    <div className="navbar-kanji-row">
                        <div className="navbar-kanji">三</div>
                        <div className="navbar-kanji">王</div>
                    </div>
                    <div className="navbar-kanji-row">
                        <div className="navbar-kanji">玉</div>
                        <div className="navbar-kanji">国</div>
                    </div>
                </div>
                <div className="navbar-title">
                    KANJI
                    <br/>
                    REMASTERED
                </div>
            </div>

            {isStartFlashcards || isChallengeRound ?
                <div className="navbar-score-bar">
                    <div className="score" style={darkMode ? darkModePointStyles : lightModePointStyles}>
                        <ScoreOdometer digits={totalScoreDigits}/>
                    </div>
                    {darkMode ?
                        <DarkModePointsIcon size={iconSize} color={totalScore < 0 ? mutedRedColor : mediumGrayColor}/> :
                        <LightModePointsIcon size={iconSize} color={totalScore < 0 ? redColor: blueColor}/>
                    }
                </div> :

                <>
                    <div className="navbar-controls">
                        <Dropdown darkMode={darkMode}/>
                        <div className="navbar-theme-toggle">
                            <form>
                                <label htmlFor="lightDark" className="toggle-theme-switch">
                                    <input
                                        type="checkbox"
                                        id="lightDark"
                                        checked={darkMode}
                                        onChange={() => toggleTheme()}
                                    />
                                    <div className="toggle-theme-slider">
                                        <MdLightMode size="22px" className="light-icon"/>
                                        <MdDarkMode size="22px" className="dark-icon"/>
                                    </div>
                                </label>
                            </form>
                        </div>
                    </div>

                    <Hamburger darkMode={darkMode} toggleTheme={toggleTheme}/>
                </>
            }
        </nav>
    );
};