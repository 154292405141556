import React, { useContext, useEffect, useRef, useState, lazy, CSSProperties, MouseEvent, Suspense } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Carousel,
    Form,
    Button,
    Modal,
} from 'react-bootstrap';
import {
    MdCancel,
    MdClose,
    MdFirstPage,
    MdLastPage,
    MdGTranslate,
    MdLibraryBooks,
    MdSave,
    MdFolder,
    MdAddCircle,
    // MdDelete,
    MdExpandLess,
    MdExpandMore,
    MdModeEdit,
    MdEditOff,
} from 'react-icons/md';
import { Context } from 'context/context';
import searchServices from 'middleware/searchServices';
import userAccountServices from 'middleware/userAccountServices';
import { Cards, ErrorBoundary, Fallback, Tips } from 'components';
import {
    SET_NAME_TAKEN,
    REVIEW_KANJI_SET,
    STANDARD_TEST_SET,
    CUSTOM_TEST_SET,
    DUMMY_CARD_NUMBER,
    RESPONSE_MESSAGE,
    FLASHCARD_TYPE,
    dummyStudyFlashcard,
    dummyTestFlashcard,
    QUESTION_TYPE,
} from 'constants/constants';
import {
    IKanjiData,
    ITestKanjiData,
    IThemedComponentProps,
    IUserSavedSet,
    IAPIBaseResponse,
    IContext,
} from 'interfaces/interfaces';
// const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
// const audience = process.env.REACT_APP_AUDIENCE;

const KanjiPicker = lazy(() => import('../../components/KanjiPicker')); // for <Suspense /> children

interface IGetReviewSetData {
    reviewSetName: string;
}

interface ITestProgressResponse {
    carouselIndex: number;
    kanjiSet: string;
    kanjiTestResults: string;
    kunyomiTestResults: string;
    meaningTestResults: string;
    onyomiTestResults: string;
}

interface ITestProgress {
    carouselIndex: number;
    kanjiDataSet: ITestKanjiData[];
    kanjiTestResults: number[];
    kunyomiTestResults: number[];
    meaningTestResults: number[];
    onyomiTestResults: number[];
}

interface IFetchTestProgressData {
    setName: string;
    isMeaningTest: boolean;
    isKanjiTest: boolean;
    isKunyomiTest: boolean;
    isOnyomiTest: boolean;
}

interface ISaveTestProgressData {
    setName: string;
    carouselIndex: number;
    isMeaningTest: boolean;
    isKanjiTest: boolean;
    isKunyomiTest: boolean;
    isOnyomiTest: boolean;
    kanjiSet: string;
    meaningTestResults: string;
    kanjiTestResults: string;
    kunyomiTestResults: string;
    onyomiTestResults: string;
}

interface IUpdateSavedSetData {
    oldSetName: string;
    newSetName: string;
    kanjiSet: string;
}

interface IDeleteSavedSetData {
    setName: string;
}

export default function SelfStudy({ darkMode }: IThemedComponentProps): JSX.Element {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // LEVELS
    const [cardLevel, setCardLevel] = useState(0);
    const levels = {
        beginner: 'beginner',
        intermediate: 'intermediate',
        advanced: 'advanced',
        custom: 'custom',
    };
    const [tabNumber, setTabNumber] = useState(0);
    const [tipsLevel, setTipsLevel] = useState(levels.beginner);
    const [beginnerTab, setBeginnerTab] = useState('tab-beginner-selected');
    const [intermediateTab, setIntermediateTab] = useState('tab-intermediate-unselected');
    const [advancedTab, setAdvancedTab] = useState('tab-advanced-unselected');
    const [customTab, setCustomTab] = useState('tab-custom-unselected');
    const [isKanjiList, setKanjiList] = useState(false);
    const [isSavedSets, setSavedSets] = useState(false);
    const [userSavedSets, setUserSavedSets] = useState<IUserSavedSet[]>([]);

    // FLASHCARDS
    const [kanjiDataSet, setKanjiDataSet] = useState<IKanjiData[] | ITestKanjiData[]>([]);
    const [reviewSet, setReviewSet] = useState<IKanjiData[] | ITestKanjiData[]>([]);
    const [isStartReview, setStartReview] = useState(false);
    const [isReviewSetChanged, setReviewSetChanged] = useState(false);
    const [kanjiSideUp, setKanjiSideUp] = useState(true);
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [isMeaningRadicals, setIsMeaningRadicals] = useState(true);
    const [isOnyomiKunyomi, setIsOnyomiKunyomi] = useState(true);

    // CUSTOM & SAVED SETS
    const [selectedKanjis, setSelectedKanjis] = useState('');
    const [selectedReviewKanjis, setSelectedReviewKanjis] = useState('');
    const [originalSelectedKanjiSet, setOriginalSelectedKanjiSet] = useState('');
    const [selectedSetName, setSelectedSetName] = useState('');
    const [prevSelectedSetName, setPrevSelectedSetName] = useState('');
    const [editSetName, setEditSetName] = useState(false);
    const [isCreateSetMode, setIsCreateSetMode] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [isCustomSet, setIsCustomSet] = useState(false);

    // TEST MODE
    const [isStudyMode, setStudyMode] = useState(true);
    const [testProgress, setTestProgress] = useState<ITestProgress>({} as ITestProgress);
    const [isMeaningTest, setMeaningTest] = useState(true);
    const [isKanjiTest, setKanjiTest] = useState(false);
    const [isOnyomiTest, setOnyomiTest] = useState(false);
    const [isKunyomiTest, setKunyomiTest] = useState(false);
    const [meaningTestResults, setMeaningTestResults] = useState<number[]>([]);
    const [kanjiTestResults, setKanjiTestResults] = useState<number[]>([]);
    const [onyomiTestResults, setOnyomiTestResults] = useState<number[]>([]);
    const [kunyomiTestResults, setKunyomiTestResults] = useState<number[]>([]);
    const [tabContainerStyles, setTabContainerStyles] = useState<CSSProperties>({
        visibility: 'visible',
        height: '175px',
        flexWrap: 'nowrap',
        padding: '0 10%',
    });

    // MODALS
    const [showModalSave, setShowModalSave] = useState(false);
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalAuthenticate, setShowModalAuthenticate] = useState(false);
    const [showModalReview, setShowModalReview] = useState(false);
    const [showModalResume, setShowModalResume] = useState(false);
    const [showModalCancel, setShowModalCancel] = useState(false);

    // AUTH
    const [token, setToken] = useState('');
    const [userId, setUserId] = useState<string | undefined>(undefined);

    const scrollToRef = useRef<HTMLDivElement | null>(null);

    const { setFlashcardsPage } = useContext(Context) as IContext;

    useEffect(() => {
        setFlashcardsPage(false);
    }, []);

    // WINDOW SIZE
    useEffect(() => {
        const handleResize = () => {
            return setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
    });

    function chooseTabStyles(windowWidth: number): void {
        if (kanjiDataSet.length || isStartReview) {
            setTabContainerStyles({
                visibility: 'hidden',
                height: '0',
                flexWrap: 'nowrap',
                padding: '0 10%',
            });
        } else if (windowWidth > 1100) {
            setTabContainerStyles({
                visibility: 'visible',
                height: '175px',
                flexWrap: 'nowrap',
                padding: '0 10%',
            });
        } else if (windowWidth > 800) {
            setTabContainerStyles({
                visibility: 'visible',
                height: '175px',
                flexWrap: 'nowrap',
                padding: '0',
            });
        } else {
            setTabContainerStyles({
                visibility: 'visible',
                height: 'auto',
                flexWrap: 'wrap',
                padding: '0',
            });
        }
    }

    // AUTH0
    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        loginWithRedirect,
    } = useAuth0();

    // SETS TAB CONTAINER STYLES ON PAGE LOAD
    useEffect(() => {
        chooseTabStyles(windowWidth);

        async function getAndSetToken(): Promise<void> {
            const auth0token = await getAccessTokenSilently();
            setToken(auth0token);
            setUserId(user?.sub);
        }

        if (isAuthenticated) {
            getAndSetToken();
        }
    }, []);

    // MEDIA QUERY FOR MENU ON SCREEN SIZE CHANGE
    // OR
    // CAUSES MENU TO OPEN OR CLOSE WHEN NEW KANJI DATA SET
    useEffect(() => {
        chooseTabStyles(windowWidth);
    }, [windowWidth, kanjiDataSet]);

    // CAUSES MENU TO OPEN OR CLOSE WHEN NEW KANJI DATA SET
    // useEffect(() => {
    //     chooseTabStyles(windowWidth);
    // }, [kanjiDataSet]);

    // SETS REVIEW DATA
    useEffect(() => {
        setCarouselIndex(0);
        setKanjiDataSet([]);
        // chooseTabStyles(windowWidth);
    }, [isStartReview]);

    // CLEARS KANJI DATA SET AND REVIEW SET
    useEffect(() => {
        setCarouselIndex(0);
        setKanjiDataSet([]);
        setReviewSet([]);
    }, [isStudyMode]);

    function handleCloseModals(): void {
        setResponseMessage('');
        setShowModalSave(false);
        setShowModalUpdate(false);
        setShowModalDelete(false);
        setShowModalAuthenticate(false);
        setShowModalResume(false);
        setShowModalCancel(false);
    }

    // CLOSES CREATE SET MODAL
    useEffect(() => {
        const saveSuccessResponse = 'Set saved!';
        setTimeout((): void => {
            if (responseMessage === saveSuccessResponse) {
                handleCloseModals();
            }
        }, 1100);
    }, [responseMessage]);

    // DATA RETRIEVAL FROM SEARCH API
    const {
        getAllKanji,
        getStandardKanjiSet,
        getStandardTestSet,
        getCustomKanjiSet,
        getCustomTestSet,
    } = searchServices;

    // DATA RETRIEVAL FROM USER ACCOUNTS API
    const {
        getSavedSets,
        createSavedSet,
        updateSavedSet,
        deleteSavedSet,
        getReviewSet,
        fetchTestProgress,
        saveTestProgress
    } = userAccountServices.auth;

    // TABS
    const testChoices = {
        isMeaningTest,
        isKanjiTest,
        isOnyomiTest,
        isKunyomiTest,
    };

    const setNumber = `${cardLevel}_${tabNumber}`;
    const selectedTestSet = `testSet_${selectedSetName || setNumber}_isMeaningsTest:${isMeaningTest}_isKanjiTest:${isKanjiTest}_isKunyomiTest:${isKunyomiTest}_isOnyomiTest:${isOnyomiTest}`;
    const selectedTestOptions = `_isMeaningsTest:${isMeaningTest}_isKanjiTest:${isKanjiTest}_isKunyomiTest:${isKunyomiTest}_isOnyomiTest:${isOnyomiTest}`;

    const customKanjiTextareaPlaceholder = 'Enter kanji separated by commas or choose from the entire 漢字 Kanji List below. Sign in to load your saved sets or create new ones.';
    const carouselItemStyles = {
        width: '100%', 
        // height: '525px',
        height: '570px',
    };
    const carouselStyles = {
        margin: '0 auto',
        // height: '525px',
        height: '570px',
        width: windowWidth > 500 ? '365px' : '100%',
    };
    const modalHeaderStyles = {
        borderRadius: 0,
        borderBottom: darkMode ? 'var(--borderPrimaryDark)' : 'var(--borderPrimary)',
        backgroundColor: darkMode ? 'var(--darkBgColor)' : 'var(--whiteColor)'
    };
    const modalTitleStyles = {
        color: darkMode ? 'var(--mediumGrayColor)' : 'var(--blueColor)',
        font: 'var(--xLargeHeavyFont)'
    };
    const modalBodyStyles = {
        borderBottom: darkMode ? 'var(--borderPrimaryDark)' : 'var(--borderPrimary)',
        backgroundColor: darkMode ? 'var(--darkBgColor)' : 'var(--whiteColor)',
        color: darkMode ? 'var(--mediumGrayColor)' : 'var(--blueColor)',
        font: 'var(--xLargeHeavyFont)'
    };
    const modalFooterStyles = {
        borderRadius: 0,
        border: 0,
        backgroundColor: darkMode ? 'var(--darkBgColor)' : 'var(--whiteColor)'
    };
    const modalButtonStyles = { borderRadius: 2 };

    function processFlashcardData(newKanjiDataSet: IKanjiData[] | ITestKanjiData[]): void {
        if (newKanjiDataSet.length) {
            const processed: (IKanjiData | ITestKanjiData)[] = [];

            // ADDS INSTRUCTIONS CARD
            processed.push(isStudyMode ?
                { ...dummyStudyFlashcard, type: FLASHCARD_TYPE.studyInstructions } :
                { ...dummyTestFlashcard, type: FLASHCARD_TYPE.testInstructions }
            );

            newKanjiDataSet.forEach((kanjiData, index) => {
                const cardNumber = index + 1;
                if (cardNumber % (DUMMY_CARD_NUMBER - 1) === 0) {
                    // ADDS REGULAR CARD
                    processed.push(kanjiData);
                    // ADDS ADVERTSING CARD
                    processed.push(isStudyMode ?
                        { ...dummyStudyFlashcard, type: FLASHCARD_TYPE.donate } :
                        { ...dummyTestFlashcard, type: FLASHCARD_TYPE.donate }
                    );
                } else {
                    // ADDS REGULAR CARD
                    processed.push(kanjiData);
                }
            });

            // ADDS REVIEW CARD
            processed.push(isStudyMode ?
                { ...dummyStudyFlashcard, type: FLASHCARD_TYPE.studyReview } :
                { ...dummyTestFlashcard, type: FLASHCARD_TYPE.testReview }
            );
            setKanjiDataSet(processed);
        }
    }

    function processReviewData(newReviewSet: IKanjiData[] | ITestKanjiData[]): void {
        if (newReviewSet.length) {
            const processed: (IKanjiData | ITestKanjiData)[] = isStudyMode ?
                [
                    {
                        ...dummyStudyFlashcard,
                        type: FLASHCARD_TYPE.studyInstructions,
                    },
                    ...newReviewSet,
                    {
                        ...dummyStudyFlashcard,
                        type: FLASHCARD_TYPE.studyReview,
                    }
                ] :
                [
                    {
                        ...dummyTestFlashcard,
                        type: FLASHCARD_TYPE.testInstructions,
                    },
                    ...newReviewSet,
                    {
                        ...dummyTestFlashcard,
                        type: FLASHCARD_TYPE.testReview,
                    }
                ];
            setReviewSet(processed);
        }
    }

    function handleChangeSetMode(): void {
        setStudyMode(!isStudyMode);
        setKanjiDataSet([]);
        setReviewSet([]);
    }

    interface IFlashcardButtonProps {
        classStyles: string;
        onClick(): void;
        // inputRef
        icon: JSX.Element;
        text: string;
    }

    function FlashcardButton({ classStyles, onClick, icon, text }: IFlashcardButtonProps): JSX.Element {
        return (
            <button className={classStyles} onClick={onClick}>
                <div>{icon}</div>
                <div style={{ paddingLeft: '5px' }}>{text}</div>
            </button>
        );
    }

    function resetFlashcards(): void {
        setCarouselIndex(0);
        setStartReview(false);
        setReviewSet([]);
    }

    function resetTestResults(): void {
        setKanjiTestResults([]);
        setMeaningTestResults([]);
        setOnyomiTestResults([]);
        setKunyomiTestResults([]);
    }

    // function trimReviewKanjis(reviewKanjis: string): string {
    //     if (reviewKanjis && reviewKanjis.slice(0, 1) === ',') {
    //         return reviewKanjis.slice(1);
    //     }
    //     return reviewKanjis;
    // }

    function getTestSet(cardNumber: number): void {
        // GET TEST SET IF NO PREVIOUS CACHE
        const standardTestSet = getStandardTestSet(cardNumber, testChoices);
        processFlashcardData(standardTestSet);
    }

    function handleTabSetButton(indexNum: number, cardNumber: number): void {
        setTabNumber(indexNum);
        resetFlashcards();
        resetTestResults();
        setSelectedKanjis('');
        setSelectedSetName('');

        if (isStudyMode) {
            // WITHOUT AUTH0
            const standardKanjiSet = getStandardKanjiSet(cardNumber);
            processFlashcardData(standardKanjiSet);
            ///////////////////////////
            

            ///////////////////////////
            // AUTH0 //////////////////
            ///////////////////////////
            // GET STUDY SET
            // if (isAuthenticated) {
            //     const reviewSetName = `${REVIEW_KANJI_SET}${cardLevel + indexNum + 1}`;
            //     const data: IGetReviewSetData = { reviewSetName };

            //     console.log({ userId });
            //     let { set: reviewKanjis } = await getReviewSet<{ set: string }, IGetReviewSetData>({
            //         token,
            //         user: userId as string || '',
            //         data,
            //     });
            //     if (reviewKanjis) {
            //         reviewKanjis = trimReviewKanjis(reviewKanjis);
            //         setSelectedReviewKanjis(reviewKanjis);
            //         setShowModalReview(true);
            //     } else {
            //         const standardKanjiSet = await getStandardKanjiSet(cardNumber);
            //         processFlashcardData(standardKanjiSet);
            //     }
            // } else {
            //     const standardKanjiSet = await getStandardKanjiSet(cardNumber);
            //     processFlashcardData(standardKanjiSet);
            // }
            ///////////////////////////
        } else if (!isStudyMode && (isKanjiTest || isMeaningTest || isOnyomiTest || isKunyomiTest)) {
            // WITHOUT AUTH0
            const selectedTestSet = `testSet_${cardLevel}_${indexNum}` + selectedTestOptions;
            const cachedTestProgress: ITestProgress | null = JSON.parse(localStorage.getItem(selectedTestSet) || '""');
            if (cachedTestProgress) {
                setTestProgress(cachedTestProgress);
                setShowModalResume(true);
            } else {
                // GET TEST SET IF NO PREVIOUS CACHE
                getTestSet(cardNumber);
            }
            ///////////////////////////

            ///////////////////////////
            // AUTH0 //////////////////
            ///////////////////////////
            // GET TEST SET
            // if (isAuthenticated) {
            //     const data: IFetchTestProgressData = {
            //         setName: `${cardLevel}${indexNum}${STANDARD_TEST_SET}`,
            //         isMeaningTest,
            //         isKanjiTest,
            //         isKunyomiTest,
            //         isOnyomiTest,
            //     };

            //     // TODO change returned data in back end
            //     let savedTestProgress = await fetchTestProgress<ITestProgressResponse, IFetchTestProgressData>({
            //         token,
            //         user: userId as string,
            //         data,
            //     });
            //     console.log({ savedTestProgress });

            //     if (Object.keys(savedTestProgress).length) {
            //         setTestProgress({
            //             ...savedTestProgress,
            //             kanjiDataSet: JSON.parse(savedTestProgress.kanjiSet),
            //             meaningTestResults: JSON.parse(savedTestProgress.meaningTestResults),
            //             kanjiTestResults: JSON.parse(savedTestProgress.kanjiTestResults),
            //             kunyomiTestResults: JSON.parse(savedTestProgress.kunyomiTestResults),
            //             onyomiTestResults: JSON.parse(savedTestProgress.onyomiTestResults),
            //         });
            //         setShowModalResume(true);
            //     } else {
            //         // GET TEST SET IF NO PREVIOUS CACHE
            //         getTestSet(cardNumber);
            //     }
            // } else {
            //     // NOT AUTHENTICATED
            //     const selectedTestSet = `testSet_${cardLevel}_${indexNum}` + selectedTestOptions;
            //     const cachedTestProgress: ITestProgress | null = JSON.parse(localStorage.getItem(selectedTestSet) || '""');
            //     if (cachedTestProgress) {
            //         setTestProgress(cachedTestProgress);
            //         console.log({ cachedTestProgress });
            //         setShowModalResume(true);
            //     } else {
            //         // GET TEST SET IF NO PREVIOUS CACHE
            //         getTestSet(cardNumber);
            //     }
            // }
            ///////////////////////////
        } else {
            alert('Select at least one test topic to practice: Meanings, Kanji, Onyomi or Kunyomi!');
        }
    };

    interface ITabButtonProps {
        indexNum: number;
    }

    function TabButton({ indexNum }: ITabButtonProps): JSX.Element {
        const cardNumber = (cardLevel * 100) + (indexNum * 100) + 1;
        const setNumber = cardLevel + indexNum + 1;
        const tabText = `Set ${setNumber}`;

        return (
            <FlashcardButton
                classStyles="tab-button"
                text={tabText}
                icon={<MdLibraryBooks size="16px" className="tab-levels-icon"/>}
                onClick={() => handleTabSetButton(indexNum, cardNumber)}
            />
        );
    }

    async function getCardLevel(level: string): Promise<void> {
        setIsCustomSet(false);
        setTipsLevel(level);
        if (level === levels.beginner) {
            setCardLevel(0);
            setBeginnerTab('tab-beginner-selected');
            setIntermediateTab('tab-intermediate-unselected');
            setAdvancedTab('tab-advanced-unselected');
            setCustomTab('tab-custom-unselected');
            setSelectedKanjis('');
            setSelectedReviewKanjis('');
            setOriginalSelectedKanjiSet('');

        } else if (level === levels.intermediate) {
            setCardLevel(10);
            setBeginnerTab('tab-beginner-unselected');
            setIntermediateTab('tab-intermediate-selected');
            setAdvancedTab('tab-advanced-unselected');
            setCustomTab('tab-custom-unselected');
            setSelectedKanjis('');
            setSelectedReviewKanjis('');
            setOriginalSelectedKanjiSet('');

        } else if (level === levels.advanced) {
            setCardLevel(20);
            setBeginnerTab('tab-beginner-unselected');
            setIntermediateTab('tab-intermediate-unselected');
            setAdvancedTab('tab-advanced-selected');
            setCustomTab('tab-custom-unselected');
            setSelectedKanjis('');
            setSelectedReviewKanjis('');
            setOriginalSelectedKanjiSet('');

        } else if (level === levels.custom) {
            setKanjiDataSet([]);
            setCardLevel(-1);
            setBeginnerTab('tab-beginner-unselected');
            setIntermediateTab('tab-intermediate-unselected');
            setAdvancedTab('tab-advanced-unselected');
            setCustomTab('tab-custom-selected');

            if (isAuthenticated && userSavedSets && !userSavedSets.length) {
                const { sets } = await getSavedSets<{ sets: IUserSavedSet[]; }>({ token, user: user?.sub as string });
                setUserSavedSets(sets);
            }
        }
    }

    function handleSavedSets(): void {
        setSavedSets(!isSavedSets);
    }

    // function handleUserSavedSet(set: IUserSavedSet): void {
    //     setSelectedKanjis(set.kanjiSet);
    //     setOriginalSelectedKanjiSet(set.kanjiSet);
    //     setSelectedSetName(set.setName);
    //     setPrevSelectedSetName(set.setName);
    //     setIsCreateSetMode(false);
    // }

    function handleKanjiList(): void {
        setKanjiList(!isKanjiList);
        if (!isKanjiList) {
            scrollToRef?.current!.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }

    async function handleModalButtonSaveSet(): Promise<void> {
        const data: IUserSavedSet = {
            setName: selectedSetName.trim(),
            kanjiSet: selectedKanjis,
        };

        // useEffect hook on top of script handles the closing
        // based on the returned response message
        if (selectedSetName.trim() && selectedKanjis.split(',').length < 200) {
            const { sets, responseMessage } = await createSavedSet<{ sets: IUserSavedSet[], responseMessage: string }, IUserSavedSet>({
                token,
                user: userId as string,
                data,
            });

            setUserSavedSets(sets);
            setResponseMessage(responseMessage);
            setEditSetName(false);
            if (responseMessage === RESPONSE_MESSAGE.setSaved) {
                setIsCreateSetMode(false);
                const { sets } = await getSavedSets<{ sets: IUserSavedSet[]; }>({ token, user: user?.sub as string });
                setUserSavedSets(sets);
            }
        } else {
            if (!selectedSetName.trim()) {
                setResponseMessage('You must choose a set name first!');
            } else {
                setResponseMessage('Maximum number of kanji allowed is 200. Please shorten your list.');
            }
        }
    }

    function handleCancelCreateSetButton(): void {
        setShowModalCancel(true);
    }

    function handleCreateNewSetButton(): void {
        if (isAuthenticated) {
            setIsCreateSetMode(true);
            setEditSetName(true);
            setSelectedSetName('');
            setSelectedKanjis('');
            setSelectedReviewKanjis('');
        } else {
            setShowModalAuthenticate(true);
        }
    }

    function handleSaveChanges(): void {
        if (isCreateSetMode) {
            if (selectedSetName && selectedKanjis) {
                setShowModalSave(true);
            } else {
                alert('Enter a set name and kanji first!');
            }
        } else {
            setResponseMessage('');
            setShowModalUpdate(true);
            setEditSetName(false);
        }
    }

    async function handleModalButtonUpdateSet(): Promise<void> {
        const data: IUpdateSavedSetData = {
            oldSetName: prevSelectedSetName,
            newSetName: selectedSetName,
            kanjiSet: selectedKanjis,
        };
        // useEffect hook on top of script handles the closing
        // based on the returned response message
        let changesToData = false;
        if (selectedSetName !== prevSelectedSetName) {
            changesToData = true;
        }

        if (selectedKanjis !== originalSelectedKanjiSet) {
            changesToData = true;
        };

        if (changesToData && selectedSetName.trim()) {
            const { sets, responseMessage } = await updateSavedSet<{ sets: IUserSavedSet[]; responseMessage: string; }, IUpdateSavedSetData>({
                token,
                user: userId as string,
                data,
            });
            setUserSavedSets(sets);
            setResponseMessage(responseMessage);
            setPrevSelectedSetName(selectedSetName);
        } else if (changesToData && !selectedSetName.trim()) {
            setResponseMessage('You must choose a set name first!');
        } else if (!changesToData) {
            setResponseMessage('No changes detected.');
        }
    }

    // function handleModalDeleteSet(set: IUserSavedSet): void {
    //     setShowModalDelete(true);
    //     setSelectedKanjis(set.kanjiSet);
    //     setSelectedSetName(set.setName);
    //     setPrevSelectedSetName(set.setName);
    // }

    async function handleModalButtonDeleteSet(): Promise<void> {
        handleCloseModals();
        setSelectedKanjis('');
        setSelectedReviewKanjis('');
        setSelectedSetName('');

        const data: IDeleteSavedSetData = { setName: selectedSetName };
        const { sets } = await deleteSavedSet<{ sets: IUserSavedSet[] }, IDeleteSavedSetData>({
            token,
            user: userId as string,
            data,
        });
        setUserSavedSets(sets);
    }

    function handleModalButtonAuthentication(): void {
        loginWithRedirect({ appState: { targetUrl: window.location.pathname } });
    }

    function handleCloseReviewModal(): void {
        setSelectedKanjis('');
        setSelectedReviewKanjis('');
        setShowModalReview(false);
    }

    function handleModalButtonFullSet(): void {
        setShowModalReview(false);
        const cardNumber = (cardLevel * 100) + (tabNumber * 100) + 1;
        const standardKanjiSet = getStandardKanjiSet(cardNumber);
        processFlashcardData(standardKanjiSet);
        const savedReviewCards = getCustomKanjiSet(selectedReviewKanjis);
        setReviewSet(savedReviewCards);
    }

    function handleModalButtonReviewSet(): void {
        setShowModalReview(false);
        const savedReviewCards = getCustomKanjiSet(selectedReviewKanjis);
        setReviewSet(savedReviewCards);
        setStartReview(true);
    }

    async function handleModalButtonStartOver(): Promise<void> {
        setShowModalResume(false);
        const cardNumber = (cardLevel * 100) + (tabNumber * 100) + 1;
        if (isCustomSet) {
            const kanjiSetData = await getCustomTestSet(selectedKanjis, testChoices);
            setKanjiDataSet(kanjiSetData);
            processFlashcardData(kanjiDataSet);
        } else {
            getTestSet(cardNumber);
        }
    }

    function handleModalButtonResumeTest(): void {
        setShowModalResume(false);
        setKanjiDataSet(testProgress.kanjiDataSet);
        setCarouselIndex(testProgress.carouselIndex);
        setMeaningTestResults(testProgress.meaningTestResults);
        setKanjiTestResults(testProgress.kanjiTestResults);
        setKunyomiTestResults(testProgress.kunyomiTestResults);
        setOnyomiTestResults(testProgress.onyomiTestResults);
        setTestProgress({} as ITestProgress);
    }

    function handleModalButtonKeepWokrking(): void {
        setShowModalCancel(false);
    }

    function handleModalButtonCancel(): void {
        setShowModalCancel(false);
        setIsCreateSetMode(false);
        setEditSetName(false);
        setSelectedSetName('');
        setSelectedKanjis('');
        setSelectedReviewKanjis('');
    }

    // CUSTOM KANJI SETS
    const allKanji: string[] = getAllKanji();

    async function handleClickLaunchCustomSet(): Promise<void> {
        setIsCustomSet(true);
        resetFlashcards();
        
        if (isStudyMode) {
            if (selectedKanjis) {
                const kanjiSetData = await getCustomKanjiSet(selectedKanjis);
                processFlashcardData(kanjiSetData);
            }
        } else if (!isStudyMode && (isKanjiTest || isMeaningTest || isOnyomiTest || isKunyomiTest)) {
            if (isAuthenticated) {
                // If authenticated will check DB for saved data
                const data: IFetchTestProgressData = {
                    setName: `${selectedSetName}${CUSTOM_TEST_SET}`,
                    isMeaningTest,
                    isKanjiTest,
                    isKunyomiTest,
                    isOnyomiTest,
                };

                let savedTestProgress = await fetchTestProgress<ITestProgressResponse, IFetchTestProgressData>({
                    token,
                    user: userId as string,
                    data,
                });

                if (Object.keys(savedTestProgress).length) {
                    setTestProgress({
                        ...savedTestProgress,
                        kanjiDataSet: JSON.parse(savedTestProgress.kanjiSet),
                        meaningTestResults: JSON.parse(savedTestProgress.meaningTestResults),
                        kanjiTestResults: JSON.parse(savedTestProgress.kanjiTestResults),
                        kunyomiTestResults: JSON.parse(savedTestProgress.kunyomiTestResults),
                        onyomiTestResults: JSON.parse(savedTestProgress.onyomiTestResults)
                    });
                    setShowModalResume(true);
                }
                // GET GET TEST SET IF NO PREVIOUS CACHE
                else if (selectedKanjis) {
                    const kanjiSetData = await getCustomTestSet(selectedKanjis, testChoices);
                    processFlashcardData(kanjiSetData);
                }
            } else {
                // Else not authenticated will check cache for data
                const cachedTestProgress: ITestProgress | null = JSON.parse(localStorage.getItem(selectedTestSet) || '""');
                if (cachedTestProgress) {
                    setTestProgress(cachedTestProgress);
                    setShowModalResume(true);
                } else if (selectedKanjis) {
                    // GET GET TEST SET IF NO PREVIOUS CACHE
                    const kanjiSetData = await getCustomTestSet(selectedKanjis, testChoices);
                    processFlashcardData(kanjiSetData);
                }
            }
        } else {
            alert('Select at least one Test topic to practice!');
        }
    }

    async function storeTestProgress(selectedIndex = 2): Promise<void> {
        if(!isStudyMode && (selectedSetName || cardLevel > -1)) {
            if (isAuthenticated) {
                // Will save every other click to save on data costs when usign carousel arrows
                // Will save always when 'close cards' is clicked
                if (selectedIndex % 2 === 0) {
                    const data: ISaveTestProgressData = {
                        setName: isCustomSet ? `${selectedSetName}${CUSTOM_TEST_SET}` : `${cardLevel}${tabNumber}${STANDARD_TEST_SET}`,
                        carouselIndex,
                        isMeaningTest,
                        isKanjiTest,
                        isKunyomiTest,
                        isOnyomiTest,
                        kanjiSet: JSON.stringify(kanjiDataSet),
                        meaningTestResults: JSON.stringify(meaningTestResults),
                        kanjiTestResults: JSON.stringify(kanjiTestResults),
                        kunyomiTestResults: JSON.stringify(kunyomiTestResults),
                        onyomiTestResults: JSON.stringify(onyomiTestResults)
                    };

                    await saveTestProgress<IAPIBaseResponse, ISaveTestProgressData>({
                        token,
                        user: userId as string,
                        data,
                    });
                }
            } else {
                localStorage.setItem(selectedTestSet, JSON.stringify({
                    selectedSetName,
                    cardLevel,
                    tabNumber,
                    carouselIndex,
                    kanjiDataSet,
                    isMeaningTest,
                    isKanjiTest,
                    isKunyomiTest,
                    isOnyomiTest,
                    meaningTestResults,
                    kanjiTestResults,
                    kunyomiTestResults,
                    onyomiTestResults
                }));
            }
        }
    }

    // FLASHCARDS
    function handleSelectCarousel(selectedIndex: number): void {
        // Control for left/right arrows in carousel
        setCarouselIndex(selectedIndex);
        storeTestProgress(selectedIndex);
    }

    async function editReviewSetStartReview(): Promise<void> {
        if (isAuthenticated) {
            const reviewSetName = `${REVIEW_KANJI_SET}${cardLevel + tabNumber + 1}`;
            const data: IGetReviewSetData = { reviewSetName };
            const { set: oldReviewSet } = await getReviewSet<{ set: string }, IGetReviewSetData>({
                token,
                user: userId as string,
                data,
            });
            const arrOldReviewSet = oldReviewSet.split(',');
            const currentReviewSet = reviewSet.map(({ kanji }) => kanji);
            const newReviewSet = [...new Set([...arrOldReviewSet, ...currentReviewSet])].join();

            const createData: IUserSavedSet = {
                setName: reviewSetName,
                kanjiSet: newReviewSet,
            };

            const { responseMessage: createResponseMsg } = await createSavedSet<{ sets: IUserSavedSet[], responseMessage: string }, IUserSavedSet>({
                token,
                user: userId as string,
                data: createData,
            });

            if (createResponseMsg.includes(SET_NAME_TAKEN)) {
                const updateData: IUpdateSavedSetData = {
                    oldSetName: reviewSetName,
                    newSetName: reviewSetName,
                    kanjiSet: newReviewSet,
                };

                await updateSavedSet<{ sets: IUserSavedSet[]; responseMessage: string; }, IUpdateSavedSetData>({
                    token,
                    user: userId as string,
                    data: updateData,
                });
            }
        }
    }

    async function editReviewSetCloseCards(): Promise<void> {
        if (isAuthenticated) {
            const reviewSetName = `${REVIEW_KANJI_SET}${cardLevel + tabNumber + 1}`;
            const createData: IUserSavedSet = {
                setName: reviewSetName,
                kanjiSet: selectedReviewKanjis,
            };

            const { responseMessage: createResponseMsg } = await createSavedSet<{ sets: IUserSavedSet[], responseMessage: string }, IUserSavedSet>({
                token,
                user: userId as string,
                data: createData,
            });

            if (createResponseMsg.includes(SET_NAME_TAKEN)) {
                const updateData = {
                    oldSetName: reviewSetName,
                    newSetName: reviewSetName,
                    kanjiSet: selectedReviewKanjis,
                };

                await updateSavedSet<{ sets: IUserSavedSet[]; responseMessage: string; }, IUpdateSavedSetData>({
                    token,
                    user: userId as string,
                    data: updateData,
                });
            }
        }
    }

    async function handleClickStartReview(e: MouseEvent<HTMLButtonElement>): Promise<void> {
        e.preventDefault();
        if (!isStudyMode) {
            const reviewCards: ITestKanjiData[] = [];
            (kanjiDataSet as ITestKanjiData[]).forEach((kanjiData: ITestKanjiData) => {
                if (kanjiData.id !== 0 &&
                    !kanjiTestResults.includes(kanjiData.id) &&
                    !meaningTestResults.includes(kanjiData.id) &&
                    !onyomiTestResults.includes(kanjiData.id) &&
                    !kunyomiTestResults.includes(kanjiData.id)) {
                        reviewCards.push(kanjiData);
                }
            });
            
            processReviewData(reviewCards);
            setStartReview(true);
        }

        if (isStudyMode && reviewSet.length) {
            const reviewSetName = `${REVIEW_KANJI_SET}${cardLevel + tabNumber + 1}`;
            const data = { reviewSetName };

            if (isReviewSetChanged && isAuthenticated) {
                editReviewSetStartReview()
                    .then(async (): Promise<string> => {
                        const { set: newReviewSet } = await getReviewSet<{ set: string }, IGetReviewSetData>({
                            token,
                            user: userId as string,
                            data,
                        });
                        return newReviewSet;
                    })
                    .then(newReviewSet => getCustomKanjiSet(newReviewSet))
                    .then(newReviewCards => {
                        setReviewSet(newReviewCards);
                        setStartReview(true);
                    });
                setReviewSetChanged(false);
            } else if (isAuthenticated) {
                const { set: newReviewSet } = await getReviewSet<{ set: string }, IGetReviewSetData>({
                    token,
                    user: userId as string,
                    data,
                });
                const newReviewCards = await getCustomKanjiSet(newReviewSet);
                setReviewSet(newReviewCards);
                setStartReview(true);
            } else {
                setStartReview(true);
            }
        }

        setKanjiTestResults([]);
        setMeaningTestResults([]);
        setOnyomiTestResults([]);
        setKunyomiTestResults([]);
    };

    interface IFlashcardProps {
        index: number;
        kanjiData: IKanjiData | ITestKanjiData;
    }

    function Flashcard({ index, kanjiData }: IFlashcardProps): JSX.Element {
        // STUDY MODE
        if (isStudyMode) {
            switch(kanjiData.type) {
                case FLASHCARD_TYPE.studyInstructions:
                    return <Cards.StudyInstructions isStartReview={isStartReview}/>;

                case FLASHCARD_TYPE.studyReview:
                    return (
                        <Cards.StudyReview
                            isStartReview={isStartReview}
                            reviewSet={reviewSet as IKanjiData[]}
                            selectedReviewKanjis={selectedReviewKanjis}
                            handleClickStartReview={handleClickStartReview}
                        />
                    );

                case FLASHCARD_TYPE.donate:
                    return <Cards.Donate/>;

                case FLASHCARD_TYPE.kanji:
                case FLASHCARD_TYPE.radical:
                case FLASHCARD_TYPE.pseudoRadical:
                case FLASHCARD_TYPE.protoRadical:
                default:
                    return (
                        <Cards.StudyFlashcard
                            index={index}
                            kanjiData={kanjiData}
                            isMeaningRadicals={isMeaningRadicals}
                            isOnyomiKunyomi={isOnyomiKunyomi}
                            kanjiDataSet={kanjiDataSet as IKanjiData[]}
                            kanjiSideUp={kanjiSideUp}
                            reviewSet={reviewSet as IKanjiData[]}
                            setReviewSet={setReviewSet}
                            selectedReviewKanjis={selectedReviewKanjis}
                            setSelectedReviewKanjis={setSelectedReviewKanjis}
                            isStartReview={isStartReview}
                            setReviewSetChanged={setReviewSetChanged}
                            isAuthenticated={isAuthenticated}
                            token={token}
                            user={user?.sub as string || ''}
                            darkMode={darkMode}
                        />
                    );
            }

        // TEST MODE
        } else {
            switch(kanjiData.type) {
                case FLASHCARD_TYPE.testInstructions:
                    return <Cards.TestInstructions/>;

                case FLASHCARD_TYPE.testReview:
                    let totalMeaningQs = 0;
                    let totalKanjiQs = 0;
                    let totalOnyomiQs = 0;
                    let totalKunyomiQs = 0;
                    let flashcardSet: ITestKanjiData[];

                    if (kanjiDataSet.length) {
                        flashcardSet = kanjiDataSet as ITestKanjiData[];
                    } else {
                        flashcardSet = reviewSet as ITestKanjiData[];
                    }

                    flashcardSet.forEach(kanjiData => {
                        switch (kanjiData.questionType) {
                            case QUESTION_TYPE.meaning:
                                totalMeaningQs++;
                                break;
                            case QUESTION_TYPE.kanji:
                                totalKanjiQs++;
                                break;
                            case QUESTION_TYPE.onyomi:
                                totalOnyomiQs++;
                                break;
                            case QUESTION_TYPE.kunyomi:
                                totalKunyomiQs++;
                                break;
                            default:
                                return;
                        }
                    });

                    return (
                        <Cards.TestReview
                            isStartReview={isStartReview}
                            handleClickStartReview={handleClickStartReview}
                            isKanjiTest={isKanjiTest}
                            isMeaningTest={isMeaningTest}
                            isOnyomiTest={isOnyomiTest}
                            isKunyomiTest={isKunyomiTest}
                            kanjiTestResults={kanjiTestResults}
                            meaningTestResults={meaningTestResults}
                            onyomiTestResults={onyomiTestResults}
                            kunyomiTestResults={kunyomiTestResults}
                            totalKanjiQs={totalKanjiQs}
                            totalMeaningQs={totalMeaningQs}
                            totalOnyomiQs={totalOnyomiQs}
                            totalKunyomiQs={totalKunyomiQs}
                        />
                    );

                case FLASHCARD_TYPE.donate:
                    return <Cards.Donate/>;

                case FLASHCARD_TYPE.kanji:
                case FLASHCARD_TYPE.radical:
                case FLASHCARD_TYPE.pseudoRadical:
                case FLASHCARD_TYPE.protoRadical:
                default:
                    return (
                        <Cards.TestFlashcard
                            index={index}
                            kanjiData={kanjiData as ITestKanjiData}
                            isStartReview={isStartReview}
                            kanjiTestResults={kanjiTestResults}
                            setKanjiTestResults={setKanjiTestResults}
                            meaningTestResults={meaningTestResults}
                            setMeaningTestResults={setMeaningTestResults}
                            onyomiTestResults={onyomiTestResults}
                            setOnyomiTestResults={setOnyomiTestResults}
                            kunyomiTestResults={kunyomiTestResults}
                            setKunyomiTestResults={setKunyomiTestResults}
                            isMeaningTest={isMeaningTest}
                            isKanjiTest={isKanjiTest}
                            isOnyomiTest={isOnyomiTest}
                            isKunyomiTest={isKunyomiTest}
                            darkMode={darkMode}
                        />
                    );
            }
        }
    }

    function handleCloseCards(): void {
        storeTestProgress();
        setKanjiDataSet([]);
        setReviewSet([]);
        setStartReview(false);
        setCarouselIndex(0);
        resetTestResults();
        if (isReviewSetChanged && !selectedKanjis) {
            editReviewSetCloseCards();
            setReviewSetChanged(false);
            setSelectedReviewKanjis('');
        }
        setSelectedReviewKanjis('');
    }

    function handleStartOver(): void {
        setCarouselIndex(0);
        resetTestResults();
    }

    function FlashcardControls(): JSX.Element {
        const flashcardsControlIconColor = darkMode ? 'var(--mediumGrayColor)' : 'var(--blueColor)';

        function getNextCard(carouselIndex: number): number {
            return ((Math.floor(carouselIndex / DUMMY_CARD_NUMBER) + 1) * DUMMY_CARD_NUMBER) - 1;
        }

        return (
            <div className="selfstudy-controls">
                <FlashcardButton
                    classStyles="selfstudy-control-button"
                    icon={<MdClose size="22px" color={flashcardsControlIconColor}/>}
                    text="Close"
                    onClick={() => handleCloseCards()}
                />
                {isStudyMode &&
                    <>
                        <FlashcardButton
                            classStyles="selfstudy-control-button"
                            icon={<MdFirstPage size="22px" color={flashcardsControlIconColor}/>}
                            text="Start Over"
                            onClick={() => handleStartOver()}
                        />
                        <FlashcardButton
                            classStyles="selfstudy-control-button"
                            icon={<MdLastPage size="22px" color={flashcardsControlIconColor}/>}
                            text="Skip"
                            onClick={() => { setCarouselIndex(carouselIndex => getNextCard(carouselIndex)); }}
                            // onClick={() => setCarouselIndex(halfOfSetLength)}
                        />
                        <FlashcardButton
                            classStyles="selfstudy-control-button"
                            icon={<MdGTranslate size="20px" color={flashcardsControlIconColor}/>}
                            text={kanjiSideUp ? 'Kanji Up' : 'Meaning Up'}
                            onClick={() => setKanjiSideUp(!kanjiSideUp)}
                        />
                    </>
                }
            </div>
        );
    }

    function EditButton(): JSX.Element {
        return editSetName ?
            <MdEditOff
                className="edit-set-name-icon"
                size="16px"
                onClick={() => setEditSetName(false)}
            /> :
            <MdModeEdit
                className="edit-set-name-icon"
                size="16px"
                onClick={() => setEditSetName(true)}
            />;
    }

    return (
        // <div className={`page-container flashcards ${windowWidth > 500 ? 'gray' : 'white'}`}>
        <div className="page-container selfstudy">
        {/* TABS */}
            <div className="tab-container" style={tabContainerStyles}>
                {/* STUDY/TEST MODE OPTIONS */}
                {kanjiDataSet.length === 0 && !isStartReview &&
                    <div className="tab-choices">
                        {/* TOGGLE SLIDER */}
                        <div className="tab-toggle-container">
                            <div className="tab-toggle-heading">
                                <h5>Choose<br/>Mode</h5>
                                <h6>{'>>'}</h6>
                            </div>
                            <form>
                                <label htmlFor="studyTest" className="toggle-mode-switch">
                                    <input
                                        type="checkbox"
                                        id="studyTest"
                                        defaultChecked={!isStudyMode}
                                        onChange={() => handleChangeSetMode()}
                                    />
                                    <div className="toggle-mode-slider-study">
                                        <div>勉強</div><div>試験</div>
                                    </div>
                                </label>
                            </form>
                        </div>

                        {/* STUDY AND TEST MODE OPTIONS */}
                        {isStudyMode ?
                            <Form className="tab-form">
                                <Form.Check
                                    className={`${isMeaningRadicals && isOnyomiKunyomi ? 'tab-checkbox-checked' : 'tab-checkbox'} study`}
                                    type="radio"
                                    // custom
                                    name="study-choices"
                                    id="custom-checkbox-study-all"
                                    defaultChecked={isMeaningRadicals && isOnyomiKunyomi}
                                    onChange={() => { setIsMeaningRadicals(true); setIsOnyomiKunyomi(true); }}
                                    label={<span className="study-all">Study All</span>}
                                />
                                <Form.Check
                                    className={`${isMeaningRadicals && !isOnyomiKunyomi ? 'tab-checkbox-checked' : 'tab-checkbox'} study`}
                                    type="radio"
                                    // custom
                                    name="study-choices"
                                    id="custom-checkbox-meaning-radicals"
                                    defaultChecked={isMeaningRadicals && !isOnyomiKunyomi}
                                    onChange={() => { setIsMeaningRadicals(true); setIsOnyomiKunyomi(false); }}
                                    label={<><span className="meanings">Meanings</span><br/><span className="radicals">Radicals</span></>}
                                />
                                <Form.Check
                                    className={`${!isMeaningRadicals && isOnyomiKunyomi ? 'tab-checkbox-checked' : 'tab-checkbox'} study`}
                                    type="radio"
                                    // custom
                                    name="study-choices"
                                    id="custom-checkbox-onyomi-kunyomi"
                                    defaultChecked={!isMeaningRadicals && isOnyomiKunyomi}
                                    onChange={() => { setIsMeaningRadicals(false); setIsOnyomiKunyomi(true); }}
                                    label={<><span className="onyomi">Onyomi</span><br/><span className="kunyomi">Kunyomi</span></>}
                                />
                            </Form> :

                            <Form className="tab-form">
                                <div>
                                    <Form.Check
                                        className={`${isMeaningTest ? 'tab-checkbox-checked' : 'tab-checkbox'} test`}
                                        // custom
                                        type="checkbox"
                                        id="custom-checkbox-meanings"
                                        defaultChecked={isMeaningTest}
                                        onClick={() => setMeaningTest(!isMeaningTest)}
                                        label={<span className="meanings">Meanings</span>}
                                    />
                                    <Form.Check
                                        className={`${isKanjiTest ? 'tab-checkbox-checked' : 'tab-checkbox'} test`}
                                        // custom
                                        type="checkbox"
                                        id="custom-checkbox-kanji"
                                        defaultChecked={isKanjiTest}
                                        onClick={() => setKanjiTest(!isKanjiTest)}
                                        label={<span className="radicals">Kanji</span>}
                                    />
                                </div>
                                <div>
                                    <Form.Check
                                        className={`${isOnyomiTest ? 'tab-checkbox-checked' : 'tab-checkbox'} test`}
                                        // custom
                                        type="checkbox"
                                        id="custom-checkbox-onyomi"
                                        defaultChecked={isOnyomiTest}
                                        onClick={() => setOnyomiTest(!isOnyomiTest)}
                                        label={<span className="onyomi">Onyomi</span>}
                                    />
                                    <Form.Check
                                        className={`${isKunyomiTest ? 'tab-checkbox-checked' : 'tab-checkbox'} test`}
                                        // custom
                                        type="checkbox"
                                        id="custom-checkbox-kunyomi"
                                        defaultChecked={isKunyomiTest}
                                        onClick={() => setKunyomiTest(!isKunyomiTest)}
                                        label={<span className="kunyomi">Kunyomi</span>}
                                    />
                                </div>
                            </Form>
                        }
                    </div>
                }

                {/* LEVELS & SETS */}
                {kanjiDataSet.length === 0 && !isStartReview &&
                    <div className="tab-sets">
                        {/* LEVELS */}
                        <div className="tab-levels">
                            <button className={beginnerTab} onClick={() => getCardLevel(levels.beginner)}>
                                <span className="tab-levels-japanese">初級者</span>
                                <br/>
                                BEGINNER
                            </button>

                            <button className={intermediateTab} onClick={() => getCardLevel(levels.intermediate)}>
                                <span className="tab-levels-japanese">中級者</span>
                                <br/>
                                INTERMEDIATE
                            </button>

                            <button className={advancedTab} onClick={() => getCardLevel(levels.advanced)}>
                                <span className="tab-levels-japanese">上級者</span>
                                <br/>
                                ADVANCED
                            </button>

                            <button className={customTab} onClick={() => getCardLevel(levels.custom)}>
                                <span className="tab-levels-japanese">カスタムセット</span>
                                <br/>
                                CUSTOM SET
                            </button>
                        </div>

                        {/* SET TABS */}
                        {cardLevel > -1 &&
                            <div className="tab-row-container">
                                <div className="tab-row">
                                    <TabButton indexNum={0}/>
                                    <TabButton indexNum={1}/>
                                    <TabButton indexNum={2}/>
                                    <TabButton indexNum={3}/>
                                    <TabButton indexNum={4} key={`TabButton_4`}/>
                                </div>
                                {cardLevel !== 20 &&
                                    <div className="tab-row">
                                        <TabButton indexNum={5}/>
                                        <TabButton indexNum={6}/>
                                        <TabButton indexNum={7}/>
                                        <TabButton indexNum={8}/>
                                        <TabButton indexNum={9}/>
                                    </div>
                                }
                            </div>
                        }

                        {/* CUSTOM SETS */}
                        {cardLevel === -1 && kanjiDataSet.length === 0 && !isStartReview &&
                            <div className="custom-kanji-container">
                                <div className="custom-kanji-textarea-container">
                                    <div className="selected-user-set-custom">
                                        {editSetName &&
                                            <>
                                                <input
                                                    type="text"
                                                    placeholder="Enter a custom set name"
                                                    value={selectedSetName}
                                                    onChange={e => setSelectedSetName(e.target.value)}
                                                />
                                                <EditButton/>
                                            </>
                                        }

                                        {!editSetName && selectedSetName &&
                                            <>
                                                {selectedSetName}
                                                <EditButton />
                                            </>
                                        }

                                        {!editSetName && !selectedSetName && 'Custom Set'}
                                    </div>
                                    <textarea
                                        className="custom-kanji-textarea"
                                        placeholder={customKanjiTextareaPlaceholder}
                                        maxLength={300}
                                        value={selectedKanjis}
                                        onChange={e => setSelectedKanjis(e.target.value)}
                                    />
                                </div>

                                {/* LAUNCH SET! */}
                                <div className="custom-kanji-controls">
                                    <button className="launch-set-button" type="button" onClick={() => handleClickLaunchCustomSet()}>
                                        <MdLibraryBooks className="launch-set-icon" size="16px"/>
                                        Launch Set!
                                    </button>
                                </div>

                                {/* CREATE NEW SET - SAVE CHANGES - REFRESH DATA */}
                                <div className="custom-kanji-controls">
                                    {isCreateSetMode ?
                                        <button className="search-options-button" type="button" onClick={() => handleCancelCreateSetButton()}>
                                            <MdCancel className="generic-icon" size="16px"/>
                                            Cancel
                                        </button> :

                                        <button className="search-options-button" type="button" onClick={() => handleCreateNewSetButton()}>
                                            <MdAddCircle className="generic-icon" size="16px"/>
                                            Create New Set
                                        </button>
                                    }

                                    {isAuthenticated &&
                                        <button className="search-options-button" type="button" onClick={() => handleSaveChanges()}>
                                            <MdSave className="generic-icon" size="16px"/>
                                            Save Changes
                                        </button>
                                    }

                                    {/* {isAuthenticated && !isCreateSetMode &&
                                        <button className="search-options-button" type="button" onClick={() => handleReloadSet()}>
                                            <MdRefresh className="generic-icon" size="16px"/>
                                            Reload Set
                                        </button>
                                    } */}
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>

            {/* CUSTOM KANJI BUTTONS */}
            {cardLevel === -1 && kanjiDataSet.length === 0 && !isStartReview &&
                <div className="custom-kanji-button-container">
                    {/* OPEN/CLOSE SAVED SETS */}
                    {windowWidth <= 800 &&
                        <button className="search-options-button" onClick={() => handleSavedSets()}>
                            <MdFolder className="generic-icon" size="16px"/>
                            {isSavedSets ? 'Closed Saved Sets' : 'Open Saved Sets'}
                        </button>
                    }

                    {/* KANJI LIST */}
                    {windowWidth <= 800 &&
                        <button className="search-options-button" onClick={() => handleKanjiList()}>
                            <span className="kanji-list-japanese-text">漢字</span>
                            Kanji List
                            {!isKanjiList ? <MdExpandMore className="expand-icon" size="25px"/> : <MdExpandLess className="expand-icon" size="25px"/>}
                        </button>
                    }
                </div>
            }

            {/* SAVED SETS */}
            {/* {cardLevel === -1 && kanjiDataSet.length === 0 && !isStartReview && (windowWidth > 800 || (isSavedSets && windowWidth <= 800)) &&
                <div className="saved-sets-container">
                    <div className="saved-sets-heading">
                        <h5>SAVED SETS</h5>
                        {isAuthenticated  && !isCreateSetMode &&
                            <>
                                <p>Save up to 10 sets.</p>
                                <div className="saved-sets-button-container">
                                    <button className="search-options-button" onClick={() => handleCreateNewSetButton()}>
                                        <MdAddCircle className="generic-icon" size="16px"/>
                                        Create Set
                                    </button>
                                </div>
                            </>
                        }

                        {!isAuthenticated && <p>Sign in or register to save your own custom kanji sets!</p>}
                    </div>

                    <div className="saved-sets">
                        {userSavedSets && userSavedSets.map((set, index) =>
                            <div className="saved-sets-row" key={`${set.setName}_${index}`}>
                                <button className="saved-sets-button" onClick={() => handleUserSavedSet(set)}>
                                    {set.setName || `Set ${index + 1}`}
                                </button>
                                <button className="saved-sets-delete-button" onClick={() => handleModalDeleteSet(set)}>
                                    <MdDelete className="delete-button" size="17px"/>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            } */}

            {/* CUSTOM KANJI PICKER */}
            <div ref={scrollToRef}/>

            {cardLevel === -1 && kanjiDataSet.length === 0 && !isStartReview && // if custom card level and no flashcards open AND
            (windowWidth > 800 || (isKanjiList && windowWidth <= 800)) && // if desktop OR if mobile/table and kanjiList chosen
                <Suspense fallback={<Fallback/>}>
                    <KanjiPicker
                        allKanji={allKanji}
                        selectedKanjis={selectedKanjis}
                        setSelectedKanjis={setSelectedKanjis}
                        darkMode={darkMode}
                    />
                </Suspense>
            }

            {/* FLASHCARDS */}
            <ErrorBoundary>
                {(kanjiDataSet.length > 0 || isStartReview) &&
                    <div className="selfstudy-dashboard">
                        <Carousel
                            activeIndex={carouselIndex}
                            onSelect={handleSelectCarousel}
                            interval={null}
                            keyboard={true}
                            prevIcon={isStudyMode ? <span aria-hidden="true" className="carousel-control-prev-icon"/> : null}
                            wrap={isStudyMode ? true : false}
                            indicators={false}
                            style={carouselStyles}
                        >
                            {/* === STUDY/TEST MODE === */}
                            {kanjiDataSet.length > 0 && kanjiDataSet.map((kanjiData, index) => (
                                <Carousel.Item key={`kanjiData_${kanjiData.meanings[0]}_${index}`} style={carouselItemStyles}>
                                    <Flashcard index={carouselIndex} kanjiData={kanjiData}/>
                                </Carousel.Item>
                            ))}

                            {/* === REVIEW CARDS === */}
                            {isStartReview && reviewSet.length > 0 && reviewSet.map((kanjiData, index) => (
                                <Carousel.Item key={`reviewCards_${kanjiData.meanings[0]}_${index}`} style={carouselItemStyles}>
                                    <Flashcard index={carouselIndex} kanjiData={kanjiData}/>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                        <FlashcardControls />
                    </div>
                }
            </ErrorBoundary>

            {kanjiDataSet.length === 0 && !isStartReview && <Tips.SelfStudy level={tipsLevel}/>}

            <Modal show={showModalSave} onHide={handleCloseModals} key="Modal_Create">
                <Modal.Header closeButton style={modalHeaderStyles}>
                    <Modal.Title style={modalTitleStyles}>SAVE NEW SET</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalBodyStyles}>
                    <p className="modal-text-japanese">{selectedKanjis}</p>
                    <p>Change title:</p>
                    <textarea
                        className="custom-kanji-textarea"
                        style={{ height: '50px' }}
                        placeholder="Enter new and unique set name"
                        maxLength={50}
                        value={selectedSetName}
                        onChange={e => setSelectedSetName(e.target.value)}
                    />
                    <p style={{ marginTop: '15px' }}><strong><em>{responseMessage}</em></strong></p>
                </Modal.Body>
                <Modal.Footer style={modalFooterStyles}>
                    <Button variant={darkMode ? 'secondary' : 'light'} style={modalButtonStyles} onClick={handleCloseModals}>
                        Cancel
                    </Button>
                    <Button variant={darkMode ? 'dark' : 'primary'} style={modalButtonStyles} onClick={handleModalButtonSaveSet}>
                        Save Set
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalUpdate} onHide={handleCloseModals} key="Modal_Close">
                <Modal.Header closeButton style={modalHeaderStyles}>
                    <Modal.Title style={modalTitleStyles}>SAVE CHANGES</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalBodyStyles}>
                    {selectedKanjis ?
                        <>
                            <h4>New Kanjis</h4>
                            <p className="modal-text-japanese">{selectedKanjis}</p>
                            <p>Change title:</p>
                            <textarea
                                className="custom-kanji-textarea"
                                style={{ height: '50px' }}
                                placeholder={selectedSetName.trim() && prevSelectedSetName}
                                maxLength={50}
                                value={selectedSetName}
                                onChange={e => setSelectedSetName(e.target.value)}
                            />
                        </> :

                        <p>Enter some kanji before saving set.</p>
                    }
                    <p style={{ marginTop: '15px' }}>
                        <strong><em>{responseMessage}</em></strong>
                    </p>
                </Modal.Body>
                <Modal.Footer style={modalFooterStyles}>
                    <Button variant={darkMode ? 'secondary' : 'light'} style={modalButtonStyles} onClick={handleCloseModals}>
                        Cancel
                    </Button>
                    {selectedKanjis &&
                        <Button variant={darkMode ? 'dark' : 'primary'} style={modalButtonStyles} onClick={handleModalButtonUpdateSet}>
                            Confirm Changes
                        </Button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={showModalDelete} onHide={handleCloseModals} key="Modal_Delete">
                <Modal.Header closeButton style={modalHeaderStyles}>
                    <Modal.Title style={modalTitleStyles}>CONFIRM DELETE</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalBodyStyles}>
                    <p>Are you sure you want to delete <strong>{selectedSetName}</strong>?</p>
                    <p>You cannot undo the delete.</p>
                </Modal.Body>
                <Modal.Footer style={modalFooterStyles}>
                    <Button variant={darkMode ? 'secondary' : 'light'} style={modalButtonStyles} onClick={handleCloseModals}>
                        Cancel
                    </Button>
                    <Button variant="danger" style={modalButtonStyles} onClick={handleModalButtonDeleteSet}>
                        Delete Set
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalAuthenticate} onHide={handleCloseModals} key="Modal_Authenticate">
                <Modal.Header closeButton style={modalHeaderStyles}>
                    <Modal.Title style={modalTitleStyles}>LOGIN REQUIRED</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalBodyStyles}>
                    <p>Sign in or create an account first before saving custom sets.</p>
                </Modal.Body>
                <Modal.Footer style={modalFooterStyles}>
                    <Button variant={darkMode ? 'secondary' : 'light'} style={modalButtonStyles} onClick={handleCloseModals}>
                        Cancel
                    </Button>
                    <Button variant={darkMode ? 'dark' : 'primary'} style={modalButtonStyles} onClick={handleModalButtonAuthentication}>
                        Sign In or Register
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalReview} onHide={handleCloseReviewModal} key="Modal_Review">
                <Modal.Header closeButton style={modalHeaderStyles}>
                    <Modal.Title style={modalTitleStyles}>REVIEW SET?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalBodyStyles}>
                    <h4>Kanjis previously marked for review in this set</h4>
                    <p className="modal-text-japanese">{selectedReviewKanjis}</p>
                </Modal.Body>
                <Modal.Footer style={modalFooterStyles}>
                    <Button variant={darkMode ? 'secondary' : 'light'} style={modalButtonStyles} onClick={handleModalButtonFullSet}>
                        Show Full Set
                    </Button>
                    <Button variant={darkMode ? 'dark' : 'primary'} style={modalButtonStyles} onClick={handleModalButtonReviewSet}>
                        Show Review Set
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalResume} onHide={handleCloseModals} key="Modal_Resume">
                <Modal.Header closeButton style={modalHeaderStyles}>
                    <Modal.Title style={modalTitleStyles}>RESUME TEST?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalBodyStyles}>
                    <h4>Do you want to pick up where you left off or start over?</h4>
                </Modal.Body>
                <Modal.Footer style={modalFooterStyles}>
                    <Button variant={darkMode ? 'secondary' : 'light'} style={modalButtonStyles} onClick={handleModalButtonStartOver}>
                        Start Over
                    </Button>
                    <Button variant={darkMode ? 'dark' : 'primary'} style={modalButtonStyles} onClick={handleModalButtonResumeTest}>
                        Resume Test
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalCancel} onHide={handleCloseModals} key="Modal_Cancel">
                <Modal.Header closeButton style={modalHeaderStyles}>
                    <Modal.Title style={modalTitleStyles}>STOP CREATING A NEW SET?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalBodyStyles}>
                    <h4>Are you sure you want to cancel? You'll lose all changes.</h4>
                </Modal.Body>
                <Modal.Footer style={modalFooterStyles}>
                    <Button variant={darkMode ? 'secondary' : 'light'} style={modalButtonStyles} onClick={handleModalButtonKeepWokrking}>
                        Keep Working on Set
                    </Button>
                    <Button variant="danger" style={modalButtonStyles} onClick={handleModalButtonCancel}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};