import React from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Context } from 'context/context';
import { AuthProvider } from 'context/authContext';
import { IContext } from 'interfaces/interfaces';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'odometer/themes/odometer-theme-default.css';

// Components
import Auth0ProviderWithHistory from 'Auth0ProviderWithHistory';
import Router from  'Router';
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import ScrollToTop from 'components/ScrollToTop';

// Styles
// general
import 'assets/styles/global.css';
import 'assets/styles/forms.css';
  // navbar
import 'assets/styles/navbar.css';
import 'assets/styles/dropdown.css';
import 'assets/styles/hamburger.css';
  // footer
import './assets/styles/footer.css';
  // components and containers
import 'assets/styles/home.css';
import 'assets/styles/siteinfo.css';
import 'assets/styles/auth.css';
import 'assets/styles/kanjisearch.css';
import 'assets/styles/flashcards.css';
import 'assets/styles/selfstudy.css';
import 'assets/styles/theory.css';
import 'assets/styles/cards.css';
import 'assets/styles/advertising.css';
import 'assets/styles/scoreodometer.css';

const App: React.FC = () => {
    const [darkMode, setDarkMode] = useState(false);
    const [isStartFlashcards, setStartFlashcards] = useState(false);
    const [isFlashcardsPage, setFlashcardsPage] = useState(false);
    const [isChallengeRound, setChallengeRound] = useState(false);

    // OVERALL FLASHCARD RECORD
    const [totalCardsSeen, setTotalCardsSeen] = useState(0);
    const [totalCardsAnswered, setTotalCardsAnswered] = useState(0);
    const [totalCardsCorrect, setTotalCardsCorrect] = useState(0);
    const [totalScore, setTotalScore] = useState(0);

    // CARDS SEEN
    const [meaningCardsSeen, setMeaningCardsSeen] = useState(0);
    const [kanjiCardsSeen, setKanjiCardsSeen] = useState(0);
    const [onyomiCardsSeen, setOnyomiCardsSeen] = useState(0);
    const [kunyomiCardsSeen, setKunyomiCardsSeen] = useState(0);

    // CARDS CORRECT
    
    const [meaningCardsCorrect, setMeaningCardsCorrect] = useState(0);
    const [kanjiCardsCorrect, setKanjiCardsCorrect] = useState(0);
    const [onyomiCardsCorrect, setOnyomiCardsCorrect] = useState(0);
    const [kunyomiCardsCorrect, setKunyomiCardsCorrect] = useState(0);

    // SCORE TRACKER
    const [meaningScore, setMeaningScore] = useState(0);
    const [kanjiScore, setKanjiScore] = useState(0);
    const [onyomiScore, setOnyomiScore] = useState(0);
    const [kunyomiScore, setKunyomiScore] = useState(0);
    const [totalScoreDigits, setTotalScoreDigits] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    const value: IContext = {
        isStartFlashcards,
        setStartFlashcards,
        isFlashcardsPage,
        setFlashcardsPage,
        isChallengeRound,
        setChallengeRound,

        // OVERALL FLASHCARD RECORD
        totalCardsSeen,
        setTotalCardsSeen,
        totalCardsAnswered,
        setTotalCardsAnswered,
        totalCardsCorrect,
        setTotalCardsCorrect,
        totalScore,
        setTotalScore,
        totalScoreDigits,
        setTotalScoreDigits,

        // CARDS SEEN
        meaningCardsSeen,
        setMeaningCardsSeen,
        kanjiCardsSeen,
        setKanjiCardsSeen,
        onyomiCardsSeen,
        setOnyomiCardsSeen,
        kunyomiCardsSeen,
        setKunyomiCardsSeen,

        // CARDS CORRECT
        meaningCardsCorrect,
        setMeaningCardsCorrect,
        kanjiCardsCorrect,
        setKanjiCardsCorrect,
        onyomiCardsCorrect,
        setOnyomiCardsCorrect,
        kunyomiCardsCorrect,
        setKunyomiCardsCorrect,

        // Score TRACKER
        meaningScore,
        setMeaningScore,
        kanjiScore,
        setKanjiScore,
        onyomiScore,
        setOnyomiScore,
        kunyomiScore,
        setKunyomiScore,
    };

    function toggleTheme(): void {
        localStorage.setItem('site-dark-mode', JSON.stringify(!darkMode));
        setDarkMode(darkMode => !darkMode);
    }

    useEffect(() => {
        const json = localStorage.getItem('site-dark-mode');
        const currentMode: boolean = JSON.parse(json as string);

        if (currentMode) {
            setDarkMode(true);
        } else {
            setDarkMode(false);
        }
    }, []);

    return (
        <>
            <BrowserRouter>
                {/* <Auth0ProviderWithHistory> */}
                <AuthProvider>
                    <ScrollToTop/>
                    <div id={darkMode ? 'dark' : 'light'}>
                        <Context.Provider value={value}>
                            <Navbar darkMode={darkMode} toggleTheme={toggleTheme}/>
                            <Router darkMode={darkMode}/>
                            {!isFlashcardsPage && <Footer darkMode={darkMode}/>}
                        </Context.Provider>
                    </div>
                </AuthProvider>
                {/* </Auth0ProviderWithHistory> */}
            </BrowserRouter>
        </>
    );
}

export default App;