import React, { useContext, useEffect, useRef, useState, ChangeEvent, KeyboardEvent, MouseEvent, ReactNode, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import {
    MdBuild,
    MdWarning,
    MdNotInterested,
    MdSentimentVeryDissatisfied,
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight,
    MdArrowForward,
    MdCheck,
    MdClose,
} from 'react-icons/md';
import {
    FaFaceGrinStars as SuccessIcon,
    FaFaceSadCry as FailureIcon,
} from 'react-icons/fa6';
import { SiBuymeacoffee, SiPatreon } from 'react-icons/si';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useAuth } from 'context/authContext';
import { Context } from 'context/context';
// import { SignInButton } from 'components/auth';
import { Mnemonic } from 'components';
// import searchServices from 'middleware/searchServices';
import userAccountServices from 'middleware/userAccountServices';
import flashcardEngine from 'middleware/flashcardEngine';
import pseudoRadicals from 'helpers/pseudoRadicals';
import {
    DUMMY_CARD_NUMBER,
    FLASHCARD_TYPE,
    KANJI_SEARCH_PATH,
    QUESTION_TYPE,
    NO_READING,
    EASY_THRESHOLD,
    NUM_RADICAL_CHOICES,
} from 'constants/constants';
import {
    IDictionaryCardProps,
    IStudyInstructionsProps,
    IStudyFlashcardProps,
    IStudyReviewProps,
    ITestFlashcardProps,
    ITestReviewProps,
    IAPIBaseResponse,
    IInstructionsFlashcardProps,
    IQuizFlashcardProps,
    INewFlashcardProps,
    IReviewFlashcardProps,
    IChallengeSucessProps,
    IChallengeFailureProps,
    IAdvertisingCardProps,
    IContext,
    IScoreAlert,
    IFlashcardHistory,
} from 'interfaces/interfaces';
import toKana from 'helpers/romajiToKana';

// const { kanjis } = searchServices;
const { auth } = userAccountServices;
const { setUserData, updateFlashcardRecordData, setHighestSeenKanji } = flashcardEngine;


function digitBreakdown(num: number): number[] {
    // const digits: number[] = [];

    // while (num !== 0) {
    //     digits.push(num % 10);
    //     num = Math.floor(num / 10);
    // }

    // return digits.reverse();

    const digits: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    let n = 0;
    let quotient = num;

    while (quotient >= 10) {
        quotient = num / (10 ** n);
        n++;
    }

    let remainder = num;
    for (let i = n; i > 0; i--) {
        const m = i - 1;
        const digit = Math.floor(remainder / (10 ** m));
        digits[digits.length - i] = digit;
        remainder -= digit * (10 ** m);
    }

    return digits;
}

// FLASHCADictionaryCardRD TEMPLATES
const Cards = {
    // KANJI SEARCH
    DictionaryCard: function ({ 
        showMeaning,
        showAll,
        kanjiData,
        searchFlashcard,
        darkMode
    }: IDictionaryCardProps): JSX.Element {
        // Will check for this path to disable :hover css in flashcard app
        const location = useLocation();

        return (
            <div className="dictionary-card" key={`${kanjiData.id}_${kanjiData.meanings[0]}`}>
                {/* HEADING: KANJI # ORDER | MEANINGS */}
                <div className="dictionary-card-kanji-heading">
                    <div className="dictionary-card-left">
                        <h5><span className="dictionary-card-japanese">漢字 </span> Kanji {kanjiData.id}</h5>
                    </div>
                    <div className="dictionary-card-right">
                        <h5><span className="dictionary-card-japanese">意味 </span> Meaning</h5>
                    </div>
                </div>

                {/* ENTRY: KANJI | MEANINGS | STROKES */}
                <div className="dictionary-card-meanings-entry">
                    <div className="dictionary-card-left">
                        {kanjiData.type === 'Pseudoradical' ?
                            <img src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(kanjiData.meanings[0])][darkMode ? 'gray' : 'black']} alt="kanji character"/> :

                            <div className="dictionary-card-kanji" onClick={() => searchFlashcard(kanjiData.kanji[0])}>
                                <span lang="ja">{kanjiData.kanji}</span>
                            </div>
                        }
                    </div>

                    {(showMeaning || showAll) &&
                        <div className="dictionary-card-right">
                            {kanjiData.meanings.map(meaning =>
                                <div className="dictionary-card-meaning" key={meaning} onClick={() => searchFlashcard(meaning)}>
                                    {meaning}
                                </div>
                            )}
                            <h5 className="strokes"><span className="dictionary-card-japanese">画数</span> Strokes</h5>
                            {kanjiData.strokes}
                        </div>
                    }
                </div>

                {/*  BOTTOM OF FLASHCARD  */}
                <div className="dictionary-card-bottom">
                    {/* MNEMONIC SENTENCE */}
                    <div className="dictionary-card-mnemonic">
                        {Mnemonic(kanjiData.mnemonic)}
                    </div>

                    {/* HEADING: BUSHU | RADICAL */}
                    <div className="dictionary-card-heading">
                        <div className="dictionary-card-left">
                            <span className="dictionary-card-japanese">部首</span>
                        </div>
                        <div className="dictionary-card-right">
                            <h5>Radicals</h5>
                        </div>
                    </div>

                    {/* ENTRY: BUSHU | RADICAL */}
                    <div className="dictionary-card-entry">
                        <div className="dictionary-card-left">
                            {kanjiData.bushu.map((bushu, index) =>
                                <div key={`${bushu}_${index}`}>
                                    {pseudoRadicals.meanings.includes(kanjiData.radicals[index]) ?
                                        <img src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(kanjiData.radicals[index])][darkMode ? 'gray' : 'white']} alt={kanjiData.radicals[index]}/> :
                                        <span className="dictionary-card-japanese">{bushu}</span>
                                    }
                                </div>
                            )}
                        </div>

                        {/* RADICAL */}
                        <div className="dictionary-card-right">
                            {kanjiData.radicals.map((radical, index) =>
                                <div className="dictionary-card-radical" key={`${radical}_${index}`} onClick={() => searchFlashcard(radical)}>
                                    <span lang="ja">{radical}</span>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* ONYOMI | CHINESE READINGS */}
                    {kanjiData.onyomi[0] &&
                        <>
                            <div className="dictionary-card-heading">
                                <div className="dictionary-card-left">
                                    <span className="dictionary-card-japanese">音読み</span>
                                </div>
                                <div className="dictionary-card-right">
                                    <h5>Onyomi</h5>
                                </div>
                            </div>

                            <div className="dictionary-card-entry">
                                <div className="dictionary-card-left">
                                    {kanjiData.onyomi.map(onyomi =>
                                        <div className="dictionary-card-japanese" key={onyomi}>{toKana(onyomi)}</div>
                                    )}
                                </div>
                                <div className="dictionary-card-right">
                                    {kanjiData.onyomi.map(onyomi =>
                                        <div className={location.pathname === KANJI_SEARCH_PATH ? 'dictionary-card-onyomi' : '' } key={onyomi} onClick={() => searchFlashcard(onyomi)}>
                                            {onyomi}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    }

                    {/* KUNYOMI | JAPANESE READINGS */}
                    {kanjiData.kunyomi[0] &&
                        <>
                            <div className="dictionary-card-heading">
                                <div className="dictionary-card-left">
                                    <span className="dictionary-card-japanese">訓読み</span>
                                </div>
                                <div className="dictionary-card-right">
                                    <h5>Kunyomi</h5>
                                </div>
                            </div>

                            <div className="dictionary-card-entry">
                                <div className="dictionary-card-left">
                                    {kanjiData.kunyomi.map((kunyomi, index) =>
                                        <div className="dictionary-card-japanese" key={`${kunyomi}_${index}`}>{toKana(kunyomi)}</div>
                                    )}
                                </div>
                                <div className="dictionary-card-right">
                                    {kanjiData.kunyomi.map(kunyomi =>
                                        <div className={location.pathname === KANJI_SEARCH_PATH ? 'dictionary-card-kunyomi' : ''} key={kunyomi} onClick={() => searchFlashcard(kunyomi)}>
                                            {kunyomi}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    }

                    {/* NOTES */}
                    {kanjiData.notes &&
                        <>
                            <div className="dictionary-card-heading">
                                <div className="dictionary-card-left">Notes</div>
                            </div>
                            <div className="dictionary-card-entry">{kanjiData.notes}</div>
                        </>
                    }
                </div>
            </div>
        )
    },

    // SELF STUDY: STUDY FLASHCARDS
    StudyInstructions: function({ isStartReview }: IStudyInstructionsProps): JSX.Element {

        return (
            <>
                <div className="flashcard-top instructions">
                    <div className="flashcard-top-details">
                        <h6>Instructions</h6>
                        <div className="flashcard-top-instruction">
                            Click the buttons on the bottom of the interactive flashcards.
                        </div>

                        {!isStartReview &&
                            <div className="flashcard-top-instruction">
                                Mark a card for review at the end.
                                <div className="flashcard-bottom-button">
                                    Review Later
                                </div>
                            </div>
                        }

                        <div className="flashcard-top-instruction">
                            Create your own notes for that card.
                            <div className="flashcard-bottom-button">
                                Create Notes
                            </div>
                        </div>

                        <div className="flashcard-top-instruction">
                            Choose between Kanji or Meaning Side Up while studying.
                        </div>
                    </div>
                </div>
                <div className="flashcard-bottom instructions">
                    <div>
                        <p>To go back, click or keyboard the left arrow or swipe.</p>
                        <MdKeyboardArrowLeft size="35px" className="arrow-icon"/>
                    </div>
                    <div className="right-column">
                        <p>To go forward, click or keyboard the right arrow or swipe.</p>
                        <MdKeyboardArrowRight size="35px"className="arrow-icon"/>
                    </div>
                </div>
            </>
        );
    },

    StudyFlashcard: function({
        index,
        kanjiData,
        isMeaningRadicals = true,
        isOnyomiKunyomi = true,
        kanjiDataSet,
        kanjiSideUp,
        reviewSet,
        setReviewSet,
        selectedReviewKanjis,
        setSelectedReviewKanjis,
        isStartReview,
        setReviewSetChanged,
        isAuthenticated,
        token,
        user,
        darkMode
    }: IStudyFlashcardProps): JSX.Element {
        const [newNotes, setNewNotes] = useState('');
        const [isInReview, setIsInReview] = useState(true);
        const [revealData, setRevealData] = useState(false);
        const [customNotes, setCustomNotes] = useState(false);

        // SAVES CUSTOM NOTES TO ONLY CURRENT CARDS AND PREVENTS NOTES FROM BEING SAVED ACCROSS ALL CARDS
        useEffect(() => {
            const kanjiIndex = kanjiData.id % 100 || 100;
            const cardIndex = index % DUMMY_CARD_NUMBER !== 0
                ? (index - Math.floor(index/DUMMY_CARD_NUMBER))
                : 0;

            if (revealData && isAuthenticated && isInReview && kanjiData.id && kanjiDataSet[index] &&
                (kanjiIndex === cardIndex || kanjiData.id === kanjiDataSet[index].id)
            ) {
                interface IFetchCustomNotesData {
                    kanji: number; // TODO change in backend to kanjiId
                }
                const data: IFetchCustomNotesData = { kanji: kanjiData.id };
                auth.fetchCustomNotes<{ statusCode: number; notes: string; }, IFetchCustomNotesData>({ token, user, data })
                    .then(({ statusCode, notes }) => {
                        if (statusCode === 201) {
                            setNewNotes(notes);
                        }
                    })
                    .catch(err => console.error(err));
            }
        }, [customNotes]);

        // ADD/REMOVE KANJI FROM REVIEW
        useEffect(() => {
            let isInReviewSet = true;
            let isInSelectedKanji = true;
            const reviewSetIds = reviewSet.map(card => card.id);

            if (!reviewSetIds.includes(kanjiData.id)) {
                isInReviewSet = false
            } if (!selectedReviewKanjis.includes(kanjiData.kanji)) {
                isInSelectedKanji = false;
            } if (!isInReviewSet && !isInSelectedKanji) {
                setIsInReview(false);
            }
        }, [reviewSet, selectedReviewKanjis]);

        async function saveNotes(): Promise<void> {
            setCustomNotes(false);
            interface ISaveCustomNotesData {
                kanji: number;
                notes: string;
            }

            const data: ISaveCustomNotesData = {
                kanji: kanjiData.id,
                notes: newNotes,
            };

            await auth.saveCustomNotes<IAPIBaseResponse, ISaveCustomNotesData>({ token, user, data });
        }

        // function handleClickSaveOrCreateNotes(e: MouseEvent<HTMLButtonElement>): void {
        //     e.preventDefault();
        //     if (customNotes) {
        //         saveNotes();
        //     } else {
        //         // By setting notes to true, a useEffect is triggered above.
        //         // This then makes a call to the API to get the user's custom notes.
        //         setCustomNotes(true);
        //     }
        // }

        function handleClickAddToReview(e: MouseEvent<HTMLButtonElement>): void {
            e.preventDefault();
            const reviewSetIds = reviewSet.map(card => card.id);
            if (!reviewSetIds.includes(kanjiData.id)) {
                setReviewSet(cards => [...cards, kanjiData]);
                setReviewSetChanged(true);
            }

            if (!selectedReviewKanjis.includes(kanjiData.kanji)) {
                setSelectedReviewKanjis(kanjis => `${kanjis},${kanjiData.kanji}`);
                setReviewSetChanged(true);
            }
        }

        function handleClickRemoveFromReview(e: MouseEvent<HTMLButtonElement>): void {
            e.preventDefault();
            const reviewSetIds = reviewSet.map(card => card.id);
            const cardIndex = reviewSetIds.indexOf(kanjiData.id);
            if (cardIndex > -1) {
                setReviewSet(cards => [...cards.slice(0, cardIndex), ...cards.slice(cardIndex + 1)]);
                setReviewSetChanged(true);
            }

            const kanjiIndex = selectedReviewKanjis.split(',').indexOf(kanjiData.kanji);
            if (kanjiIndex > -1) {
                setSelectedReviewKanjis(kanjis => {
                    const firstHalf = kanjis.split(',').slice(0, kanjiIndex);
                    const secondHalf = kanjis.split(',').slice(kanjiIndex + 1);
                    return [...firstHalf, ...secondHalf].join();
                });
                setReviewSetChanged(true);
            }
        }

        function handleClickFlipCard(e: MouseEvent<HTMLButtonElement>): void {
            e.preventDefault();
            setRevealData(!revealData);
        }

        return (
            <>
                {/* TOP */}
                {revealData ?
                    <div className="flashcard-top study" key={`StudyTopFlashcard_${kanjiData.kanji}_${index}`}>
                        <div className="flashcard-top-container">
                            {/* ==== LEFT SIDE ==== */}
                            <div className="flashcard-top-left-container">
                                {/* KANJI */ }
                                <div className="flashcard-top-heading">
                                    <span className="dictionary-card-japanese heading">漢字</span>
                                    {kanjiData.id}
                                </div>
                                <div className="flashcard-top-kanji">
                                    {kanjiData.type === 'Pseudoradical' ?
                                        <img className="flashcard-top-pseudoradical" src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(kanjiData.meanings[0])].white} alt="kanji character"/> :
                                        <span lang="ja">{kanjiData.kanji}</span>
                                    }
                                </div>

                                {/* STROKES */}
                                <div className="strokes">{`${kanjiData.strokes} Stroke${kanjiData.strokes === 1 ? '' : 's'}`}</div>

                                {/* MEANING */}
                                {kanjiData.meanings.map(meaning =>
                                    <div
                                        key={meaning}
                                        className={meaning.length > DUMMY_CARD_NUMBER && !meaning.includes(' ') && !meaning.includes('-')
                                            ? 'flashcard-top-meaning-long'
                                            : 'flashcard-top-meaning-short'
                                        }
                                    >
                                        {meaning}
                                    </div>
                                )}
                            </div>

                            {/* ==== RIGHT SIDE ==== */}
                            <div className="flashcard-top-right-container">
                                {/*  TOP ROW: BUSHU | ONYOMI KATAKANA | KUNYOMI HIRAGANA  */}
                                <div className="flashcard-top-right-top-row">
                                    {/* BUSHU */}
                                    <div className="flashcard-top-radicals-column">
                                        <div className="flashcard-top-heading">
                                            <span className="dictionary-card-japanese">部首</span>
                                        </div>
                                        {kanjiData.bushu.map((bushu, index) => {
                                            if (pseudoRadicals.meanings.includes(kanjiData.radicals[index])) {
                                                return (
                                                    <img
                                                        className="flashcard-top-bushu-pseudoradical"
                                                        src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(kanjiData.radicals[index])][darkMode ? 'gray' : 'white']}
                                                        alt={kanjiData.radicals[index]}
                                                        key={`img_${bushu}_${index}_${kanjiData.meanings[0]}`}
                                                    />
                                                );
                                            } else {
                                                return <div key={`${bushu}_${index}_${kanjiData.meanings[0]}`}>{bushu}</div>;
                                            }
                                        })}
                                    </div>

                                    {/* ONYOMI KATAKANA */}
                                    <div className="flashcard-top-onyomi-column">
                                        <div className="flashcard-top-heading">
                                            <span className="dictionary-card-japanese">音読み</span>
                                        </div>
                                        {kanjiData.onyomi.map((onyomi, index) =>
                                            <div key={`${onyomi}_${index}_${kanjiData.meanings[0]}`}>{toKana(onyomi)}</div>
                                        )}
                                    </div>

                                    {/* KUNYOMI HIRAGANA */}
                                    <div className="flashcard-top-kunyomi-column">
                                        <div className="flashcard-top-heading">
                                            <span className="dictionary-card-japanese">訓読み</span>
                                        </div>
                                        {kanjiData.kunyomi.map((kunyomi, index) =>
                                            <div key={`${kunyomi}_${index}_${kanjiData.meanings[0]}`}>{toKana(kunyomi)}</div>
                                        )}
                                    </div>
                                </div>


                                {/* BOTTOM ROW: RADICALS | ONYOMI ROMAJI | KUNYOMI ROMAJI */}
                                <div className="flashcard-top-right-bottom-row">
                                    {/* RADICALS */}
                                    <div className="flashcard-top-radicals-column">
                                        <div className="flashcard-top-heading">Radicals</div>
                                        {kanjiData.radicals.map((radical, index) =>
                                            <div className="r-highlight" key={`${radical}_${index}_${kanjiData.meanings[0]}`}>{radical}</div>
                                        )}
                                    </div>

                                    {/* ONYOMI ROMAJI */}
                                    <div className="flashcard-top-onyomi-column">
                                        <div className="flashcard-top-heading">On</div>
                                        {kanjiData.onyomi.map((onyomi, index) =>
                                            <div className="o-highlight" key={`${onyomi}_${index}_${kanjiData.meanings[0]}`}>{onyomi}</div>
                                        )}
                                    </div>

                                    {/* KUNYOMI ROMAJI */}
                                    <div className="flashcard-top-kunyomi-column">
                                        <div className="flashcard-top-heading">Kun</div>
                                        {kanjiData.kunyomi.map((kunyomi, index) =>
                                            <div className="k-highlight" key={`${kunyomi}_${index}_${kanjiData.meanings[0]}`}>{kunyomi}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flashcard-top-mnemonic">
                            {Mnemonic(kanjiData.mnemonic, isMeaningRadicals, isOnyomiKunyomi)}
                        </div>
                    </div> :

                    <div className="flashcard-top-placeholder">
                        {kanjiSideUp ?
                            <div className="flashcard-top-placeholder__kanji">
                                {!isInReview && isStartReview &&
                                    <div className="flashcard-top-placeholder__removed-overlay">
                                        <div className="japanese-text">削除</div>
                                        Removed From Review
                                    </div>
                                }
                                {kanjiData.type === 'Pseudoradical' ?
                                    // Pseudoradical
                                    <img
                                        className="flashcard-top-placeholder__pseudoradical"
                                        src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(kanjiData.meanings[0])][darkMode ? 'gray' : 'black']}
                                        alt="kanji character"
                                    /> :
                                    kanjiData.kanji
                                }
                            </div> :

                            <div className="flashcard-top-placeholder__meaning">
                                {!isInReview && isStartReview &&
                                    <div className="flashcard-top-placeholder__removed-overlay">
                                        <div className="japanese-text">削除</div>
                                        Removed From Review
                                    </div>
                                }
                                {kanjiData.meanings[0]}
                            </div>
                        }
                    </div>
                }

                {/* BOTTOM */}
                <div className="flashcard-bottom">
                    {/* LEFT SIDE */}
                    <div className="flashcard-bottom-column">
                        <h6>Kanji {kanjiData.id}</h6>

                        {!revealData &&
                            <div className="flashcard-bottom-item-container">
                                {/* KANJI */}
                                {kanjiSideUp &&
                                    <div className="flashcard-bottom-item">
                                        <div className="flashcard-bottom-kanji">
                                            {kanjiData.type === 'Pseudoradical' ?
                                                // Pseudoradical
                                                <img
                                                    className="flashcard-bottom-kanji-pseudoradical"
                                                    src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(kanjiData.meanings[0])][darkMode ? 'gray' : 'black']}
                                                    alt="kanji character"
                                                /> :

                                                // Regular kanji or radical
                                                <>{kanjiData.kanji}</>
                                            }
                                        </div>
                                        <div className="flashcard-kanji-backdrop"/>
                                    </div>
                                }

                                {/* MEANING */}
                                {!kanjiSideUp &&
                                    <div className="flashcard-bottom-item">
                                        <div className="flashcard-bottom-meaning">
                                            {kanjiData.meanings[0].toUpperCase()}
                                        </div>
                                        <div className="flashcard-meaning-backdrop"/>
                                    </div>
                                }
                            </div>
                        }

                        {revealData && kanjiData.notes &&
                            <div className="flashcard-bottom-notes">{kanjiData.notes}</div>
                        }
                    </div>

                    {/* RIGHT SIDE */}
                    <div className="flashcard-bottom-column">
                        <div className="flashcard-bottom-controls">
                            {((!isStartReview && !customNotes) || (isStartReview && isInReview && !customNotes)) &&
                                <button className="flashcard-bottom-button" onClick={handleClickFlipCard}>
                                    Flip Card
                                </button>
                            }

                            {isStartReview && isInReview && isAuthenticated && !customNotes &&
                                <button className="flashcard-bottom-button" onClick={handleClickRemoveFromReview}>
                                    Remove
                                </button>
                            }

                            {!isStartReview && !customNotes && !reviewSet.find(({ id }) => id === kanjiData.id) &&
                                <button className="flashcard-bottom-button" onClick={handleClickAddToReview}>
                                    Review Later
                                </button>
                            }

                            {!isStartReview && !customNotes && reviewSet.find(({ id }) => id === kanjiData.id) &&
                                <button className="flashcard-bottom-button" style={{ fontSize: '11px' }} onClick={handleClickRemoveFromReview}>
                                    Remove from Review
                                </button>
                            }

                            {isStartReview && !isInReview && !customNotes &&
                                <button className="flashcard-bottom-button" onClick={handleClickAddToReview}>
                                    Undo
                                </button>
                            }

                            {/* {((revealData && isStartReview && isInReview) || (revealData && !isStartReview)) &&
                                <button className="flashcard-bottom-button" onClick={handleClickSaveOrCreateNotes}>
                                    {customNotes ? 'Save Notes' : 'Create Notes'}
                                </button>
                            } */}
                        </div>
                    </div>
                </div>
            </>
        );
    },

    StudyReview: function({
        isStartReview,
        reviewSet,
        selectedReviewKanjis,
        handleClickStartReview,
    }: IStudyReviewProps): JSX.Element {
        const arrReviewSet = reviewSet.map(card => card.kanji);
        const arrReviewKanjis = selectedReviewKanjis.split(',');
        const reviewCardTiles = [...new Set([...arrReviewKanjis, ...arrReviewSet])].filter(kanji => kanji);
        const numberOfCards = reviewCardTiles.length;

        return (
            <>
                <div className="flashcard-top review">
                    <div className="flashcard-top-details">
                        <h6>Review</h6>
                        {isStartReview
                            ? <h1>You finished your review!</h1>
                            : <h1>You marked {numberOfCards || 'no'} card{numberOfCards === 1 ? '' : 's'} for review.</h1>
                        }

                        {numberOfCards > 0 &&
                            <div className="flashcard-top-review-tiles">
                                {reviewCardTiles.slice(0, 50).map((kanji, index) => (
                                    <div className="flashcard-top-review-kanji" key={`${kanji}_${index}`}>{kanji}</div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <div className="flashcard-bottom test-review">
                    {!isStartReview ?
                        <div className="flashcard-bottom-column">
                            <div className="flashcard-bottom-controls">
                                {numberOfCards !== 0 &&
                                    <button className="flashcard-bottom-button start-review" onClick={handleClickStartReview}>
                                        Start Review
                                    </button>
                                }
                            </div>
                        </div> :
                        <h2>Great review! The best way to learn is by forgetting things.</h2>
                    }
                </div>
            </>
        );
    },

    // SELF STUDY: TEST FLASHCARDS
    TestInstructions: function(): JSX.Element {
        return (
            <>
                <div className="flashcard-top instructions">
                    <div className="flashcard-top-details">
                        <h6>Instructions</h6>
                        <br/>
                        <p>Answer the questions by selecting the correct choice on the top of the flashcard.</p>
                        <br/>
                        <p>Wrong or unanswered questions can be revisited in the review session at the end.</p>
                        <br/>
                    </div>
                </div>
                <div className="flashcard-bottom instructions">
                    <div className="test-instructions">
                        <p>To go forward, click or keyboard the right arrow or swipe.</p>
                        <MdKeyboardArrowRight size="35px" className="arrow-icon"/>
                    </div>
                </div>
            </>
        );
    },

    TestFlashcard: function({
        index,
        kanjiData,
        isStartReview,
        kanjiTestResults,
        meaningTestResults,
        onyomiTestResults,
        kunyomiTestResults,
        setKanjiTestResults,
        setMeaningTestResults,
        setOnyomiTestResults,
        setKunyomiTestResults,
        isMeaningTest,
        isKanjiTest,
        isOnyomiTest,
        isKunyomiTest,
        darkMode
    }: ITestFlashcardProps): JSX.Element {
        const [revealData, setRevealData] = useState(false);
        const [isCorrect, setCorrect] = useState(false);
        const [questionNumber, setQuestionNumber] = useState(0);
        const currentKanji: number = kanjiData.id;

        useEffect(() => {
            if (isStartReview) {
                setQuestionNumber(index);
            } else {
                setQuestionNumber(index % DUMMY_CARD_NUMBER !== 0
                    ? (index - Math.floor(index/DUMMY_CARD_NUMBER))
                    : 0);
            }
        }, [isStartReview]);

        function handleClickAnswerQuestion(answer: string): number[] {
            setRevealData(true);

            if (kanjiData.correctAnswers.includes(answer)) {
                setCorrect(true);
                switch (kanjiData.questionType) {
                    case QUESTION_TYPE.meaning:
                        if (!meaningTestResults.includes(currentKanji)) {
                            setMeaningTestResults(meaningTestResults => {
                                meaningTestResults.push(currentKanji);
                                return meaningTestResults;
                            });
                        }
                        break;
                    case QUESTION_TYPE.kanji:
                        if (!kanjiTestResults.includes(currentKanji)) {
                            setKanjiTestResults(kanjiTestResults => {
                                kanjiTestResults.push(currentKanji);
                                return kanjiTestResults;
                            });
                        }
                        break;
                    case QUESTION_TYPE.onyomi:
                        if (!onyomiTestResults.includes(currentKanji)) {
                            setOnyomiTestResults(onyomiTestResults => {
                                onyomiTestResults.push(currentKanji);
                                return onyomiTestResults;
                            });
                        }
                        break;
                    case QUESTION_TYPE.kunyomi:
                        if (!kunyomiTestResults.includes(currentKanji)) {
                            setKunyomiTestResults(kunyomiTestResults => {
                                kunyomiTestResults.push(currentKanji);
                                return kunyomiTestResults;
                            });
                        }
                        break;
                }
            } else {
                setCorrect(false);
                switch (kanjiData.questionType) {
                    case QUESTION_TYPE.meaning:
                        if (meaningTestResults.includes(currentKanji)) {
                            const meaningIndex = meaningTestResults.indexOf(currentKanji);
                            setMeaningTestResults(meaningTestResults => {
                                meaningTestResults.splice(meaningIndex, 1);
                                return meaningTestResults;
                            });
                        }
                        break;
                    case QUESTION_TYPE.kanji:
                        if (kanjiTestResults.includes(currentKanji)) {
                            const kanjiIndex = kanjiTestResults.indexOf(currentKanji);
                            setKanjiTestResults(kanjiTestResults => {
                                kanjiTestResults.splice(kanjiIndex, 1);
                                return kanjiTestResults;
                            });
                        }
                        break;
                    case QUESTION_TYPE.onyomi:
                        if (onyomiTestResults.includes(currentKanji)) {
                            const onyomiIndex = onyomiTestResults.indexOf(currentKanji);
                            setOnyomiTestResults(onyomiTestResults => {
                                onyomiTestResults.splice(onyomiIndex, 1);
                                return onyomiTestResults;
                            });
                        }
                        break;
                    case QUESTION_TYPE.kunyomi:
                        if (kunyomiTestResults.includes(currentKanji)) {
                            const kunyomiIndex = kunyomiTestResults.indexOf(currentKanji);
                            setKunyomiTestResults(kunyomiTestResults => {
                                kunyomiTestResults.splice(kunyomiIndex, 1);
                                return kunyomiTestResults;
                            });
                        }
                        break;
                }
            }

            return [];
        }

        interface IAnswerChoiceProps {
            choice: string;
        }

        function KanjiAnswerChoice({ choice }: IAnswerChoiceProps): JSX.Element {
            return (
                <button className="flashcard-top-kanji-answer-choice-button" onClick={() => handleClickAnswerQuestion(choice)}>
                    {pseudoRadicals.kanji.includes(choice)
                        ? <img src={pseudoRadicals.images[pseudoRadicals.kanji.indexOf(choice)][darkMode ? 'gray' : 'black']} alt="kanji character"/>
                        : <>{choice}</>
                    }
                </button>
            );
        }

        function AnswerChoice({ choice }: IAnswerChoiceProps): JSX.Element {
            return (
                <button className="flashcard-top-answer-choice-button" onClick={() => handleClickAnswerQuestion(choice)}>
                    {choice.includes(' ') && choice.length > 12
                        ? <span className="two-word-answer-choice">{choice}</span>
                        : choice
                    }
                </button>
            );
        }

        function displayKanjiOrPseudoRadical(): ReactNode {
            const index = pseudoRadicals.meanings.indexOf(kanjiData.meaning);
            return kanjiData.type === 'Pseudoradical'
                ? <img className="flashcard-bottom-pseudoradical" src={pseudoRadicals.images[index][darkMode ? 'gray' : 'black']} alt="kanji character"/>
                : kanjiData.kanji;
        }

        function Question(): JSX.Element {
            switch (kanjiData.questionType) {
                case QUESTION_TYPE.kanji:
                    return (
                        <h2 className="question-kanji">
                            The kanji for <strong>{kanjiData.meaning}</strong> is?
                        </h2>
                    );

                case QUESTION_TYPE.meaning:
                    return (
                        <h2 className="question-text">
                            The meaning of <span className="flashcard-bottom-question">{displayKanjiOrPseudoRadical()}</span> is?
                        </h2>
                    );

                case QUESTION_TYPE.onyomi:
                    return (
                        <h2 className="question-text">
                            The onyomi reading of <span className="flashcard-bottom-question">{displayKanjiOrPseudoRadical()}</span> is?
                        </h2>
                    );

                case QUESTION_TYPE.kunyomi:
                    return (
                        <h2 className="question-text">
                            The kunyomi reading of <span className="flashcard-bottom-question">{displayKanjiOrPseudoRadical()}</span> is?
                        </h2>
                    );

                default:
                    return <h2 className="question-default">Woops. Something went wrong... err lolz</h2>;
            }
        }

        return (
            <>
                {/* TOP */}
                {revealData ?
                    <div className="flashcard-top study" key={`StudyTopFlashcard_${kanjiData.kanji}_${index}`}>
                        <div className="flashcard-top-container">
                            {/* ==== LEFT SIDE ==== */}
                            <div className="flashcard-top-left-container">
                                {/* KANJI */ }
                                <div className="flashcard-top-heading">
                                    <span className="dictionary-card-japanese heading">漢字</span>
                                    {kanjiData.id}
                                </div>
                                <div className="flashcard-top-kanji">
                                    {kanjiData.type === 'Pseudoradical' ?
                                        <img
                                            className="flashcard-top-pseudoradical"
                                            src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(kanjiData.meanings[0])].gray}
                                            alt="kanji character"
                                        /> :
                                        <span lang="ja">{kanjiData.kanji}</span>
                                    }
                                </div>

                                {/* STROKES */}
                                <div className="strokes">{`${kanjiData.strokes} Stroke${kanjiData.strokes === 1 ? '' : 's'}`}</div>

                                {/* MEANING */}
                                {kanjiData.meanings.map(meaning =>
                                <div
                                    key={meaning}
                                    className={meaning.length > DUMMY_CARD_NUMBER && !meaning.includes(' ') && !meaning.includes('-') ?
                                        'flashcard-top-meaning-long' :
                                        'flashcard-top-meaning-short'
                                    }
                                >
                                    {meaning}
                                </div>
                                )}
                            </div>

                            {/* ==== RIGHT SIDE ==== */}
                            <div className="flashcard-top-right-container">
                                {/*  TOP ROW: BUSHU | ONYOMI KATAKANA | KUNYOMI HIRAGANA  */}
                                <div className="flashcard-top-right-top-row">
                                    {/* BUSHU */}
                                    <div className="flashcard-top-radicals-column">
                                        <div className="flashcard-top-heading">
                                            <span className="dictionary-card-japanese">部首</span>
                                        </div>
                                        {kanjiData.bushu.map((bushu, index) => {
                                            if (pseudoRadicals.meanings.includes(kanjiData.radicals[index])) {
                                                return (
                                                    <img
                                                        className="flashcard-top-bushu-pseudoradical"
                                                        src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(kanjiData.radicals[index])].gray}
                                                        alt="kanji character"
                                                        key={`img_${bushu}_${index}_${kanjiData.meanings[0]}`}
                                                    />
                                                );
                                            } else {
                                                return <div key={`${bushu}_${index}_${kanjiData.meanings[0]}`}>{bushu}</div>;
                                            }
                                        })}
                                    </div>
        
                                    {/* ONYOMI KATAKANA */}
                                    <div className="flashcard-top-onyomi-column">
                                        <div className="flashcard-top-heading">
                                            <span className="dictionary-card-japanese">音読み</span>
                                        </div>
                                        {kanjiData.onyomi.map((onyomi, index) =>
                                            <div key={`${onyomi}_${index}_${kanjiData.meanings[0]}`}>{toKana(onyomi)}</div>
                                        )}
                                    </div>
        
                                    {/* KUNYOMI HIRAGANA */}
                                    <div className="flashcard-top-kunyomi-column">
                                        <div className="flashcard-top-heading">
                                            <span className="dictionary-card-japanese">訓読み</span>
                                        </div>
                                        {kanjiData.kunyomi.map((kunyomi, index) =>
                                            <div key={`${kunyomi}_${index}_${kanjiData.meanings[0]}`}>{toKana(kunyomi)}</div>
                                        )}
                                    </div>
                                </div>

                                {/* BOTTOM ROW: RADICALS | ONYOMI ROMAJI | KUNYOMI ROMAJI */}
                                <div className="flashcard-top-right-bottom-row">
                                    {/* RADICALS */}
                                    <div className="flashcard-top-radicals-column">
                                        <div className="flashcard-top-heading">Radicals</div>
                                        {kanjiData.radicals.map((radical, index) =>
                                            <div className="r-highlight" key={`${radical}_${index}_${kanjiData.meanings[0]}`}>{radical}</div>
                                        )}
                                    </div>
        
                                    {/* ONYOMI ROMAJI */}
                                    <div className="flashcard-top-onyomi-column">
                                        <div className="flashcard-top-heading">On</div>
                                        {kanjiData.onyomi.map((onyomi, index) =>
                                            <div className="o-highlight" key={`${onyomi}_${index}_${kanjiData.meanings[0]}`}>{onyomi}</div>
                                        )}
                                    </div>
        
                                    {/* KUNYOMI ROMAJI */}
                                    <div className="flashcard-top-kunyomi-column">
                                        <div className="flashcard-top-heading">Kun</div>
                                        {kanjiData.kunyomi.map((kunyomi, index) =>
                                            <div className="k-highlight" key={`${kunyomi}_${index}_${kanjiData.meanings[0]}`}>{kunyomi}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* MNEMONIC */}
                        <div className="flashcard-top-mnemonic">
                            {Mnemonic(kanjiData.mnemonic, isMeaningTest || isKanjiTest, isOnyomiTest || isKunyomiTest)}
                        </div>
                    </div> :

                    <div className="flashcard-top test">
                        {kanjiData.questionType === QUESTION_TYPE.kanji
                            ? // KANJI QUESTIONS
                                <div className="flashcard-top-kanji">
                                    <div className="flashcard-top-kanji-answer-choice-column">
                                        <KanjiAnswerChoice choice={kanjiData.answerChoices[0]}/>
                                        <KanjiAnswerChoice choice={kanjiData.answerChoices[1]}/>
                                        <KanjiAnswerChoice choice={kanjiData.answerChoices[2]}/>
                                    </div>

                                    <div className="flashcard-top-kanji-answer-choice-column">
                                        <KanjiAnswerChoice choice={kanjiData.answerChoices[3]}/>
                                        <KanjiAnswerChoice choice={kanjiData.answerChoices[4]}/>
                                        <KanjiAnswerChoice choice={kanjiData.answerChoices[5]}/>
                                    </div>
                                </div>
                            
                            : // MEANING, ONYOMI, KUNYOMI QUESTIONS
                                <div className="flashcard-top-answer-choice-column">
                                    {kanjiData.answerChoices.map((answerChoice, index) =>
                                        <AnswerChoice key={`${answerChoice}_${index}`} choice={answerChoice}/>
                                    )}
                                </div>
                        }
                    </div>
                }

                {/* BOTTOM */}
                <div className={`flashcard-bottom ${revealData ? 'flipped' : 'not-flipped'}`}>
                    <div className="flashcard-bottom-test">
                        {/* LEFT SIDE */}
                        <div>
                            <h6>Question {questionNumber}</h6>

                            {!revealData && <Question/>}

                            {revealData && isCorrect && <h2 className="answer">Correct!</h2>}
                            {revealData && !isCorrect && <h2 className="answer">Wrong!</h2>}

                            {revealData && <h2 className="answer">The answer is <strong>{kanjiData.correctAnswers[0] ? kanjiData.correctAnswers.join(' or ') : 'none'}</strong>.</h2>}
                        </div>

                        {/* RIGHT SIDE */}
                        {revealData && isCorrect && <MdCheck size="100px" className="check-icon"/>}
                        {revealData && !isCorrect && <MdClose size="100px" className="x-icon"/>}
                    </div>
                </div>
            </>
        );
    },

    TestReview: function({
        isStartReview,
        handleClickStartReview,
        isMeaningTest,
        isKanjiTest,
        isOnyomiTest,
        isKunyomiTest,
        kanjiTestResults,
        meaningTestResults,
        onyomiTestResults,
        kunyomiTestResults,
        totalKanjiQs,
        totalMeaningQs,
        totalOnyomiQs,
        totalKunyomiQs,
    }: ITestReviewProps): JSX.Element {
        const [hasMistakes, setMistakes] = useState(false);
        const meaningsWidth = meaningTestResults.length && meaningTestResults.length/totalMeaningQs * 100;
        const kanjiWidth = kanjiTestResults.length && kanjiTestResults.length/totalKanjiQs * 100;
        const onyomiWidth = onyomiTestResults.length && onyomiTestResults.length/totalOnyomiQs * 100;
        const kunyomiWidth = kunyomiTestResults.length && kunyomiTestResults.length/totalKunyomiQs * 100;

        useEffect(() => {
            if (!meaningTestResults.length || !kanjiTestResults.length || !onyomiTestResults.length || !kunyomiTestResults.length)  {
                setMistakes(true);
            }
            if (meaningTestResults.length && meaningTestResults.length/totalMeaningQs !== 1) {
                setMistakes(true);
            }
            if (kanjiTestResults.length && kanjiTestResults.length/totalKanjiQs !== 1) {
                setMistakes(true);
            }
            if (onyomiTestResults.length && onyomiTestResults.length/totalOnyomiQs !== 1) {
                setMistakes(true);
            }
            if (kunyomiTestResults.length && kunyomiTestResults.length/totalKunyomiQs !== 1) {
                setMistakes(true);
            }
        }, []);

        return (
            <>
                <div className="flashcard-top review">
                    <div className="flashcard-top-test-review">
                    <h6>Test Review</h6>

                    {isMeaningTest && !isStartReview &&
                        <div className="flashcard-top-test-container">
                            <div className="flashcard-top-test-bar"> {`${meaningTestResults.length} / ${totalMeaningQs} `} Meaning Correct</div>
                            <div className="flashcard-top-test-backdrop meaning" style={{ width: `${meaningsWidth}%` }}/>
                        </div>
                    }

                    {isKanjiTest && !isStartReview &&
                        <div className="flashcard-top-test-container">
                            <div className="flashcard-top-test-bar">{`${kanjiTestResults.length} / ${totalKanjiQs} `} Kanji Correct</div>
                            <div className="flashcard-top-test-backdrop radical" style={{ width: `${kanjiWidth}%` }}/>
                        </div>
                    }

                    {isOnyomiTest && !isStartReview &&
                        <div className="flashcard-top-test-container">
                            <div className="flashcard-top-test-bar">{`${onyomiTestResults.length} / ${totalOnyomiQs} `} Onyomi Correct</div>
                            <div className="flashcard-top-test-backdrop onyomi" style={{ width: `${onyomiWidth}%` }}/>
                        </div>
                    }

                    {isKunyomiTest && !isStartReview &&
                        <div className="flashcard-top-test-container">
                            <div className="flashcard-top-test-bar">{`${kunyomiTestResults.length} / ${totalKunyomiQs} `} Kunyomi Correct</div>
                            <div className="flashcard-top-test-backdrop kunyomi" style={{ width: `${kunyomiWidth}%` }}/>
                        </div>
                    }
                    </div>
                </div>
                <div className="flashcard-bottom test-review">
                    {(!isStartReview && hasMistakes) ?
                        <div className="flashcard-bottom-column">
                            <div className="flashcard-bottom-controls">
                            <button className="flashcard-bottom-button" type="button" onClick={handleClickStartReview}>
                                Start Review
                            </button>
                            </div>
                        </div> :
                        <h2> Great review! The best way to learn is by getting things wrong.</h2>
                    }
                </div>
            </>
        );
    },

    // FLASHCARDS
    InstructionsFlashcard: function({ handleClickStart }: IInstructionsFlashcardProps): JSX.Element {
        const [isInputOpen, setInputOpen] = useState(false);
        const [pastedText, setPastedText] = useState('');
        const [highestSeenKanjiId, setHighestSeenKanjiId] = useState(0);
        const [isValidUserData, setValidUserData] = useState(false);
    
        async function handleClickCopyUserData(): Promise<void> {
            const userDataJSON = localStorage.getItem('user-data');
            const flashcardRecordJSON = localStorage.getItem('user-flashcard-record');

            const userData = JSON.stringify({
                userData: userDataJSON,
                flashcardRecord: flashcardRecordJSON,
            });

            await navigator.clipboard.writeText(userData);
        }

        function handleClickSetUserData(): void {
            setInputOpen(true);
        }

        function handleClickSaveUserData(): void {
            const data = JSON.parse(pastedText) || {
                userData: '',
                flashcardRecord: '',
            };
            const { userData, flashcardRecord } = data;
            localStorage.setItem('user-data', userData);
            localStorage.setItem('user-flashcard-record', flashcardRecord);
            setPastedText('');
            setInputOpen(false);
            setValidUserData(false);
        }

        function handleChangeTextarea(event: ChangeEvent<HTMLTextAreaElement>): void {
           const { value } = event.target;
           setPastedText(value);
            
           if (value.includes('totalCardsSeen')) {
            setValidUserData(true);
           }
        }

        function handleChangeHighestSeenKanji(event: ChangeEvent<HTMLInputElement>): void {
            const { value } = event.target;
            setHighestSeenKanjiId(Number(value));
        }

        function handleClickHighestSeenKanji(): void {
            setHighestSeenKanji(highestSeenKanjiId);
        }

        return (
            <>
                <div className="flashcard-top instructions">
                    <div className="flashcard-top-details">
                        {isInputOpen ?
                            <>
                                <textarea
                                    className="user-data-textarea"
                                    rows={8}
                                    placeholder="Paste user data here"
                                    value={pastedText}
                                    onChange={handleChangeTextarea}
                                />
                            </> :
                            <>
                                <h6>Instructions</h6>
                                <p>Select the correct answer before the countdown finishes.</p>
                                <p>Score points by answering different types of questions. Earn bonus points for the seconds remaining on the countdown.</p>
                                <p>The flashcards will get harder as time goes on.</p>
                            </>
                        }

                        <div className="user-data-button-container">
                            {isInputOpen ?
                                <>
                                    <button
                                        className={`user-data-button ${isValidUserData ? 'enabled' : 'disabled'}`}
                                        onClick={() => handleClickSaveUserData()}
                                        disabled={!isValidUserData}
                                    >
                                        Save User Data
                                    </button>
                                    <button className="user-data-button" onClick={() => setInputOpen(false)}>
                                        Cancel
                                    </button>
                                    <button className="user-data-button" style={{ width: '100%' }} onClick={() => updateFlashcardRecordData()}>
                                        Update User Data
                                    </button>
                                    <input
                                        className="highest-seen-kanji-input"
                                        placeholder="enter a number"
                                        value={highestSeenKanjiId}
                                        onChange={handleChangeHighestSeenKanji}
                                    />
                                    <button className="user-data-button" style={{ fontSize: '12px' }} onClick={() => handleClickHighestSeenKanji()}>
                                        Set Highest Seen Kanji ID Number
                                    </button>
                                </> :
                                <>
                                    <button className="user-data-button" onClick={() => handleClickCopyUserData()}>
                                        Copy User Data
                                    </button>
                                    <button className="user-data-button" onClick={() => handleClickSetUserData()}>
                                        Set User Data
                                    </button>
                                </>
                            }
                        </div>

                        {!isInputOpen &&
                            <div className="points-explanations">
                                <div className="explanation">
                                    <span className="meanings">Meanings</span> &nbsp; 2 points
                                </div>
                                <div className="explanation">
                                    <span className="kanji">Kanji</span> &nbsp; 3 points
                                </div>
                                <div className="explanation">
                                    <span className="onyomi">Onyomi</span> &nbsp; 5 points
                                </div>
                                <div className="explanation">
                                    <span className="kunyomi">Kunyomi</span> &nbsp; 7 points
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className="flashcard-bottom flipped">
                    <div className="flashcard-bottom-instructions">
                        <div className="left">
                            <p style={{ height: 'auto' }}>Tap the arrow to begin.</p>
                            <div className="logo">
                                <div className="kanji-row">
                                    <div className="kanji">三</div>
                                    <div className="kanji">王</div>
                                </div>
                                <div className="kanji-row">
                                    <div className="kanji">玉</div>
                                    <div className="kanji">国</div>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <button className="next-card-circle" onClick={() => handleClickStart()}>
                                <MdArrowForward size="60px" className="next-icon"/>
                                <div className="next-card-text">Start</div>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    },

    NewFlashcard: function({
        index,
        flashcard,
        setFlashcardDeck,
        updateFlashcardRecord,
        numOflashcards,
        isCardFlipped,
        setCardFlipped,
        handleClickNextCard,
        darkMode,
    }: INewFlashcardProps): JSX.Element {
        const {
            totalCardsSeen,
            setTotalCardsSeen,
            totalScore,
            setTotalScore,
            setTotalScoreDigits,
        } = useContext(Context) as IContext;

        const { testKanjiData: kanjiData, flashcardHistory } = flashcard;

        const duration = 12;
        const redColor = '#C80000';
        const mutedRedColor = '#CA3232';
        const mediumBgColor = '#303030';
        const transparent = '#00000000';
        const timerColor = darkMode ? mutedRedColor : redColor;

        function updateUserProgress(): void {
            // updates flashcardHistory times seen and times correct
            const setBonus = Math.floor(kanjiData.id / 100) + 1;

            // SAVE USER PROGRESS IN APP CONTEXT
            setTotalCardsSeen(seen => seen + 1);
            setTotalScore(score => score + setBonus);
            const newTotalScore = totalScore + setBonus;
            const scoreDigits = digitBreakdown(newTotalScore);
            setTotalScoreDigits(scoreDigits);

            const updatedFlashcard = {
                ...flashcard,
                flashcardHistory: {
                    ...flashcardHistory,
                    timesSeen: flashcardHistory.timesSeen + 1,
                }
            };

            setFlashcardDeck(flashcardDeck => {
                const newFlashcardDeck = [...flashcardDeck];
                newFlashcardDeck[index] = updatedFlashcard;
                return newFlashcardDeck;
            });

            // SAVE USER PROGRESS IN DATABASE
            setUserData({
                totalCardsSeen: totalCardsSeen + 1,
                totalScore: newTotalScore,
                totalScoreDigits: scoreDigits,
            });

            updateFlashcardRecord(updatedFlashcard);
        }

        function handleClickFlipCard(e: MouseEvent<HTMLButtonElement>): void {
            e.preventDefault();
            setCardFlipped(!isCardFlipped);
            updateUserProgress();
        }

        function handleCompleteCircleTimer(totalElapsedTime: number): { shouldRepeat: boolean } {
            setCardFlipped(true);
            updateUserProgress();
            return { shouldRepeat: false };
        }

        return (
            <>
                {/* TOP */}
                {isCardFlipped ?
                    <div className="flashcard-top study" key={`StudyTopFlashcard_${kanjiData.kanji}_${index}`}>
                        <div className="flashcard-top-container">
                            <div className="flashcard-top-left-container">
                                {/* KANJI */ }
                                <div className="flashcard-top-heading flashcards">
                                    <span className="dictionary-card-japanese heading">漢字</span>
                                    {kanjiData.id}
                                </div>
                                <div className="flashcard-top-kanji">
                                    {kanjiData.type === 'Pseudoradical' ?
                                        <img className="flashcard-top-pseudoradical" src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(kanjiData.meanings[0])].white} alt="kanji character"/> :
                                        <span lang="ja">{kanjiData.kanji}</span>
                                    }
                                </div>

                                {/* STROKES */}
                                <div className="strokes">{`${kanjiData.strokes} Stroke${kanjiData.strokes === 1 ? '' : 's'}`}</div>

                                {/* MEANING */}
                                {kanjiData.meanings.map(meaning =>
                                    <div
                                        key={meaning}
                                        className={meaning.length > DUMMY_CARD_NUMBER && !meaning.includes(' ') && !meaning.includes('-')
                                            ? 'flashcard-top-meaning-long'
                                            : 'flashcard-top-meaning-short'
                                        }
                                    >
                                        {meaning}
                                    </div>
                                )}
                            </div>

                            <div className="flashcard-top-right-container">
                                {/*  TOP ROW: BUSHU | ONYOMI KATAKANA | KUNYOMI HIRAGANA  */}
                                <div className="flashcard-top-right-top-row">
                                    {/* BUSHU */}
                                    <div className="flashcard-top-radicals-column">
                                        <div className="flashcard-top-heading">
                                            <span className="dictionary-card-japanese">部首</span>
                                        </div>
                                        {kanjiData.bushu.map((bushu, index) => {
                                            if (pseudoRadicals.meanings.includes(kanjiData.radicals[index])) {
                                                return (
                                                    <img
                                                        className="flashcard-top-bushu-pseudoradical"
                                                        src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(kanjiData.radicals[index])][darkMode ? 'gray' : 'white']}
                                                        alt={kanjiData.radicals[index]}
                                                        key={`img_${bushu}_${index}_${kanjiData.meanings[0]}`}
                                                    />
                                                );
                                            } else {
                                                return <div key={`${bushu}_${index}_${kanjiData.meanings[0]}`}>{bushu}</div>;
                                            }
                                        })}
                                    </div>

                                    {/* ONYOMI KATAKANA */}
                                    <div className="flashcard-top-onyomi-column">
                                        <div className="flashcard-top-heading">
                                            <span className="dictionary-card-japanese">音読み</span>
                                        </div>
                                        {kanjiData.onyomi.map((onyomi, index) =>
                                            <div key={`${onyomi}_${index}_${kanjiData.meanings[0]}`}>{toKana(onyomi)}</div>
                                        )}
                                    </div>

                                    {/* KUNYOMI HIRAGANA */}
                                    <div className="flashcard-top-kunyomi-column">
                                        <div className="flashcard-top-heading">
                                            <span className="dictionary-card-japanese">訓読み</span>
                                        </div>
                                        {kanjiData.kunyomi.map((kunyomi, index) =>
                                            <div key={`${kunyomi}_${index}_${kanjiData.meanings[0]}`}>{toKana(kunyomi)}</div>
                                        )}
                                    </div>
                                </div>


                                {/* BOTTOM ROW: RADICALS | ONYOMI ROMAJI | KUNYOMI ROMAJI */}
                                <div className="flashcard-top-right-bottom-row">
                                    {/* RADICALS */}
                                    <div className="flashcard-top-radicals-column">
                                        <div className="flashcard-top-heading">Radicals</div>
                                        {kanjiData.radicals.map((radical, index) =>
                                            <div className="r-highlight" key={`${radical}_${index}_${kanjiData.meanings[0]}`}>{radical}</div>
                                        )}
                                    </div>

                                    {/* ONYOMI ROMAJI */}
                                    <div className="flashcard-top-onyomi-column">
                                        <div className="flashcard-top-heading">On</div>
                                        {kanjiData.onyomi.map((onyomi, index) =>
                                            <div className="o-highlight" key={`${onyomi}_${index}_${kanjiData.meanings[0]}`}>{onyomi}</div>
                                        )}
                                    </div>

                                    {/* KUNYOMI ROMAJI */}
                                    <div className="flashcard-top-kunyomi-column">
                                        <div className="flashcard-top-heading">Kun</div>
                                        {kanjiData.kunyomi.map((kunyomi, index) =>
                                            <div className="k-highlight" key={`${kunyomi}_${index}_${kanjiData.meanings[0]}`}>{kunyomi}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flashcard-top-mnemonic">
                            {Mnemonic(kanjiData.mnemonic, true, true)}
                        </div>
                    </div> :

                    <button className="flashcard-top-placeholder" onClick={handleClickFlipCard}>
                        <div className="flashcard-top-placeholder__kanji" style={{ cursor: 'pointer' }}>
                            {kanjiData.type === 'Pseudoradical' ?
                                // Pseudoradical
                                <img
                                    className="flashcard-top-placeholder__pseudoradical"
                                    src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(kanjiData.meanings[0])][darkMode ? 'gray' : 'black']}
                                    alt="kanji character"
                                /> :
                                kanjiData.kanji
                            }
                        </div>
                    </button>
                }

                <div className={`flashcard-bottom ${isCardFlipped ? 'flipped' : 'not-flipped'}`}>
                    <div className="flashcard-bottom-new">
                        <div className="left">
                            <h6>New Kanji</h6>

                            {!isCardFlipped &&
                                <div className="flashcard-bottom-item">
                                    <div className="flashcard-bottom-kanji">
                                        {kanjiData.type === 'Pseudoradical' ?
                                            // Pseudoradical
                                            <img
                                                className="flashcard-bottom-kanji-pseudoradical"
                                                src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(kanjiData.meanings[0])][darkMode ? 'gray' : 'black']}
                                                alt="kanji character"
                                            /> :

                                            // Regular kanji or radical
                                            <>{kanjiData.kanji}</>
                                        }
                                    </div>
                                    <div className="flashcard-kanji-backdrop"/>
                                </div>
                            }

                            {isCardFlipped && kanjiData.notes &&
                                <div className="flashcard-bottom-notes">{kanjiData.notes}</div>
                            }
                        </div>

                        <div className="right">
                            {!isCardFlipped ?
                                <CountdownCircleTimer
                                    isPlaying
                                    duration={duration}
                                    colors={timerColor}
                                    trailColor={darkMode ? mediumBgColor : transparent}
                                    size={100}
                                    strokeWidth={8}
                                    strokeLinecap="round"
                                    onComplete={handleCompleteCircleTimer}
                                >
                                    {({ remainingTime }) => (
                                        <button className="flip-new-card" type="button" onClick={handleClickFlipCard}>
                                            {remainingTime}
                                        </button>
                                    )}
                                </CountdownCircleTimer> :

                                <button className="next-card-circle" onClick={() => handleClickNextCard()}>
                                    <MdArrowForward size="60px" className="next-icon"/>
                                    {index < numOflashcards - 1 ?
                                        <div className="next-card-text">Next</div> :
                                        <>
                                            <div className="next-card-text">Start</div>
                                            <div className="next-card-text">Review</div>
                                        </>
                                    }
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    },

    QuizFlashcard: function({
        index,
        flashcard,
        lastCardIndex,
        setFlashcardDeck,
        updateFlashcardRecord,
        numOflashcards,
        isCardFlipped,
        setCardFlipped,
        goToNextCard,
        isCorrect,
        setCorrect,
        isChallengeRound,
        setChallengeStatus,
        challengeCorrectAnswers,
        setChallengeCorrectAnswers,
        kanjiTestResults,
        meaningTestResults,
        onyomiTestResults,
        kunyomiTestResults,
        setKanjiTestResults,
        setMeaningTestResults,
        setOnyomiTestResults,
        setKunyomiTestResults,
        handleClickNextCard,
        darkMode
    }: IQuizFlashcardProps): JSX.Element {
        const [questionNumber, setQuestionNumber] = useState(0);
        const [timeBonus, setTimeBonus] = useState(0);
        const [answer, setAnswer] = useState('');
        const [hasInput, setHasInput] = useState(false);
        const [isAlmostCorrect, setIsAlmostCorrect] = useState(false);
        const [isPreviousAnswer, setPreviousAnswer] = useState(false);
        const [selectedBushu, setSelectedBushu] = useState<string[]>([]);

        // const answerInputRef = useRef<HTMLInputElement | null>(null);
        const { currentUser } = useAuth();

        useEffect(() => {
            setQuestionNumber(index % DUMMY_CARD_NUMBER !== 0
                ? (index - Math.floor(index/DUMMY_CARD_NUMBER) + 1)
                : 1);
        }, []);

        let initializeSelectedRadicals: boolean[] = [];
        for (let i = 0; i < NUM_RADICAL_CHOICES; i++) {
            initializeSelectedRadicals.push(false);
        }
        const [selectedRadicals, setSelectedRadicals] = useState(initializeSelectedRadicals);

        const {
            // OVERALL FLASHCARD RECORD
            totalCardsSeen,
            setTotalCardsSeen,
            totalCardsAnswered,
            setTotalCardsAnswered,
            totalCardsCorrect,
            setTotalCardsCorrect,
            totalScore,
            setTotalScore,
            setTotalScoreDigits,

             // CARDS SEEN
            meaningCardsSeen,
            setMeaningCardsSeen,
            kanjiCardsSeen,
            setKanjiCardsSeen,
            onyomiCardsSeen,
            setOnyomiCardsSeen,
            kunyomiCardsSeen,
            setKunyomiCardsSeen,

            // CARDS CORRECT
            meaningCardsCorrect,
            setMeaningCardsCorrect,
            kanjiCardsCorrect,
            setKanjiCardsCorrect,
            onyomiCardsCorrect,
            setOnyomiCardsCorrect,
            kunyomiCardsCorrect,
            setKunyomiCardsCorrect,

            // SCORE TRACKER
            meaningScore,
            setMeaningScore,
            kanjiScore,
            setKanjiScore,
            onyomiScore,
            setOnyomiScore,
            kunyomiScore,
            setKunyomiScore,
        } = useContext(Context) as IContext;

        const { testKanjiData, flashcardHistory } = flashcard;

        const currentKanji: number = testKanjiData.id;
        const duration = 12;
        const redColor = '#C80000';
        const mutedRedColor = '#CA3232';
        const mediumBgColor = '#303030';
        const transparent = '#00000000';
        const timerColor = darkMode ? mutedRedColor : redColor;

        useEffect(() => {
            // if (answerInputRef?.current) {
            //     answerInputRef?.current!.focus();
            // }

            const { testKanjiData } = flashcard;
            const { questionType } = testKanjiData;

            switch (questionType) {
                case QUESTION_TYPE.meaning:
                    if (questionType !== challengeCorrectAnswers.questionType) {
                        const processedAnswers = testKanjiData.meanings.map(m => removePunctuation(m));
                        const answers = testKanjiData.meanings.length ? processedAnswers : [NO_READING];
                        setChallengeCorrectAnswers({
                            questionType,
                            answers,
                        });
                    }
                    break;
                case QUESTION_TYPE.radicals:
                    if (questionType !== challengeCorrectAnswers.questionType) {
                        setChallengeCorrectAnswers({
                            questionType,
                            answers: testKanjiData.bushu,
                        });
                    }
                    break;
                case QUESTION_TYPE.onyomi:
                    if (questionType !== challengeCorrectAnswers.questionType) {
                        const processedAnswers = testKanjiData.onyomi.map(o => removePunctuation(o));
                        const answers = testKanjiData.onyomi.length ? processedAnswers : [NO_READING];
                        setChallengeCorrectAnswers({
                            questionType,
                            answers,
                        });
                    }
                    break;
                case QUESTION_TYPE.kunyomi:
                    if (questionType !== challengeCorrectAnswers.questionType) {
                        const processedAnswers = testKanjiData.kunyomi.map(k => removePunctuation(k));
                        const answers = testKanjiData.kunyomi.length ? processedAnswers : [NO_READING];
                        setChallengeCorrectAnswers({
                            questionType,
                            answers,
                        });
                    }
                    break;
            }
        }, []);

        useEffect(() => {
            if (answer.trim()) {
                setHasInput(true);
            } else {
                setHasInput(false);
            }
        }, [answer]);

        function processCorrectChoice(
            answerPoints: number,
            // SEEN
            typeCardsSeenProp: 'meaningCardsSeen' | 'kanjiCardsSeen' | 'onyomiCardsSeen' | 'kunyomiCardsSeen',
            typeCardsSeen: number,
            setTypeCardsSeen: Dispatch<SetStateAction<number>>,
            // CORRECT
            typeCardsCorrectProp: 'meaningCardsCorrect' | 'kanjiCardsCorrect' | 'onyomiCardsCorrect' | 'kunyomiCardsCorrect',
            typeCardsCorrect: number,
            setTypeCardsCorrect: Dispatch<SetStateAction<number>>,
            // SCORE
            typeScoreProp: 'meaningScore' | 'kanjiScore' | 'onyomiScore' | 'kunyomiScore',
            typeScore: number,
            setTypeScore: Dispatch<SetStateAction<number>>,
            // TEST RESULTS
            testResults: number[],
            setTestResults: Dispatch<SetStateAction<number[]>>,
        ): void {
            const { timesCorrect } = flashcardHistory;

            if (timesCorrect >= EASY_THRESHOLD) {
                answerPoints *= 2;
            }

            // FOR APP CONTEXT
            const correctAnswerTypeScore = answerPoints + timeBonus;
            setTotalCardsSeen(seen => seen + 1);
            setTotalCardsAnswered(answered => answered + 1);
            setTotalCardsCorrect(correct => correct + 1);
            setTotalScore(score => score + correctAnswerTypeScore);

            setTypeCardsSeen(seen => seen + 1);
            setTypeCardsCorrect(correct => correct + 1);
            setTypeScore(score => score + answerPoints);

            const updatedFlashcard = {
                ...flashcard,
                flashcardHistory: {
                    ...flashcardHistory,
                    timesSeen: flashcardHistory.timesSeen + 1,
                    timesCorrect: flashcardHistory.timesCorrect + 1,
                    score: flashcardHistory.score + answerPoints,
                }
            };

            if (!isChallengeRound) {
                setFlashcardDeck(flashcardDeck => {
                    const newFlashcardDeck = [...flashcardDeck];
                    newFlashcardDeck[index] = updatedFlashcard;
                    return newFlashcardDeck;
                });
            }

            const newTotalScore = totalScore + correctAnswerTypeScore;
            const scoreDigits = digitBreakdown(newTotalScore);
            setTotalScoreDigits(scoreDigits);

            // SAVE USER PROGRESS IN DATABASE
            setUserData({
                totalCardsSeen: totalCardsSeen + 1,
                totalCardsAnswered: totalCardsAnswered + 1,
                totalCardsCorrect: totalCardsCorrect + 1,
                totalScore: totalScore + correctAnswerTypeScore,
                [typeCardsSeenProp]: typeCardsSeen + 1,
                [typeCardsCorrectProp]: typeCardsCorrect + 1,
                [typeScoreProp]: typeScore + answerPoints,
                totalScoreDigits: scoreDigits,
                currentUser,
            });

            // ALERTS
            const scoreAlerts: IScoreAlert[] = [
                {
                    text: `+${answerPoints} ${testKanjiData.questionType.toUpperCase()} POINTS!`,
                    type: testKanjiData.questionType,
                },
                {
                    text: `+${timeBonus} BONUS POINTS!`,
                    type: 'bonus',
                },
            ];

            updateFlashcardRecord(updatedFlashcard, true, answerPoints, scoreAlerts);

            const newTestResults = [...testResults, currentKanji];
            setTestResults(newTestResults);
        }

        function processIncorrectChoice(
            answerPoints: number,
            // SEEN
            typeCardsSeenProp: 'meaningCardsSeen' | 'kanjiCardsSeen' | 'onyomiCardsSeen' | 'kunyomiCardsSeen',
            typeCardsSeen: number,
            setTypeCardsSeen: Dispatch<SetStateAction<number>>,
            // SCORE
            typeScoreProp: 'meaningScore' | 'kanjiScore' | 'onyomiScore' | 'kunyomiScore',
            typeScore: number,
            setTypeScore: Dispatch<SetStateAction<number>>,
        ): void {
            // SAVE USER PROGRESS IN APP CONTEXT
            const newTotalScore = totalScore - answerPoints;
            const scoreDigits = digitBreakdown(newTotalScore);
            setTotalCardsSeen(seen => seen + 1);
            setTotalCardsAnswered(answered => answered + 1);
            setTotalScore(score => score - answerPoints);
            setTotalScoreDigits(scoreDigits);

            setTypeCardsSeen(seen => seen + 1);
            setTypeScore(score => score - answerPoints);

            const updatedFlashcard = {
                ...flashcard,
                flashcardHistory: {
                    ...flashcardHistory,
                    timesSeen: flashcardHistory.timesSeen + 1,
                    score: flashcardHistory.score + answerPoints,
                }
            };

            if (!isChallengeRound) {
                setFlashcardDeck(flashcardDeck => {
                    const newFlashcardDeck = [...flashcardDeck];
                    newFlashcardDeck[index] = updatedFlashcard;
                    return newFlashcardDeck;
                });
            }

            // SAVE USER PROGRESS IN DATABASE
            setUserData({
                totalCardsSeen: totalCardsSeen + 1,
                totalCardsAnswered: totalCardsAnswered + 1,
                totalScore: totalScore - answerPoints,
                [typeCardsSeenProp]: typeCardsSeen + 1,
                [typeScoreProp]: typeScore - answerPoints,
                totalScoreDigits: scoreDigits,
            });

            // ALERTS
            const scoreAlerts: IScoreAlert[] = [{
                text: `-${answerPoints} ${testKanjiData.questionType.toUpperCase()} POINTS!`,
                type: 'incorrect',
            }];

            updateFlashcardRecord(updatedFlashcard, false, answerPoints, scoreAlerts);
        }

        function proceedWithChallenge(): void {
            goToNextCard();

            if (index === lastCardIndex) {
                setChallengeStatus(true);
            }
        }

        function handleAnswerChoice(isCorrect: boolean): void {
            const meaningPoints = 2;
            const kanjiPoints = 3;
            const onyomiPoints = 5;
            const kunyomiPoints = 7;

            if (isCorrect) {
                setCorrect(true);

                switch (testKanjiData.questionType) {
                    case QUESTION_TYPE.meaning:
                        processCorrectChoice(
                            meaningPoints,
                            'meaningCardsSeen',
                            meaningCardsSeen,
                            setMeaningCardsSeen,
                            'meaningCardsCorrect',
                            meaningCardsCorrect,
                            setMeaningCardsCorrect,
                            'meaningScore',
                            meaningScore,
                            setMeaningScore,
                            meaningTestResults,
                            setMeaningTestResults,
                        );
                        break;
                    case QUESTION_TYPE.kanji:
                    case QUESTION_TYPE.radicals:
                        processCorrectChoice(
                            kanjiPoints,
                            'kanjiCardsSeen',
                            kanjiCardsSeen,
                            setKanjiCardsSeen,
                            'kanjiCardsCorrect',
                            kanjiCardsCorrect,
                            setKanjiCardsCorrect,
                            'kanjiScore',
                            kanjiScore,
                            setKanjiScore,
                            kanjiTestResults,
                            setKanjiTestResults,
                        );
                        break;
                    case QUESTION_TYPE.onyomi:
                        processCorrectChoice(
                            onyomiPoints,
                            'onyomiCardsSeen',
                            onyomiCardsSeen,
                            setOnyomiCardsSeen,
                            'onyomiCardsCorrect',
                            onyomiCardsCorrect,
                            setOnyomiCardsCorrect,
                            'onyomiScore',
                            onyomiScore,
                            setOnyomiScore,
                            onyomiTestResults,
                            setOnyomiTestResults,
                        );
                        break;
                    case QUESTION_TYPE.kunyomi:
                        processCorrectChoice(
                            kunyomiPoints,
                            'kunyomiCardsSeen',
                            kunyomiCardsSeen,
                            setKunyomiCardsSeen,
                            'kunyomiCardsCorrect',
                            kunyomiCardsCorrect,
                            setKunyomiCardsCorrect,
                            'kunyomiScore',
                            kunyomiScore,
                            setKunyomiScore,
                            kunyomiTestResults,
                            setKunyomiTestResults,
                        );
                        break;
                }
            } else {
                setCorrect(false);

                switch (testKanjiData.questionType) {
                    case QUESTION_TYPE.meaning:
                        processIncorrectChoice(
                            meaningPoints,
                            'meaningCardsSeen',
                            meaningCardsSeen,
                            setMeaningCardsSeen,
                            'meaningScore',
                            meaningScore,
                            setMeaningScore,
                        );
                        break;
                    case QUESTION_TYPE.kanji:
                    case QUESTION_TYPE.radicals:
                        processIncorrectChoice(
                            kanjiPoints,
                            'kanjiCardsSeen',
                            kanjiCardsSeen,
                            setKanjiCardsSeen,
                            'kanjiScore',
                            kanjiScore,
                            setKanjiScore,
                        );
                        break;
                    case QUESTION_TYPE.onyomi:
                        processIncorrectChoice(
                            onyomiPoints,
                            'onyomiCardsSeen',
                            onyomiCardsSeen,
                            setOnyomiCardsSeen,
                            'onyomiScore',
                            onyomiScore,
                            setOnyomiScore,
                        );
                        break;
                    case QUESTION_TYPE.kunyomi:
                        processIncorrectChoice(
                            kunyomiPoints,
                            'kunyomiCardsSeen',
                            kunyomiCardsSeen,
                            setKunyomiCardsSeen,
                            'kunyomiScore',
                            kunyomiScore,
                            setKunyomiScore,
                        );
                        break;
                }
            }
        }

        function removePunctuation(str: string): string {
            // will not process '-none-' answer
            if (str === NO_READING) {
                return NO_READING;
            }

            const chars = str.trim().toLowerCase().split('');
            const processedChars: string[] = [];
            const punctuation = ['.', ',', '-', ';'];

            chars.forEach(char => {
                if (!punctuation.includes(char)) {
                    processedChars.push(char);
                }
            });

            return processedChars.join('');
        }

        function scrubChallengeCorrectAnswers(processedAnswer: string): void {
            const { testKanjiData } = flashcard;
            const { questionType } = testKanjiData;
            let processedAnswers: string[] =[];
            let answers: string[] = [];

            switch (questionType) {
                case QUESTION_TYPE.meaning:
                    processedAnswers = testKanjiData.meanings.map(m => removePunctuation(m)).filter(a => a !== processedAnswer);
                    answers = testKanjiData.meanings.length ? processedAnswers : [NO_READING];
                    setChallengeCorrectAnswers({
                        questionType,
                        answers,
                    });
                    break;
                case QUESTION_TYPE.onyomi:
                    processedAnswers = testKanjiData.onyomi.map(o => removePunctuation(o)).filter(a => a !== processedAnswer);
                    answers = testKanjiData.onyomi.length ? processedAnswers : [NO_READING];
                    setChallengeCorrectAnswers({
                        questionType,
                        answers,
                    });
                    break;
                case QUESTION_TYPE.kunyomi:
                    processedAnswers = testKanjiData.kunyomi.map(k => removePunctuation(k)).filter(a => a !== processedAnswer);
                    answers = testKanjiData.kunyomi.length ? processedAnswers : [NO_READING];
                    setChallengeCorrectAnswers({
                        questionType,
                        answers,
                    });
                    break;
            }
        }

        function handleClickAnswerQuestion(choice: string): void {
            const isCorrect = testKanjiData.correctAnswers.includes(choice);
            handleAnswerChoice(isCorrect);

            if (isChallengeRound) {
                if (isCorrect) {
                    proceedWithChallenge();
                } else {
                    setChallengeStatus(false);
                }
            } else {
                setCardFlipped(true);
            }
        }

        function handleClickAnswerButton(e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLInputElement>): void {
            e.preventDefault();
            const processedAnswer = removePunctuation(answer) || NO_READING;
            const processedCorrectAnswers = testKanjiData.correctAnswers.map(correctAnswer => removePunctuation(correctAnswer));

            if (isChallengeRound) {
                if (challengeCorrectAnswers.answers.includes(processedAnswer)) {
                    /*  A user can enter any correct asnwer for the question type during a challenge round.
                        As correct answers are entered, they get rmoved form the answer pile
                        ex: ONYOMI: ['SOKU', 'ZOKU']
                        A user can enter SOKU or ZOKU in any order unlike during normal flashcard rounds.
                    */
                    setCorrect(true);
                    setPreviousAnswer(false);
                    handleAnswerChoice(true);
                    scrubChallengeCorrectAnswers(processedAnswer);
                    proceedWithChallenge();
                } else if (processedCorrectAnswers.includes(processedAnswer)) {
                    setPreviousAnswer(true);
                } else {
                    setCorrect(false);
                    setPreviousAnswer(false);
                    handleAnswerChoice(false);
                    setChallengeStatus(false);
                }
            } else {
                if (processedAnswer === removePunctuation(flashcardHistory.value)) {
                    setCorrect(true);
                    setIsAlmostCorrect(false);
                    handleAnswerChoice(true);
                    setCardFlipped(true);
                } else if (processedCorrectAnswers.includes(processedAnswer)) {
                    setIsAlmostCorrect(true);
                } else {
                    setCorrect(false);
                    setIsAlmostCorrect(false);
                    handleAnswerChoice(false);
                    setCardFlipped(true);
                }
            }
        }

        function handleKeypress(e: KeyboardEvent<HTMLInputElement>): void {
            //it triggers by pressing the enter key
            if (e.key === 'Enter') {
                handleClickAnswerButton(e);
            }
        }

        interface IAnswerChoiceProps {
            choice: string;
        }

        function KanjiAnswerChoice({ choice }: IAnswerChoiceProps): JSX.Element {
            return (
                <button className="flashcard-top-kanji-answer-choice-button" onClick={() => handleClickAnswerQuestion(choice)}>
                    {pseudoRadicals.kanji.includes(choice)
                        ? <img src={pseudoRadicals.images[pseudoRadicals.kanji.indexOf(choice)][darkMode ? 'gray' : 'black']} alt="kanji character"/>
                        : <>{choice}</>
                    }
                </button>
            );
        }

        function AnswerChoice({ choice }: IAnswerChoiceProps): JSX.Element {
            return (
                <button className="flashcard-top-answer-choice-button" onClick={() => handleClickAnswerQuestion(choice)}>
                    {choice.includes(' ') && choice.length > 12
                        ? <span className="two-word-answer-choice">{choice}</span>
                        : choice
                    }
                </button>
            );
        }

        function displayKanjiOrPseudoRadical(): ReactNode {
            const index = pseudoRadicals.meanings.indexOf(testKanjiData.meaning);
            return testKanjiData.type === 'Pseudoradical'
                ? <img className="flashcard-bottom-pseudoradical" src={pseudoRadicals.images[index][darkMode ? 'gray' : 'black']} alt="kanji character"/>
                : testKanjiData.kanji;
        }

        function handleClickRadicalChoice(bushu: string, index: number): void {
            setSelectedBushu(selectedBushu => {
                let newSelectedBushu: string[] = [];

                if (selectedBushu.includes(bushu)) {
                    // remove bushu from list
                    const idx = selectedBushu.findIndex(b => b === bushu);
                    newSelectedBushu = [...selectedBushu.slice(0, idx), ...selectedBushu.slice(idx + 1)];
                } else {
                    // add bush to list
                    newSelectedBushu = [...selectedBushu, bushu];
                }

                return newSelectedBushu;
            });

            setSelectedRadicals(selectedRadicals => {
                const newSelectedRadicals = [...selectedRadicals];
                newSelectedRadicals[index] = !selectedRadicals[index];
                return newSelectedRadicals;
            });
        }

        function handleClickSubmitRadicals(): void {
            let hasAllBushu = true;

            for (let bushu of selectedBushu) {
                hasAllBushu = testKanjiData.correctAnswers.includes(bushu);

                if (!hasAllBushu) {
                    break;
                }
            }

            setCorrect(hasAllBushu);
            handleAnswerChoice(hasAllBushu);

            // IF challenge round go to next card only if correct. Otherwise jump to failure screen
            // ELSE normal flshcard mode: flip card over
            if (isChallengeRound) {
                if (hasAllBushu) {
                    proceedWithChallenge();
                } else {
                    setChallengeStatus(false);
                }
            } else {
                setCardFlipped(true);
            }
        }

        function handleCompleteCircleTimer(totalElapsedTime: number): { shouldRepeat: boolean } {
            if (isChallengeRound) {
                setChallengeStatus(false);
            } else {
                setCardFlipped(true);
                setCorrect(false);
            }
            return { shouldRepeat: false };
        }

        function Question(): JSX.Element {
            switch (testKanjiData.questionType) {
                case QUESTION_TYPE.meaning:
                    return (
                        <h2 className="question-text">
                            The meaning of <span className="flashcard-bottom-question">{displayKanjiOrPseudoRadical()}</span> is?
                        </h2>
                    );

                case QUESTION_TYPE.kanji:
                    return (
                        <h2 className="question-kanji">
                            The kanji for <strong>{testKanjiData.meaning}</strong> is?
                        </h2>
                    );

                case QUESTION_TYPE.radicals:
                    return (
                        <h2 className="question-kanji">
                            Select the radicals that make up <strong>{testKanjiData.meaning}</strong>.
                        </h2>
                    );

                case QUESTION_TYPE.onyomi:
                    return (
                        <h2 className="question-text">
                            The onyomi reading of <span className="flashcard-bottom-question">{displayKanjiOrPseudoRadical()}</span> is?
                        </h2>
                    );

                case QUESTION_TYPE.kunyomi:
                    return (
                        <h2 className="question-text">
                            The kunyomi reading of <span className="flashcard-bottom-question">{displayKanjiOrPseudoRadical()}</span> is?
                        </h2>
                    );

                default:
                    return <h2 className="question-default">Woops. Something went wrong... err lolz</h2>;
            }
        }

        return (
            <>
                {/* TOP */}
                {isCardFlipped ?
                    <div className="flashcard-top study" key={`StudyTopFlashcard_${testKanjiData.kanji}_${index}`}>
                        <div className="flashcard-top-container">
                            {/* ==== LEFT SIDE ==== */}
                            <div className="flashcard-top-left-container">
                                {/* KANJI */ }
                                <div className="flashcard-top-heading flashcards">
                                    <span className="dictionary-card-japanese heading">漢字</span>
                                    {testKanjiData.id}
                                </div>
                                <div className="flashcard-top-kanji">
                                    {testKanjiData.type === 'Pseudoradical' ?
                                        <img
                                            className="flashcard-top-pseudoradical"
                                            src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(testKanjiData.meanings[0])].gray}
                                            alt="kanji character"
                                        /> :
                                        <span lang="ja">{testKanjiData.kanji}</span>
                                    }
                                </div>

                                {/* STROKES */}
                                <div className="strokes">{`${testKanjiData.strokes} Stroke${testKanjiData.strokes === 1 ? '' : 's'}`}</div>

                                {/* MEANING */}
                                {testKanjiData.meanings.map(meaning =>
                                <div key={meaning} className={meaning.length > DUMMY_CARD_NUMBER && !meaning.includes(' ') && !meaning.includes('-') ? 'flashcard-top-meaning-long' : 'flashcard-top-meaning-short'}>
                                    {meaning}
                                </div>
                                )}
                            </div>

                            {/* ==== RIGHT SIDE ==== */}
                            <div className="flashcard-top-right-container">
                                {/*  TOP ROW: BUSHU | ONYOMI KATAKANA | KUNYOMI HIRAGANA  */}
                                <div className="flashcard-top-right-top-row">
                                    {/* BUSHU */}
                                    <div className="flashcard-top-radicals-column">
                                        <div className="flashcard-top-heading">
                                            <span className="dictionary-card-japanese">部首</span>
                                        </div>
                                        {testKanjiData.bushu.map((bushu, index) => {
                                            if (pseudoRadicals.meanings.includes(testKanjiData.radicals[index])) {
                                                return (
                                                    <img
                                                        className="flashcard-top-bushu-pseudoradical"
                                                        src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(testKanjiData.radicals[index])].gray}
                                                        alt="kanji character"
                                                        key={`img_${bushu}_${index}_${testKanjiData.meanings[0]}`}
                                                    />
                                                );
                                            } else {
                                                return <div key={`${bushu}_${index}_${testKanjiData.meanings[0]}`}>{bushu}</div>;
                                            }
                                        })}
                                    </div>
        
                                    {/* ONYOMI KATAKANA */}
                                    <div className="flashcard-top-onyomi-column">
                                        <div className="flashcard-top-heading">
                                            <span className="dictionary-card-japanese">音読み</span>
                                        </div>
                                        {testKanjiData.onyomi.map((onyomi, index) =>
                                            <div key={`${onyomi}_${index}_${testKanjiData.meanings[0]}`}>{toKana(onyomi)}</div>
                                        )}
                                    </div>
        
                                    {/* KUNYOMI HIRAGANA */}
                                    <div className="flashcard-top-kunyomi-column">
                                        <div className="flashcard-top-heading">
                                            <span className="dictionary-card-japanese">訓読み</span>
                                        </div>
                                        {testKanjiData.kunyomi.map((kunyomi, index) =>
                                            <div key={`${kunyomi}_${index}_${testKanjiData.meanings[0]}`}>{toKana(kunyomi)}</div>
                                        )}
                                    </div>
                                </div>

                                {/* BOTTOM ROW: RADICALS | ONYOMI ROMAJI | KUNYOMI ROMAJI */}
                                <div className="flashcard-top-right-bottom-row">
                                    {/* RADICALS */}
                                    <div className="flashcard-top-radicals-column">
                                        <div className="flashcard-top-heading">Radicals</div>
                                        {testKanjiData.radicals.map((radical, index) =>
                                            <div className="r-highlight" key={`${radical}_${index}_${testKanjiData.meanings[0]}`}>{radical}</div>
                                        )}
                                    </div>
        
                                    {/* ONYOMI ROMAJI */}
                                    <div className="flashcard-top-onyomi-column">
                                        <div className="flashcard-top-heading">On</div>
                                        {testKanjiData.onyomi.map((onyomi, index) =>
                                            <div className="o-highlight" key={`${onyomi}_${index}_${testKanjiData.meanings[0]}`}>{onyomi}</div>
                                        )}
                                    </div>
        
                                    {/* KUNYOMI ROMAJI */}
                                    <div className="flashcard-top-kunyomi-column">
                                        <div className="flashcard-top-heading">Kun</div>
                                        {testKanjiData.kunyomi.map((kunyomi, index) =>
                                            <div className="k-highlight" key={`${kunyomi}_${index}_${testKanjiData.meanings[0]}`}>{kunyomi}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* MNEMONIC */}
                        <div className="flashcard-top-mnemonic">
                            {Mnemonic(
                                testKanjiData.mnemonic,
                                testKanjiData.cardType === FLASHCARD_TYPE.quizMeaning || testKanjiData.cardType === FLASHCARD_TYPE.quizKanji,
                                testKanjiData.cardType === FLASHCARD_TYPE.quizOnyomi || testKanjiData.cardType === FLASHCARD_TYPE.quizKunyomi
                            )}
                        </div>
                    </div> :

                    <div className="flashcard-top test">
                        {(testKanjiData.questionType === QUESTION_TYPE.kanji || testKanjiData.questionType === QUESTION_TYPE.radicals)
                            ? // KANJI AND RADICALS QUESTIONS ONLY
                                <div className="flashcard-top-kanji">
                                    {testKanjiData.questionType === QUESTION_TYPE.kanji ?
                                        <>
                                            <div className="flashcard-top-kanji-answer-choice-column">
                                                <KanjiAnswerChoice choice={testKanjiData.answerChoices[0]}/>
                                                <KanjiAnswerChoice choice={testKanjiData.answerChoices[1]}/>
                                                <KanjiAnswerChoice choice={testKanjiData.answerChoices[2]}/>
                                            </div>

                                            <div className="flashcard-top-kanji-answer-choice-column">
                                                <KanjiAnswerChoice choice={testKanjiData.answerChoices[3]}/>
                                                <KanjiAnswerChoice choice={testKanjiData.answerChoices[4]}/>
                                                <KanjiAnswerChoice choice={testKanjiData.answerChoices[5]}/>
                                            </div>
                                        </>:

                                        <div className="radical-choice-container">
                                            {flashcard.testKanjiData.answerChoices.map((bushu, index) => (
                                                <button
                                                    key={`${bushu}_${index}`}
                                                    className={`radical-choice ${selectedRadicals[index] ? 'selected' : 'deselected'}`}
                                                    onClick={() => handleClickRadicalChoice(bushu, index)}
                                                >
                                                    {bushu}
                                                </button>
                                            ))}
                                            <button className="radical-choice submit" onClick={() => handleClickSubmitRadicals()}>
                                                Submit Answer
                                            </button>
                                        </div>
                                    }
                                </div>
                            
                            : // MEANING, ONYOMI, KUNYOMI QUESTIONS
                                <div className="flashcard-top-answer-choice-column">
                                    {(flashcardHistory.timesCorrect >= EASY_THRESHOLD || isChallengeRound) ?
                                        <div className="flashcard-top-answer-input">
                                            <div className="input-area">
                                                <form>
                                                    <input
                                                        className={hasInput ? 'enabled-input' : 'disabled-input'}
                                                        type="text"
                                                        placeholder="Type answer here"
                                                        value={answer}
                                                        onChange={e => setAnswer(e.target.value)}
                                                        onKeyDown={handleKeypress}
                                                        // ref={answerInputRef}
                                                    />
                                                    <button
                                                        className={hasInput ? 'enabled-button' : 'disabled-button'}
                                                        onClick={handleClickAnswerButton}
                                                        type="submit"
                                                        disabled={!hasInput}
                                                    >
                                                        Enter
                                                    </button>
                                                </form>
                                            </div>
                                            {(flashcardHistory.questionType === QUESTION_TYPE.onyomi || flashcardHistory.questionType === QUESTION_TYPE.kunyomi) &&
                                                <div className="input-area">
                                                    <button className="no-reading" onClick={handleClickAnswerButton}>
                                                        Has No Reading
                                                    </button>
                                                </div>
                                            }
                                            <div className="input-prompt">
                                                What is {flashcardHistory.questionType === QUESTION_TYPE.onyomi ? 'an' : 'a'} <strong>{flashcardHistory.questionType}</strong> of this kanji? 
                                                You'll get a double point bonus if you get it right! Capitalization and punctuation do not matter.
                                            </div>
                                        </div> :

                                        <>
                                            {testKanjiData.answerChoices.map((answerChoice, index) =>
                                                <AnswerChoice key={`${answerChoice}_${index}`} choice={answerChoice}/>
                                            )}
                                        </>
                                    }
                                </div>
                        }
                    </div>
                }

                {/* BOTTOM */}
                <div className={`flashcard-bottom ${isCardFlipped ? 'flipped' : 'not-flipped'}`}>
                    <div className="flashcard-bottom-quiz">
                        <div className="left">
                            <h6>Question {questionNumber}</h6>

                            {!isCardFlipped && !isAlmostCorrect && <Question/>}

                            {!isCardFlipped && isAlmostCorrect && <h2>{`Correct but what is another ${flashcardHistory.questionType} that begins with ${flashcardHistory.value.slice(0, 1)}?`}</h2>}

                            {!isCardFlipped && isPreviousAnswer && <h2>{`You already entered that answer. Try again`}</h2>}

                            {isCardFlipped && isCorrect && <h2 className="answer">Correct!</h2>}

                            {isCardFlipped && !isCorrect && <h2 className="answer">Wrong!</h2>}

                            {isCardFlipped && (testKanjiData.questionType === QUESTION_TYPE.radicals ?
                                <h2 className="answer">The answers are {testKanjiData.correctAnswers[0] ? testKanjiData.correctAnswers.join(' and ') : 'none'}.</h2> :
                                <h2 className="answer"> {flashcardHistory.value === NO_READING ? `${testKanjiData.kanji} has no ${testKanjiData.questionType} readings.` : `The answer is ${flashcardHistory.value}.`}</h2>
                            )}
                        </div>

                        <div className="right">
                            {!isCardFlipped ?
                                <CountdownCircleTimer
                                    isPlaying={true}
                                    duration={duration}
                                    colors={timerColor}
                                    trailColor={darkMode ? mediumBgColor : transparent}
                                    size={100}
                                    strokeWidth={8}
                                    strokeLinecap="round"
                                    onComplete={handleCompleteCircleTimer}
                                >
                                    {({ remainingTime }) => {
                                        setTimeBonus(remainingTime);
                                        return remainingTime;
                                    }}
                                </CountdownCircleTimer> :

                                <button className="next-card-circle" onClick={() => handleClickNextCard()}>
                                    {isCorrect ?
                                        <MdCheck size="60px" className="check-icon"/> :
                                        <MdClose size="60px" className="x-icon"/>
                                    }
                                    {index < numOflashcards - 1 && !isChallengeRound && <div className="next-card-text">Next</div>}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    },

    ReviewFlashcard: function({
        flashcardDeck,
        kanjiTestResults,
        meaningTestResults,
        onyomiTestResults,
        kunyomiTestResults,
        flashcardRecord,
    }: IReviewFlashcardProps): JSX.Element {
        const meaningCards = flashcardDeck.filter(({ flashcardHistory }) => flashcardHistory.questionType === QUESTION_TYPE.meaning).length;
        const kanjiCards = flashcardDeck.filter(({ flashcardHistory }) => flashcardHistory.questionType === QUESTION_TYPE.kanji).length;
        const onyomiCards = flashcardDeck.filter(({ flashcardHistory }) => flashcardHistory.questionType === QUESTION_TYPE.onyomi).length;
        const kunyomiCards = flashcardDeck.filter(({ flashcardHistory }) => flashcardHistory.questionType === QUESTION_TYPE.kunyomi).length;

        const meaningBarWidth = (meaningTestResults.length / meaningCards) * 100;
        const kanjiBarWidth = (kanjiTestResults.length / kanjiCards) * 100;
        const onyomiBarWidth = (onyomiTestResults.length / onyomiCards) * 100;
        const kunyomiBarWidth = (kunyomiTestResults.length / kunyomiCards) * 100;

        const challengedKanji: string[] = [];
        flashcardRecord.challengedCards.forEach(card => {
            if (!challengedKanji.includes(card.UID)) {
                challengedKanji.push(card.UID);
            }
        });

        return (
            <>
                <div className="flashcard-top review">
                    <div className="flashcard-top-test-review">
                    <h6>Test Review</h6>

                    {challengedKanji.length > 0 && <p>{`You have sucessfully challenged ${challengedKanji.length} kanji!`}</p>}

                    {meaningCards > 0 &&
                        <div className="flashcard-top-test-container">
                            <div className="flashcard-top-test-bar"> {`${meaningTestResults.length} / ${meaningCards} `} Meaning Cards Correct</div>
                            <div className="flashcard-top-test-backdrop meaning" style={{ width: `${meaningBarWidth}%` }}/>
                        </div>
                    }

                    {kanjiCards > 0 &&
                        <div className="flashcard-top-test-container">
                            <div className="flashcard-top-test-bar">{`${kanjiTestResults.length} / ${kanjiCards} `} Kanji & Radical Cards Correct</div>
                            <div className="flashcard-top-test-backdrop radical" style={{ width: `${kanjiBarWidth}%` }}/>
                        </div>
                    }

                    {onyomiCards > 0 &&
                        <div className="flashcard-top-test-container">
                            <div className="flashcard-top-test-bar">{`${onyomiTestResults.length} / ${onyomiCards} `} Onyomi Cards Correct</div>
                            <div className="flashcard-top-test-backdrop onyomi" style={{ width: `${onyomiBarWidth}%` }}/>
                        </div>
                    }

                    {kunyomiCards > 0 &&
                        <div className="flashcard-top-test-container">
                            <div className="flashcard-top-test-bar">{`${kunyomiTestResults.length} / ${kunyomiCards} `} Kunyomi Cards Correct</div>
                            <div className="flashcard-top-test-backdrop kunyomi" style={{ width: `${kunyomiBarWidth}%` }}/>
                        </div>
                    }
                    </div>
                </div>
                <div className="flashcard-bottom flipped">
                    <div className="flashcard-bottom-instructions">
                        <div className="left">
                            <p style={{ height: 'auto' }}>Great! Review twice a day.</p>
                            <div className="logo">
                                <div className="kanji-row">
                                    <div className="kanji">三</div>
                                    <div className="kanji">王</div>
                                </div>
                                <div className="kanji-row">
                                    <div className="kanji">玉</div>
                                    <div className="kanji">国</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    },

    FinishedFlashcard: function(): JSX.Element {
        return (
            <>
                <div className="flashcard-top instructions">
                    <div className="flashcard-top-details">
                        <h6>Congratulations</h6>
                        <p>You have gone through the entire flashcardset of 2500 kanjis!</p>
                        <p>You should continue your studies by reading as much real Japanese material as possible.</p>
                        <p>Thank you for coming on this journey and please tell all your friends abotu Kanji Remastered..</p>
                    </div>
                </div>

                <div className="flashcard-bottom flipped">
                    <div className="flashcard-bottom-instructions">
                        <div className="left">
                            <p style={{ height: 'auto' }}>You are a Kanji Master.</p>
                            <div className="logo">
                                <div className="kanji-row">
                                    <div className="kanji">三</div>
                                    <div className="kanji">王</div>
                                </div>
                                <div className="kanji-row">
                                    <div className="kanji">玉</div>
                                    <div className="kanji">国</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    },

    ChallengeSucess: function({ handleClickContinue }: IChallengeSucessProps): JSX.Element {
        const iconSize = '150px';

        return (
            <>
                <div className="flashcard-top instructions">
                    <div className="flashcard-top-details">
                        <h6>Challenge Passed!</h6>
                        <div className="japanese-text">合格!</div>
                        <p>You've succesfully challenged this kanji. It will no longer appear in the flashcard deck.</p>

                        <div className="flashcard-top-error-icon-container">
                            <SuccessIcon size={iconSize} className="base-icon"/>
                        </div>
                    </div>
                </div>

                <div className="flashcard-bottom flipped">
                    <div className="flashcard-bottom-instructions">
                        <div className="left">
                            <p style={{ height: 'auto' }}>Tap the arrow to continue.</p>
                            <div className="logo">
                                <div className="kanji-row">
                                    <div className="kanji">三</div>
                                    <div className="kanji">王</div>
                                </div>
                                <div className="kanji-row">
                                    <div className="kanji">玉</div>
                                    <div className="kanji">国</div>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <button className="next-card-circle" onClick={() => handleClickContinue(true)}>
                                <MdArrowForward size="60px" className="next-icon"/>
                                <div className="next-card-text">Go!</div>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    },

    ChallengeFailure: function({ handleClickContinue }: IChallengeFailureProps): JSX.Element {
        const iconSize = '150px';

        return (
            <>
                <div className="flashcard-top instructions">
                    <div className="flashcard-top-details">
                        <h6>Challenge Failed!</h6>
                        <div className="japanese-text">落第!</div>
                        <p>You did not succesfully challenged this kanji. Flashcards featuring this kanji will continue to appear in the flashcard deck.</p>

                        <div className="flashcard-top-error-icon-container">
                            <FailureIcon size={iconSize} className="base-icon"/>
                        </div>
                    </div>
                </div>

                <div className="flashcard-bottom flipped">
                    <div className="flashcard-bottom-instructions">
                        <div className="left">
                            <p style={{ height: 'auto' }}>Tap the arrow to continue.</p>
                            <div className="logo">
                                <div className="kanji-row">
                                    <div className="kanji">三</div>
                                    <div className="kanji">王</div>
                                </div>
                                <div className="kanji-row">
                                    <div className="kanji">玉</div>
                                    <div className="kanji">国</div>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <button className="next-card-circle" onClick={() => handleClickContinue(false)}>
                                <MdArrowForward size="60px" className="next-icon"/>
                                <div className="next-card-text">Go!</div>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    },

    // ADVERTISING FLASHCARDS
    Advertising: function ({ handleClickNextCard }: IAdvertisingCardProps): JSX.Element {
        return (
            <>
                <div className="flashcard-top-placeholder">
                    <div className="flashcard-top-details">
                        <h6>DONATE</h6>

                        <div className="flashcard-top-instruction">
                            Please support Kanji Remastered with a donation to keep this site growing. Even a little is greatly appreciated.
                        </div>

                        <div className="flashcard-top-instruction">
                            Kanji Remastered is an independently managed web project.
                        </div>

                        <br/>
                        <a href="https://www.patreon.com/kanjiremastered" rel="noopener noreferrer" target="_blank">
                            <SiPatreon size="35px" className="flashcard-top-donate-icon patreon"/>
                            <h5>Support this on Patreon!</h5>
                        </a>
                        <br/>
                        <br/>
                        <a href="https://www.buymeacoffee.com/kanjiremastered" rel="noopener noreferrer" target="_blank">
                            <SiBuymeacoffee size="35px" className="flashcard-top-donate-icon"/>
                            <h5>Buy me a coffee!</h5>
                        </a>
                    </div>
                </div>
                <div className="flashcard-bottom advertising">
                    <div className="flashcard-bottom-controls-left">
                        <p>Tap the arrow to continue.</p>
                    </div>
                    <div className="flashcard-bottom-controls-right">
                        <button className="next-card" onClick={() => handleClickNextCard()} type="button">
                            <MdKeyboardArrowRight size="50px" className="controls-arrow"/>
                        </button>
                    </div>
                </div>
            </>
        );
    },

    Donate: function (): JSX.Element {
        return (
            <>
                <div className="flashcard-top-placeholder">
                    <div className="flashcard-top-details">
                        <h6>DONATE</h6>

                        <div className="flashcard-top-instruction">
                            Please support Kanji Remastered with a donation to keep this site growing. Even a little is greatly appreciated.
                        </div>

                        <div className="flashcard-top-instruction">
                            Kanji Remastered is an independently managed web project.
                        </div>

                        <br/>
                        <a href="https://www.patreon.com/kanjiremastered" rel="noopener noreferrer" target="_blank">
                            <SiPatreon size="35px" className="flashcard-top-donate-icon patreon"/>
                            <h5>Support this on Patreon!</h5>
                        </a>
                        <br/>
                        <br/>
                        <a href="https://www.buymeacoffee.com/kanjiremastered" rel="noopener noreferrer" target="_blank">
                            <SiBuymeacoffee size="35px" className="flashcard-top-donate-icon"/>
                            <h5>Buy me a coffee!</h5>
                        </a>
                    </div>
                </div>
                <div className="flashcard-bottom donate">
                    <div className="flashcard-bottom-donate-column-flank">
                        <MdKeyboardArrowLeft size="50px" className="donate-arrow"/>
                    </div>
                    <div className="flashcard-bottom-donate-column-middle">
                        <p>Swipe card or hit arrows to continue.</p>
                    </div>
                    <div className="flashcard-bottom-donate-column-flank">
                        <MdKeyboardArrowRight size="50px" className="donate-arrow"/>
                    </div>
                </div>
            </>
        );
    },

    Error: function(): JSX.Element {
        const iconSize = '50px';

        return (
            <>
                <div className="flashcard-top instructions">
                    <div className="flashcard-top-details">
                        <h6>OOPS!</h6>
                        <div className="japanese-text">サーバー エラー</div>
                        <p>We can't seem to find what you're looking for.</p>
                        <div className="japanese-text">ファイルまたはディレクトリが見つかりません。</div>
                        <div className="flashcard-top-error-icon-container">
                            <MdBuild size={iconSize} className="base-icon" style={{ marginTop: '15px' }}/>
                            <MdNotInterested size={iconSize} className="base-icon"/>
                        </div>
                        <div className="flashcard-top-error-icon-container">
                            <MdSentimentVeryDissatisfied size={iconSize} className="base-icon"/>
                            <MdWarning size={iconSize} className="base-icon"/>
                        </div>
                    </div>
                </div>

                <div className="flashcard-bottom flipped">
                    <div className="flashcard-bottom-instructions">
                        <div className="left">
                            <p style={{ height: 'auto' }}>Refresh the page to restart.</p>
                            <div className="logo">
                                <div className="kanji-row">
                                    <div className="kanji">三</div>
                                    <div className="kanji">王</div>
                                </div>
                                <div className="kanji-row">
                                    <div className="kanji">玉</div>
                                    <div className="kanji">国</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    },
};

export default Cards;