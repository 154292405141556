import React, { MouseEvent } from 'react';
import 'core-js';
import pseudoRadicals from 'helpers/pseudoRadicals';
import toKana from 'helpers/romajiToKana';
import { ISearchKanjiData, ISearchResultsProps } from 'interfaces/interfaces';
import { NO_DATA } from 'constants/constants';

export default function SearchResults({
    isResultsGrid,
    handleClickResults,
    searchData,
    darkMode,
}: ISearchResultsProps): JSX.Element {
    /* DESCRIPTION
        Main search feature from search bar
        Returns search data from getSearchData(search term: string)
        The results are displayed either as a:
            <List /> component
            or
            <Grid /> component
        The search data results are clickable and display a dictionary card
    */

    // Sorts by depth of kanji derivation to display results in order
    const sortedData = [...searchData];
    sortedData.sort();

    const numericHeadings: number[] = [];
    const resultsWithDepthLevels: string[] = [];
    for (let result of sortedData) {
        if (typeof result.heading === 'number') {
            numericHeadings.push(result.heading);
        } else {
            resultsWithDepthLevels.push(result.heading);
        }
    }

    // Pushes non-numeric headings...
    let arrHeadings: any[] = []; // will have mixed data types
    sortedData.forEach(result => {
        // console.log(result.heading);
        if (typeof result.heading !== 'number') {
            arrHeadings.push(result.heading);
        }
    });

    // ...then pushes 0, 1, 2, 3,..., maxDepth
    const maxDepth = Math.max.apply(Math, numericHeadings);
    if (maxDepth !== -Infinity && !Number.isNaN(maxDepth)) {
        for (let i = 0; i <= Math.max(1, maxDepth); i++) {
            arrHeadings.push(i);
        }
    }

    // Removes duplicate non-numeric headings from search
    arrHeadings = [...new Set(arrHeadings)];

    function ResultsHeading({ heading }: { heading: string | number }): JSX.Element {
        const styleHeading = { fontWeight: 500 };
        const headingMessage = {
            noMatch: (
                <div className="tips-search">
                    <h3>Sorry. No data matches your search term. ^_^</h3>
                    <br/>
                    <h5>ごめん。検索キーワードに一致する情報は見つからなかった。</h5>
                </div>
            ),

            exactMatch: <h3 style={styleHeading}>Matches {heading} of</h3>,

            topKanji: <h3 style={styleHeading}>Kanji</h3>,

            orderDerivatives: <h3 style={styleHeading}>Order {heading} Derivatives</h3>,
        }

        if (heading === NO_DATA) {
            // >>> IMPORTANT <<< //
            // Handles case if no data returned
            return headingMessage.noMatch;
        } else if (typeof heading !== 'number') {
            return headingMessage.exactMatch;
        } else if (typeof heading === 'number' && heading === 0) {
            return headingMessage.topKanji;
        } else if (typeof heading === 'number') {
            return headingMessage.orderDerivatives;
        } else {
            return headingMessage.noMatch;
        }
    };

    interface ISearchDataProps {
        heading: string | number;
        handleClickResults(e: MouseEvent<HTMLButtonElement>, id: number, kanji: string): void;
        searchData: ISearchKanjiData[];
        darkMode: boolean;
    }

    const searchDataProps = { handleClickResults, searchData, darkMode };

    function Grid({ searchData, handleClickResults, heading, darkMode }: ISearchDataProps): JSX.Element {
        return (
            <div className="search-data-grid">
                {searchData.filter(kanji => heading === kanji.heading && heading !== NO_DATA).map(result => {
                    const {
                        id,
                        kanji,
                        onyomi,
                        kunyomi,
                    } = result;
                    const meaning = result.meanings[0];

                    return (
                        <div key={`Grid_${id}_${kanji}`} className="search-data-box-container">
                            <button
                                className="search-data-box"
                                type="button"
                                onClick={e => handleClickResults(e, id, kanji)}
                            >
                                {pseudoRadicals.meanings.includes(meaning)
                                    ? <img src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(meaning)][darkMode ? 'gray' : 'black']} alt="kanji character"/>
                                    : <p>{kanji}</p>
                                }
                                {meaning.length > 10 && !meaning.includes(' ') && !meaning.includes('-')
                                    ? <h1>{meaning}</h1> // long meaning
                                    : <h2>{meaning}</h2> // short meaning
                                }
                            </button>

                            <div className="search-data-box-details">
                                {onyomi && onyomi.map((o, index) => <h4 key={`${o}_${index}`}>{toKana(o)}</h4>)}

                                {kunyomi && kunyomi.map((k, index) => <h4 key={`${k}_${index}`}>{toKana(k)}</h4>)}

                                {!onyomi.length && !kunyomi.length && <h2>radical</h2>}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    function List({ searchData, handleClickResults, heading }: ISearchDataProps): JSX.Element {
        return (
            <div>
                {searchData.filter(result => result.heading === heading && result.heading !== NO_DATA).map(result => { 
                    const {
                        id,
                        kanji,
                        meanings,
                    } = result;

                    // if (depthLevel === heading && heading !== NO_DATA) {
                    return (
                        <button 
                            className="search-data-list-button"
                            type="button"
                            onClick={e => handleClickResults(e, id, kanji)}
                            key={`List_${id}_${kanji}`}
                        >
                            <div className="search-data-line">
                                {pseudoRadicals.meanings.includes(meanings[0])
                                    ? <img src={pseudoRadicals.images[pseudoRadicals.meanings.indexOf(meanings[0])].black} alt="kanji character"/>
                                    : <>{kanji}</>
                                }

                                {meanings.map(meaning => <h2 key={meaning}>{meaning}</h2>)}
                            </div>
                        </button>
                    );
                    // }
                })}
            </div>
        );
    }

    // search results grouped by heading
    return (
        <>
            {arrHeadings.map((heading, index) => isResultsGrid ?
                <div className="search-data-grid-container" key={`${heading}_${index}`}>
                    {/*
                        If no kanji in search results, this removes unnecessary heading.
                        Example: Search for a number like 50. This will prevent from 'Level 0 Derivatives from showing'.
                    */}
                    {searchDataProps.searchData.filter(kanji => kanji.heading === heading).length > 0 &&
                        <ResultsHeading heading={heading}/>
                    }
                    <Grid heading={heading} {...searchDataProps}/>
                </div> :

                <div className="search-data-list-container" key={`${heading}_${index}`}>
                    <ResultsHeading heading={heading}/>
                    <List  heading={heading} {...searchDataProps}/>
                </div>
            )}
        </>
    );
}