import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from 'context/authContext';
import { doSignOut } from 'firebaseConfig/authentication';
import {
    MdAccountBox,
    MdExitToApp,
    MdHome,
    MdSearch,
    MdLibraryBooks,
    MdInfo,
    MdAttachMoney,
    MdMail,
    MdGavel,
    MdLightMode,
    MdDarkMode
} from 'react-icons/md';
import { FaBrain } from 'react-icons/fa';
import { FaBookOpen } from 'react-icons/fa6';
import { IThemedComponentProps } from 'interfaces/interfaces';

interface IHamburgerMenuContainerStyles {
    display: string;
    visibility: any;
    zIndex: string;
    position: any;
    top: string;
    left: string;
    width: string;
    height: string;
    backgroundImage: string;
    textAlign: any;
    alignItems: string;
    justifyContent: string;
    overflow: string;
    transition: string;
}

interface IHamburgerProps extends IThemedComponentProps {
    toggleTheme(): void;
}

export default function Hamburger({ darkMode, toggleTheme }: IHamburgerProps): JSX.Element {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        if (isChecked) {
            disableScroll();
        } else {
            enableScroll();
        }
    }, [isChecked]);

    const [menuVisibility, setMenuVisibility] = useState('hidden');
    const [menuWidth, setMenuWidth] = useState('0');

    const { userLoggedIn } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = (): void => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        if (windowWidth > 950) {
            enableScroll();
            setIsChecked(false);
            setMenuVisibility('hidden');
            setMenuWidth('0');
        }
    }, [windowWidth]);

    const iconStyles = { margin: '0 16px' , color: darkMode ? '#CA3232' : '#C80000' };
    const lightBackground: string = 'linear-gradient(to right, var(--whiteColor), var(--whiteColor) 200px, var(--blackColor) 200px, var(--blackColor) 202px, rgba(255,255,255,.7) 202px, rgba(255,255,255,.7)';
    const darkBackground: string = 'linear-gradient(to right, var(--darkBgColor), var(--darkBgColor) 200px, var(--blackColor) 200px, var(--blackColor) 202px, rgba(0,0,0,.7) 202px, rgba(0,0,0,.7)';
   
    const hamburgerMenuContainerStyles: IHamburgerMenuContainerStyles = {
        display: 'flex',
        visibility: menuVisibility,
        zIndex: '15',
        position: 'fixed',
        top: '50px',
        left: '0',
        width: menuWidth,
        height: '100%',
        backgroundImage: darkMode ? darkBackground : lightBackground,
        textAlign: 'right',
        alignItems: 'right',
        justifyContent: 'left',
        overflow: 'hidden',
        transition: '0.5s ease',
    };

    function toggleMenu(): void {
        setIsChecked(!isChecked);

        if (!isChecked) {
            setMenuVisibility('visible');
            setMenuWidth('100%');
        } else {
            setMenuVisibility('hidden');
            setMenuWidth('0');
        }
    }

    function handleSignIn(): void {
        toggleMenu();

        if (!userLoggedIn) {
            navigate('/auth/signin');
        }
    }

    function handleSignOut(): void {
        toggleMenu();

        if (userLoggedIn) {
            doSignOut();
        }
    }

    function disableScroll(): void {
        // Get the current page scroll position
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    
        // if any scroll is attempted, set this to the previous value
        window.onscroll = function(): void {
            window.scrollTo(scrollLeft, scrollTop);
        };
    }

    function enableScroll(): void {
        window.onscroll = Function.prototype();
    }

    return (
        <div className='hamburger-wrap'>
            <div className="navbar-theme-toggle">
                <form>
                    <label htmlFor="lightDark" className="toggle-theme-switch">
                        <input
                            type="checkbox"
                            id="lightDark"
                            checked={darkMode}
                            onChange={() => toggleTheme()}
                        />
                        <div className="toggle-theme-slider">
                            <MdLightMode size="22px" style={{ margin: '0', padding: '0', color: "#BBB" }}/>
                            <MdDarkMode size="22px" style={{ margin: '0', padding: '0', color: "#050520" }}/>
                        </div>
                    </label>
                </form>
            </div>

            {/* HAMBURGER TOGGLER */}
            <input
                className="hamburger-toggler"
                type="checkbox"
                checked={isChecked} 
                onChange={() => toggleMenu()}
            />
            <div className="hamburger"><div/></div>

            {/* SIDENAV MENU */}
            <div style={hamburgerMenuContainerStyles}>
                <div className="hamburger-menu-bar">
                    <ul>
                        {/* SIGN IN/OUT */}
                        {userLoggedIn ?
                            <li className="hamburger-menu-item">
                                <button
                                    className="hamburger-button"
                                    onClick={() => handleSignOut()}
                                >
                                    <MdExitToApp size="20px" style={iconStyles}/>
                                    Sign Out
                                </button>
                            </li> :

                            <li className="hamburger-menu-item">
                                <button
                                    className="hamburger-button"
                                    onClick={() => handleSignIn()}
                                >
                                    <MdAccountBox size="20px" style={iconStyles}/>
                                    Sign In
                                </button>
                            </li>
                        }

                        <li><h1 className="hamburger-heading">LEARN</h1></li>
                        <li className="hamburger-menu-item">
                            <Link className="hamburger-link" to="/" onClick={() => toggleMenu()}>
                                <MdHome size="20px" style={iconStyles}/>
                                Home
                            </Link>
                        </li>
                        <li className="hamburger-menu-item">
                            <Link className="hamburger-link" to="/language/flashcards" onClick={() => toggleMenu()}>
                                <MdLibraryBooks size="20px" style={iconStyles}/>
                                Flashcards
                            </Link>
                        </li>
                        <li className="hamburger-menu-item">
                            <Link className="hamburger-link" to="/language/selfstudy" onClick={() => toggleMenu()}>
                                <FaBookOpen size="20px" style={iconStyles}/>
                                Self Study
                            </Link>
                        </li>
                        <li className="hamburger-menu-item">
                            <Link className="hamburger-link" to="/language/kanjisearch" onClick={() => toggleMenu()}>
                                <MdSearch size="20px" style={iconStyles}/>
                                Kanji Search
                            </Link>
                        </li>
                        <li className="hamburger-menu-item">
                            <Link className="hamburger-link" to="/language/theory" onClick={() => toggleMenu()}>
                                <FaBrain size="20px" style={iconStyles}/>
                                Theory
                            </Link>
                        </li>

                        <li><h1 className="hamburger-heading">SITE INFO</h1></li>
                        <li className="hamburger-menu-item">
                            <Link className="hamburger-link" to="/siteinfo/about" onClick={() => toggleMenu()}>
                                <MdInfo size="20px" style={iconStyles}/>
                                About
                        </Link>
                        </li>
                        <li className="hamburger-menu-item">
                            <Link className="hamburger-link" to="/siteinfo/contact" onClick={() => toggleMenu()}>
                                <MdMail size="20px" style={iconStyles}/>
                                Contact
                            </Link>
                        </li>
                        <li className="hamburger-menu-item">
                            <Link className="hamburger-link" to="/siteinfo/donate" onClick={() => toggleMenu()}>
                                <MdAttachMoney size="20px" style={iconStyles}/>
                                Donate
                            </Link>
                        </li>
                        <li className="hamburger-menu-item">
                            <Link className="hamburger-link" to="/siteinfo/legal" onClick={() => toggleMenu()}>
                                <MdGavel size="20px" style={iconStyles}/>
                                Legal
                            </Link>
                        </li>
                    </ul>

                    <div className="hamburger-background-image"/>
                </div>

                {/* SIDENAV CLOSE */}
                <div className="hamburger-sidenav-close" onClick={() => toggleMenu()}/>
            </div>
        </div>
    );
}