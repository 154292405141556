import React, { useContext, useEffect} from 'react';
import { MdMail } from 'react-icons/md';
import { Context } from 'context/context';
import ContactForm from 'components/ContactForm';
import { IThemedComponentProps, IContext } from 'interfaces/interfaces';

export default function Contact({ darkMode }: IThemedComponentProps): JSX.Element {
    const { setFlashcardsPage } = useContext(Context) as IContext;

    useEffect(() => {
        setFlashcardsPage(false);
    }, []);

    return (
        <div className="page-container">
            <div className="page-content">
                <h6>CONTACT</h6>
                <MdMail size="100px" className="siteinfo-red-icon"/>
                <div className="page-contact-paragraph">
                    <div className="page-responsive-content">
                        <p>Have something you would like to let us know about? Perhaps a question or suggestion. Reporting a bug or typo? Please send us a message.</p>
                        <br/>
                        <p>Email:</p>
                        <a href="mailto: contact@kanjiremastered.com">contact@kanjiremastered.com</a>
                    </div>
                    <ContactForm darkMode={darkMode}/>
                </div>
            </div>
        </div>
    );
};