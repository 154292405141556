import React from 'react';
import { MdClose } from 'react-icons/md';
import {IoIosSearch } from 'react-icons/io';
import { RECENT_ITEM } from 'constants/constants';
import { IRecentSearchesProps } from 'interfaces/interfaces';

export default function RecentSearches({
    handleClickRecentSearches,
    handleClickRecentItem,
    darkMode,
}: IRecentSearchesProps): JSX.Element {
    const recentItems = Object.entries(sessionStorage)
        .filter(entry => entry[0].includes(RECENT_ITEM))
        .sort((a,b) => {
            const numIdx = RECENT_ITEM.length + 1;
            const A = parseInt(a[0].slice(numIdx));
            const B = parseInt(b[0].slice(numIdx));
            return B - A;
        }) // sorts by RECENT_ITEM_INDEX (ex: RECENT_ITEM_1, RECENT_ITEM_2)
        .map(x => x[1]); // return only the search term

    return (
        <div className="recent-container">
            <div className="recent-topline">
                <IoIosSearch size="20px" color={darkMode ? 'var(--mediumGrayColor)' : 'var(--blueColor)'} title="clickable list of recent searches"/>
                <h1>Recent Searches</h1>
                <button className="search-options-button" onClick={handleClickRecentSearches}>
                    <MdClose
                        size="20px"
                        style={{ color: darkMode ? 'var(--mediumGrayColor)' : 'var(--blueColor)', margin: '-1.5px 3px 0 0' }}
                        title="close recent searches"
                    />
                    <h1>Close</h1>
                </button>
            </div>

            {recentItems.slice(0, 20).map((searchItem, index) => (
                <button className="recent-item-button" key={`${searchItem}_${index}`} onClick={e => handleClickRecentItem(e, searchItem)}>
                    {`<${index + 1}> ${searchItem}`}
                </button>
            ))}
        </div>
  );
}