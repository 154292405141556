import React, { useState, useEffect, useRef, ChangeEvent, FormEvent, MouseEvent, ReactNode, useContext } from 'react';
// import { withRouter } from 'react-router-dom';
import { Context } from 'context/context';
import 'core-js';
import _ from 'lodash';
import {
    IoIosSearch,
    IoIosList,
    IoMdGrid,
    IoIosTimer,
    IoIosRefresh,
} from 'react-icons/io';
import { MdClose, MdExpandLess, MdExpandMore } from 'react-icons/md';
import searchServices from 'middleware/searchServices';
import strokesAndRadicals from 'helpers/strokesAndRadicals';
import { RECENT_ITEM } from 'constants/constants';
import {
    IRadicalTabProps,
    ISearchKanjiData,
    IThemedComponentProps,
    IKanjiData,
    Radical,
    IDictionaryCardProps,
    ISuggestionsProps,
    ISearchResultsProps,
    IContext,
} from 'interfaces/interfaces';
import {
    Advertising,
    Cards,
    ErrorBoundary,
    RadicalTab,
    RecentSearches,
    SearchResults,
    Suggestions,
    Tips,
} from 'components';

interface IKanjiSearchProps extends IThemedComponentProps {}
interface ISelectedRadicals {
    [key: string]: Radical,
}

export default function KanjiSearch({ darkMode }: IKanjiSearchProps): JSX.Element {
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [isResultsGrid, setIsResultsGrid] = useState(true);
    const [isRadicalsChart, setRadicalChart] = useState(false);
    const [selectedRadicals, setSelectedRadicals] = useState<ISelectedRadicals>({});
    const [isChart, setChart] = useState(true);
    const [isRadicalData, setIsRadicalData] = useState(false);
    const [isRecentSearches, setRecentSearches] = useState(false);
    const [isDataDisplayed, setDataDisplayed] = useState(false);
    const [searchData, setSearchData] = useState<ISearchKanjiData[]>([]);
    const [parentsData, setParentsData] = useState<string[]>([]);
    const [kanjiData, setKanjiData] = useState<IKanjiData>({} as IKanjiData);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const recentItemKey: number = Object.keys(sessionStorage).filter(key => key.includes(RECENT_ITEM)).length;

    const { setFlashcardsPage } = useContext(Context) as IContext;

    useEffect(() => {
        setFlashcardsPage(false);
    }, []);

    // WINDOW SIZE
    useEffect(() => {
        const handleResize = (): void => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
    });

    // DATA RETRIEVAL FROM BACKEND
    const {
        getSearchData,
        getSuggestions,
        getByRadicals,
        getRadicalNames,
        getKanjiData,
    } = searchServices;

    // FORM & BUTTON CONTROLS
    function handleChangeSearchInput(e: ChangeEvent<HTMLInputElement>): void {
        e.preventDefault();
        const { value } = e.target;
        setSearchTerm(value);
        setSuggestions(getSuggestions(value));
        setRecentSearches(false);
    }

    const searchInputRef = useRef<HTMLInputElement | null>(null);

    function handleClickResetInput(e: MouseEvent<SVGElement>): void {
        e.preventDefault();
        setSearchTerm('');
        setSelectedRadicals({});
        setChart(!isChart);
        searchInputRef?.current!.focus();
    }

    function setRecentSearchCache(searchTerm: string): void {
        sessionStorage.setItem(`${RECENT_ITEM}_${recentItemKey}`, searchTerm);
    }

    function handleSubmitSearch(e: FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        if (searchTerm.trim().length > 0) {
            setDataDisplayed(false);
            setSearchData([]);
            setSearchData(getSearchData(searchTerm));
            setRecentSearchCache(searchTerm);
            setDataDisplayed(true);
            setSuggestions([]);
            setRadicalChart(false);
            setIsRadicalData(false);
            setRecentSearches(false);
            setKanjiData({} as IKanjiData);
        }
    }

    function handleClickSuggestions(e: MouseEvent<HTMLButtonElement>, meaning: string, kanji: string): void {
        e.preventDefault();
        setSearchData(getSearchData(meaning));
        setRecentSearchCache(meaning);
        setDataDisplayed(true);
        setSearchTerm(kanji);
        setSuggestions([]);
        setIsRadicalData(false);
        setRadicalChart(false);
        setKanjiData({} as IKanjiData);
    }

    function handleClickListResults(e: MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        setKanjiData({} as IKanjiData);
        if (searchData.length) {
            setIsResultsGrid(false);
            setDataDisplayed(true);
            setKanjiData({} as IKanjiData);
        }
    }

    function handleClickGridResults(e: MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        setKanjiData({} as IKanjiData);
        if (searchData.length) {
            setIsResultsGrid(true);
            setDataDisplayed(true);
            setKanjiData({} as IKanjiData);
        }
    }

    function handleClickRadicalChart(e: MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        setSuggestions([]);
        setRadicalChart(!isRadicalsChart);
        setIsRadicalData(false);
    }

    // function handleClickCloseRadicalChart(e: MouseEvent<HTMLButtonElement>) {
    //     e.preventDefault();
    //     setRadicalChart(false);
    //     setIsRadicalData(false);
    // }

    function searchForRadicals(radicalNames: string[], radicalChars: string[]): void {
        const [results, parents] = getByRadicals(radicalNames);
        setSearchData(results);
        setParentsData(parents);
        setRecentSearchCache(radicalChars.join('.')); // TODO CHECK LATER!!!!!
        setSearchTerm(radicalNames.join('.'));
        setSuggestions([]);
        setIsRadicalData(true);
        setDataDisplayed(true);
        setKanjiData({} as IKanjiData);
    }

    function handleClickRecentSearches(e: MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        setRecentSearches(!isRecentSearches);
        setSuggestions([]);
    }

    function handleClickRecentItem(e: MouseEvent<HTMLButtonElement>, searchItem: string): void {
        /* DESCRIPTION
            Gets search data of recent search items.
            If the search item string is concatened radicals from a Radical
            Chart search, it splits them into an array for radical search.
            Then, it finds the radical names and searches those names
            with the same function that Radical Chart uses.
        */
        e.preventDefault();

        if (searchItem.includes(',')) {
            const radicalNames = getRadicalNames(searchItem.split(','));
            console.table([{...radicalNames}]);
            const [searchResults, ] = getByRadicals(radicalNames);
            setSearchData(searchResults);
        } else {
            setSearchData(getSearchData(searchItem)); // middleware
            setSearchTerm(searchItem);
        }

        setRadicalChart(false);
        setIsRadicalData(false);
        setRecentSearches(false);
        setDataDisplayed(true);
        setKanjiData({} as IKanjiData);
    }

    function handleClickClearData(e: MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        setSearchTerm('');
        setDataDisplayed(false); 
        setSearchData([]);
        setParentsData([]);
        setKanjiData({} as IKanjiData);
        setSelectedRadicals({});
        setChart(!isChart);
    }

    function handleClickResults(e: MouseEvent<HTMLButtonElement>, id: number, kanji: string): void {
        e.preventDefault();
        const data: IKanjiData = getKanjiData(id);
        setKanjiData(data);
        setRecentSearchCache(kanji);
        setSearchTerm(kanji);
        setSuggestions([]);
        setRadicalChart(false);
        setIsRadicalData(false);
        setRecentSearches(false);
        setDataDisplayed(false);
        window.scrollTo(0, 0);
    }

    function handleClickBackToResults(e: MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        setRadicalChart(false);
        setRecentSearches(false);
        setKanjiData({} as IKanjiData);
        setDataDisplayed(true);
    }

    // SEARCH OPTIONS
    interface ISearchOptionsButtonProps {
        handleClick(...params: any[]): void;
        label: string | JSX.Element;
        className?: string;
        children?: ReactNode;
    }

    function SearchOptionsButton({ handleClick, label, className, children }: ISearchOptionsButtonProps): JSX.Element {
        return (
            <button className={className || 'search-options-button'} type="button" onClick={e => handleClick(e)}>
                {/* {icon && <img className="search-options-icon" src={icon} alt={label}/>} */}
                {children}
                {label}
            </button>
        );
    }

    const searchIconStyles = {
        margin: '2px 7px 0 0',
        color: darkMode ? 'var(--mediumGrayColor)' : '#5f5f5f',
    };

    const stylesExpandColapse = {
        marginLeft: '5px',
        color: darkMode ? 'var(--mediumGrayColor)' : '#5f5f5f',
    };

    function SearchOptions() {
        return (
            <div className="search-options-container">
                <SearchOptionsButton handleClick={handleClickListResults} label="List">
                    <IoIosList size="15px" style={searchIconStyles}/>
                </SearchOptionsButton>

                <SearchOptionsButton handleClick={handleClickGridResults} label="Grid">
                    <IoMdGrid size="15px" style={searchIconStyles}/>
                </SearchOptionsButton>

                <SearchOptionsButton handleClick={handleClickRecentSearches} label="Recents">
                    <IoIosTimer size="15px" style={searchIconStyles}/>
                </SearchOptionsButton>

                <SearchOptionsButton handleClick={handleClickClearData} label="Clear">
                    <IoIosRefresh size="15px" style={searchIconStyles}/>
                </SearchOptionsButton>
            </div>
        );
    }

    const suggestionsProps: ISuggestionsProps = { handleClickSuggestions, suggestions, darkMode };
    const recentSearchesProps = { handleClickRecentSearches, handleClickRecentItem, darkMode };

    // RADICAL CHART
    // const propsRadicalChart = {
    //   searchForRadicals,
    //   setDataDisplayed,
    //   handleClickCloseRadicalChart,
    //   setSearchTerm,
    //   searchData,
    //   setSearchData,
    //   isRadicalData,
    //   parentsData,
    //   windowWidth,
    // };

    function resetChart(e: MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setSelectedRadicals({});
        setSearchTerm('');
        setDataDisplayed(false);
        setSearchData([]);
        setChart(!isChart);
    }

    function createList(name: string, char: string): string[][] {
        const radicalList: string[][]= [[], []];
        const tempRadicals = { ...selectedRadicals };

        if (!(name in selectedRadicals)) {
            // adds radical name to list
            setSelectedRadicals({
                ...selectedRadicals,
                [name]: [name, char],
            });
            tempRadicals[name] = [name, char];
        } else {
            // removes radical name from list
            const { [name]: tempToDelete, ...restOfList } = selectedRadicals;
            setSelectedRadicals(restOfList);
            delete tempRadicals[name];
        }

        for (let radical in tempRadicals) {
            radicalList[0].push(tempRadicals[radical][0]); // radical name
            radicalList[1].push(tempRadicals[radical][1]); // radical character
        };

        return radicalList;
    }

    const radicalTabProps: IRadicalTabProps = {
        searchData,
        parentsData,
        isRadicalData,
        createList,
        searchForRadicals,
        radical: ['', ''],
    };

    function RadicalGrid(): JSX.Element {
        const girdItems: JSX.Element[] = [];

        strokesAndRadicals.forEach(stroke => {
            girdItems.push(<div key={stroke[0]} className="radical-strokes">{stroke[0]}</div>);
            stroke[1].forEach((radical, index) => {
                radicalTabProps.radical = radical;
                girdItems.push(<RadicalTab key={`${index}_${radical[0]}`} {...radicalTabProps}/>);
            });
        });

        return <div className="radical-grid">{girdItems}</div>;
    }

    interface IRadicalChart {
        className: string;
    }

    function RadicalChart({ className }: IRadicalChart): JSX.Element {
        return (
            <div className={className}>
                {/* /HACK SOLUTION: vacuous <div>s so React rerenders when chart flips. Otherwise, React doesn't detect a change to rerender.*/}
                {isChart ? RadicalGrid() : <div>{RadicalGrid()}</div>}
            </div>
        );
    }

    // SEARCH RESULTS
    const searchResultsProps: ISearchResultsProps = {
        isResultsGrid,
        searchData,
        handleClickResults,
        darkMode,
    };

    // BACK BUTTON
    const [fixButton, setFixButton] = useState(true);
    const buttonRef = useRef<boolean | null>(null);
    buttonRef.current = fixButton;

    useEffect(() => {
        const handleScroll = (): void => {
            const show = window.scrollY <= 700;
            if (buttonRef.current !== show) {
                setFixButton(show);
            }
        };
        document.addEventListener('scroll', _.throttle(handleScroll, 100));

        return () => document.removeEventListener('scroll', _.throttle(handleScroll, 100));
    }, []);

    function BackToSearchResults(): JSX.Element {
        return (
            <div className="search-back-fixed">
                <SearchOptionsButton className="search-back-button" handleClick={handleClickBackToResults} label="< Back"/>
            </div>
        );
    }

    // KANJIDATA
    const propsDictionaryCard: IDictionaryCardProps = {
        showMeaning: true,
        showAll: true,
        kanjiData,
        darkMode,
        searchFlashcard(searchTerm) {
            setSearchData(getSearchData(searchTerm));
            setSearchTerm(searchTerm);
            sessionStorage.setItem(`${RECENT_ITEM} _${recentItemKey}`, searchTerm);
            setRadicalChart(false);
            setIsRadicalData(false);
            setRecentSearches(false);
            setDataDisplayed(true);
            setKanjiData({} as IKanjiData);
            setSuggestions([]);
        }
    };

    // const DictionaryCardWithRouter = withRouter(Cards.DictionaryCard);
    function DictionaryCard (): JSX.Element {
        return (
            <>
                <div className="kanjiData-container">
                    <Cards.DictionaryCard {...propsDictionaryCard}/>
                    {fixButton && <BackToSearchResults/>}
                </div>
                <br/>
                <div className="tips-search-container results-card">
                    <div className="tips-search">
                        <p>Click on a meaning, radical, onyomi or kunyomi on the right side of a kanji card to search it.</p>
                    </div>
                </div>
            </>
        );
    }

    // console.log({ suggestions, searchTerm });

    return (
        <div className="page-container kanjisearch">
            <div className="kanjisearch-left-container">
                {/* TOP BAR */}
                <div className="kanjisearch-navbar">
                    {/* RADICAL SEARCH */}
                    {windowWidth > 1200 &&
                        <div className="kanjisearch-search-by-radicals">
                            <h5>SEARCH BY RADICALS</h5>
                            <div className="radical-button-container">
                                {searchData.length > 0 &&
                                    <SearchOptionsButton handleClick={resetChart} label="Reset">
                                        <IoIosRefresh size="15px" style={searchIconStyles}/>
                                    </SearchOptionsButton>
                                }
                            </div>
                        </div>
                    }

                    {/* SEARCH BAR */}
                    <div className="search-form-container">
                        <form className="search-form" onSubmit={e => handleSubmitSearch(e)}>
                            <input
                                className="search-input"
                                type="text"
                                name="search-input"
                                placeholder="Enter Search Terms..."
                                autoComplete="off"
                                value={searchTerm}
                                ref={searchInputRef}
                                onChange={handleChangeSearchInput}
                            />

                            <div className="search-reset">
                                {searchTerm &&
                                    <MdClose
                                        size="25px"
                                        style={{ marginTop: '-4px', color: darkMode ? 'var(--mutedRedColor)' : 'var(--blueColor)' }}
                                        onClick={(e: MouseEvent<SVGElement>) => handleClickResetInput(e)}
                                    />
                                }
                            </div>

                            <button className="search-button" type="submit">
                                <IoIosSearch size="25px" title="search kanji" style={{ margin: '0 0 0 8px', color: darkMode ? 'var(--mediumGrayColor)' : 'var(--lightGrayColor)' }}/>
                            </button>
                        </form>

                        <SearchOptions/>

                        {suggestions.length > 0 && searchTerm !== '' && <Suggestions {...suggestionsProps}/>}

                        {isRecentSearches && <RecentSearches {...recentSearchesProps}/>}
                    </div>
                </div>

                {/* BOTTOM BODY */}
                <div className="kanjisearch-body">
                    <RadicalChart className="radical-chart-desktop"/>

                    <SearchOptionsButton
                        className="search-options-radical-button"
                        handleClick={handleClickRadicalChart}
                        label={
                            <>
                                <span>部</span>
                                {!isRadicalsChart
                                    ? <>Show Radical Chart <MdExpandMore size="25px" style={stylesExpandColapse}/></>
                                    : <>Hide Radical Chart <MdExpandLess size="25px" style={stylesExpandColapse}/></>
                                }
                            </>
                        }
                    />

                    <div className="search-container">
                        <ErrorBoundary>
                            {isRadicalsChart && <RadicalChart className="radical-chart-mobile"/>}

                            {isDataDisplayed && <SearchResults {...searchResultsProps}/>}

                            {searchData.length === 0 &&  <Tips.KanjiSearch darkMode={darkMode}/>}

                            {Object.keys(kanjiData).length > 0 &&
                                <div className="dictionary-card-container">
                                    <DictionaryCard/>
                                </div>
                            }
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
            <Advertising.KanjiSearch />
        </div>
    );
}