import React, { useState, ChangeEvent, FormEvent } from 'react';
import { MdCheckBox } from 'react-icons/md';
import userAccountServices from 'middleware/userAccountServices';
import { IThemedComponentProps, IAPIBaseResponse } from 'interfaces/interfaces';

export default function ContactForm({ darkMode }: IThemedComponentProps): JSX.Element {
    const [isSubmitted, setSubmitted] = useState(false);
    const [subject, setSubject] = useState('General Question');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    async function handleSubmitContactForm(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();
        if (message) {
            setSubmitted(true);

            interface ISendContactMessageData {
                subject: string;
                name: string;
                email: string;
                message: string;
            }

            const data: ISendContactMessageData = { subject, name, email, message };
            await userAccountServices.forms.sendContactMessage<IAPIBaseResponse, ISendContactMessageData>(data);
        }
    }

    return (
        <>
            {isSubmitted
                ? // SUBMITTED MESSAGE
                    <div className="form-contact">
                        <div className="tips-search">
                            <h1>Thank you for your feedback. We will get back to you as soon as possible.</h1>
                            <h5>ご意見ありがとうございます。早く返事します。</h5>
                            <MdCheckBox size="50px" style={{ margin: '10px 0', color: darkMode ? 'var(--mediumGrayColor)' : 'var(--blueColor)' }}/>
                        </div>
                    </div>

                : // FORM
                    <form className="form-contact" name="contact" onSubmit={handleSubmitContactForm}>
                        {/* DROPDOWN MENU */}
                        <div className="form-group">
                            <label>
                                <p>Reason for contact</p>
                                <select
                                    className="form-control"
                                    name="subject"
                                    onSelect={(e: ChangeEvent<HTMLSelectElement>) => setSubject(e.currentTarget.value)}
                                >
                                    <option value="Question">General Question</option>
                                    <option value="Mnemonic">Suggest a Mnemonic Device</option>
                                    <option value="Typo">Report Typo</option>
                                    <option value="Bug">Report Bug</option>
                                    <option value="Comment">Other Comment or Concern</option>
                                </select>
                            </label>
                        </div>

                        {/* FORM ROWS */}
                        <div className="form-group">
                            <label>
                                <p>Name</p>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    placeholder="Optional"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value)}
                                />
                            </label>
                            <label className="email">
                                <p>Email</p>
                                <input
                                    className="form-control"
                                    type="email"
                                    name="email"
                                    placeholder="Optional"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
                                />
                            </label>
                        </div>

                        {/* TEXT AREA */}
                        <div className="form-group">
                            <label>
                                <p>Message</p>
                                <textarea
                                    className="form-textarea"
                                    name="message"
                                    rows={10}
                                    placeholder="Required. Max length 500 characters."
                                    maxLength={500}
                                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.currentTarget.value)}
                                    required
                                />
                            </label>
                        </div>

                        <button className="form-button-secondary" type="submit">Send</button>
                    </form>
            }
        </>
    );
};
