import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from './components';

// Main
import Home from './container/main/Home';
import { Profile } from './components';
import FourOhFour from './container/main/404NotFound';

// Language
import KanjiSearch from './container/language/KanjiSearch';
import Flashcards from './container/language/Flashcards';
import SelfStudy from './container/language/SelfStudy';
import Theory from './container/language/Theory';

// Site Info
import About from './container/siteinfo/About';
import Contact from './container/siteinfo/Contact';
import Donate from './container/siteinfo/Donate';
import Legal from './container/siteinfo/Legal';

// Authentication
import SignIn from './components/auth/login';
import Register from 'components/auth/register';


interface IRouterProps {
    darkMode: boolean;
}

export default function Router({ darkMode }: IRouterProps): JSX.Element {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home darkMode={darkMode}/>}/>
                <Route
                    path="/profile"
                    Component={withAuthenticationRequired(Profile, {
                        onRedirecting: () => <Loading />,
                    })}
                />
                <Route path="/language/kanjisearch" element={<KanjiSearch darkMode={darkMode}/>}/>
                <Route path="/language/flashcards" element={<Flashcards darkMode={darkMode}/>}/>
                <Route path="/language/selfstudy" element={<SelfStudy darkMode={darkMode}/>}/>
                <Route path="/language/theory" element={<Theory/>}/>
                <Route path="/siteinfo/about" element={<About/>}/>
                <Route path="/siteinfo/contact" element={<Contact darkMode={darkMode}/>}/>
                <Route path="/siteinfo/donate" element={<Donate/>}/>
                <Route path="/siteinfo/legal" element={<Legal/>}/>
                <Route path="/auth/signin" element={<SignIn/>}/>
                <Route path="/auth/register" element={<Register/>}/>
                <Route path="/*" element={<FourOhFour/>}/>
            </Routes>
        </>
    );
};