import React from 'react';
import {
    IoIosList,
    IoMdGrid,
    IoIosTimer
} from 'react-icons/io';
import { IThemedComponentProps } from 'interfaces/interfaces';

interface IFlashcardsProps {
    level: string;
}

interface IFlashcardTipsProps {
    [key: string]: JSX.Element,
}

const Tips = {
    // SEARCH TIPS
    KanjiSearch: function({ darkMode }: IThemedComponentProps): JSX.Element {
        const iconStyles = { marginTop: '5px', color: darkMode ? 'var(--mediumGrayColor)' : '#212435' };

        const kanjiSearchTips = [
            (<div className="tips-search" key="kanjiSearch_tip1">
                <p>Kanji Search is a unique searchable dictionary of the 2,500 most common kanji and radicals along with their associated mnemonics used in the flashcard app.</p>
            </div>),
        
            (<div className="tips-search" key="kanjiSearch_tip2">
                <p> Type Japanese or English into the search bar.</p>
                <p> Search by kanji, meaning, radical, onyomi or kunyomi reading: &#8239;</p>
                <br/><br/>
                <div>
                <p><em>Kanji example: &#8239; 水</em></p>
                <p><em>Meaning example: &#8239; water</em></p>
                <p><em>Radical example: &#8239; 亅</em></p>
                <p><em>Onyomi example: &#8239; SUI</em></p>
                <p><em>Kunyomi example: &#8239; mizu</em></p>
                </div>
            </div>),
        
            (<div className="tips-search" key="kanjiSearch_tip3">
                <p> Search radicals by separating them with periods:</p>
                <p><em>Example: field.splash.ten will return 油 演 滝</em></p>
                <p><em>Example: pike.tree will return 柔</em></p>
            </div>),
        
            (<div className="tips-search" key="kanjiSearch_tip4">
                <p>Select &#8239;</p>
                <IoIosList size="20px" style={iconStyles} title="list results"/>
                <p>&#8239; List or &#8239;</p>
                <IoMdGrid size="20px" style={iconStyles} title="grid results"/>
                <p>&#8239; Grid to return to search results.</p>
            </div>),
        
            (<div className="tips-search" key="kanjiSearch_tip5">
                <p>Select &nbsp; 部 &nbsp; Radicals to display radical chart.</p>
            </div>),
        
            (<div className="tips-search" key="kanjiSearch_tip6">
                <p>Select &#8239;</p>
                <IoIosTimer size="20px" style={iconStyles} title="grid results"/>
                <p> &#8239; Recents to view search history.</p>
            </div>),
        ];

        return <div className="tips-search-container">{kanjiSearchTips}</div>;
    },

    // FLASHCARD TIPS
    SelfStudy: function({ level }: IFlashcardsProps):JSX.Element {
        const selfstudyTips: IFlashcardTipsProps = {
            instructions1: (
                <div className="selfstudy-tips-item">
                    <p>Choose either Study or Test mode. In Study mode select Meaning-Radicals to study the structure of kanji or select Onyomi-Kunyomi to study the pronunciations. In Test mode select which individual concepts to study.</p>
                </div>
            ),

            instructions2: (
                <div className="selfstudy-tips-item">
                    <p>Select a difficulty level for different sets. Click on a set number to start!</p>
                </div>
            ),

            instructions3: (
                <div className="selfstudy-tips-item">
                    <p>The flashcards have three levels, each divided into sets of 100 flashcards for a total of 2,500 kanji.</p>
                </div>
            ),

            beginner: (
                <div className="selfstudy-tips-item">
                    <p><span className="heading-beginner">BEGINNER</span> teaches the 1,000 most basic kanji and radicals. This covers JLPT N5 and N4 kanji and is enough to read over 50% of Japanese text!</p>
                </div>
            ),

            intermediate: (
                <div className="selfstudy-tips-item">
                    <p><span className="heading-intermediate">INTERMEDIATE</span> teaches the next 1,000 Kanji. This covers JLPT N3 and N2 kanji, almost all of N1 Kanji, and is enough to read over 95% of Japanese text!</p>
                </div>
            ),

            advanced: (
                <div className="selfstudy-tips-item">
                    <p><span className="heading-advanced">ADVANCED</span> teaches the final 500 most useful Kanji. This covers all JLPT N1 kanji, enough to read well over 99% of Japanese text!</p>
                </div>
            ),

            custom: (
                <div className="selfstudy-tips-item">
                    <p><span className="heading-custom">CUSTOM SET</span> allows your to enter up to 100 kanji to create custom sets to study. Type kanji separated by commas or select one from the entire list of kanji.</p>
                </div>
            ),
        };

        return (
            <div className="selfstudy-tips">
                {selfstudyTips.instructions1}
                {selfstudyTips.instructions3}
                {selfstudyTips[level]}
                {selfstudyTips.instructions2}
            </div>
        );
    },
};

export default Tips;