import React, { useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {
    MdBuild,
    MdWarning,
    MdNotInterested,
    MdSentimentVeryDissatisfied,
} from 'react-icons/md';
import { Context } from 'context/context';
import { IContext } from 'interfaces/interfaces';

export default function FourOhFour(): JSX.Element {
    const { setFlashcardsPage } = useContext(Context) as IContext;

    useEffect(() => {
        setFlashcardsPage(false);
    }, []);

    const iconSize = '100px';

    return (
        <div className="page-container">
            <div className="page-content"> 
                <div className="page-title">
                    <h6>404 Service Error</h6>
                    サーバー エラー
                    <h6>We can't seem to find what you're looking for.</h6>
                    ファイルまたはディレクトリが見つかりません。
                </div>
                <div className="fourOhFour-icon-container">
                    <MdBuild size={iconSize} className="fourOhFour-icon"/>
                    <MdNotInterested size={iconSize} className="fourOhFour-icon"/>
                </div>
                <div className="fourOhFour-icon-container">
                    <MdSentimentVeryDissatisfied size={iconSize} className="fourOhFour-icon"/>
                    <MdWarning size={iconSize} className="fourOhFour-icon"/>
                </div>
                <div className="fourOhfour-message">
                    <h1>
                        You've landed on a URL that doesn't seem to exist on Kanji Remastered.
                        If you think this is an error on our part, please let us know <Link to="/siteinfo/contact">here</Link>.
                        Or navigate to another page from the menu.
                    </h1>
                </div>
            </div>
        </div>
    );
};