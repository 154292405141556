import React from 'react';
import { Loading } from './';
import { useAuth0, withAuthenticationRequired, User } from '@auth0/auth0-react';

const Profile = (): JSX.Element => {
    const { user } = useAuth0();
    const { name, picture, email } = user as User;

    return (
        <div className="page-container">
            <div>
                <img className="rounded-circle img-fluid my-4" src={picture} alt="Profile"/>
            </div>

            <div>
                <br/>
                <h3>{name}</h3>
                <p>{email}</p>
            </div>
        </div>
    );
};

export default withAuthenticationRequired(Profile, {
    onRedirecting: () => <Loading/>,
});
