import { StrokeAndRadical } from 'interfaces/interfaces';

// TODO
// const strokesAndRadicals: IStrokesAndRadical[] = [
//     {
//         stroke: 1,
//         radicals: [
//             ['one','一'],
//             ['bar','｜'],
//             ['drop','丶'],
//             ['slash','丿'],
//             ['barb','亅'],
//             ['scythe','𠄌'],
//             ['bracket','𠃊'],
//             ['hook','乚'],
//             ['smart','乙 '],
//             ['fuel','フ'],
//         ],
//     },
// ];

const strokesAndRadicals: StrokeAndRadical[] = [
    [1, [
        ['one','一'],
        ['bar','｜'],
        ['drop','丶'],
        ['slash','丿'],
        ['barb','亅'],
        ['scythe','𠄌'],
        ['bracket','𠃊'],
        ['hook','乚'],
        ['smart','乙 '],
        ['fuel','フ'],
    ]],

    [2, [
        ['two','二'],
        ['lid','亠'],
        ['ten','十'],
        ['letter T','丅'],
        ['street','丁'],
        ['upright man','亻'],
        ['person','人'],
        ['enter','入'],
        ['roof','𠆢'],
        ['horns','丷'],
        ['eight','八'],
        ['legs','儿'],
        ['ankles','丿|'],
        ['table','几'],
        ['staple','冂'],
        ['hat','冖'],
        ['open box','凵'],
        ['rack','匚'],
        ['koala','コ'],
        ['yuan','ユ'],
        ['divination','⼘'],
        ['icicle','冫'],
        ['power','力'],
        ['sword','刀'],
        ['dagger','刂'],
        ['seal','卩'],
        ['fork','丩'],
        ['wrap','勹'],
        ['posses','乃'],
        ['obstruct','丂'],
        ['spoon','匕'],
        ['cliff','厂'],
        ['cane','𠂉'],
        ['particle','丆'],
        ['awning','𠂇'],
        ['melon','メ'],
        ['scissors','乂'],
        ['again', '又'],
        ['kudos','ク'],
        ['private','厶'],
        ['mammal','マ'],
        ['complete','了'],
    ]],

    [3, [
        ['three','三'],
        ['strands','彡'],
        ['river','川'],
        ['rapids','巛'],
        ['mouth','口'],
        ['box','囗'],
        ['plant','艹'],
        ['manhole','䒑'],
        ['sparkles','⺌'],
        ['industry', '工'],
        ['ground','土'],
        ['scholar','士'],
        ['pass away','亡'],
        ['late','夂'],
        ['evening','夕'],
        ['kettle', 'ケ'],
        ['loiter','彳'],
        ['by hand','扌'],
        ['animal','犭'],
        ['beating heart','忄'],
        ['chop','丬'],
        ['town','⻏'],
        ['splash','氵'],
        ['small', '小'],
        ['big','大'],
        ['woman','女'],
        ['child','子'],
        ['crown','宀'],
        ['inch','寸'],
        ['crooked','尢'],
        ['corpse','尸'],
        ['mountain','山'],
        ['sprout','屮'],
        ['towel','巾'],
        ['dry','干'],
        ['letter H','廾'],
        ['precipice','广'],
        ['motion','⻌'],
        ['stride','廴'],
        ['lance','弋'],
        ['self','己'],
        ['bow','弓'],
        ['string','幺'],
        ['swine','彑'],
        ['bristles','彐'],
    ]],

    [4, [
        ['king','王'],
        ['spear','戈'],
        ['door','戸'],
        ['hand','手'],
        ['branch','支'],
        ['whip','攵'],
        ['sentence','文'],
        ['volume','斗'],
        ['axe','斤'],
        ['clan','氏'],
        ['direction','方'],
        ['pirate','无'],
        ['sun','日'],
        ['moon','月'],
        ['snake','巴'],
        ['tree','木'],
        ['lack','欠'],
        ['stop','止'],
        ['death','歹'],
        ['weapon','殳'],
        ['mother','⺟'],
        ['do not','毋'],
        ['denied','勿'],
        ['compare','比'],
        ['fur','毛'],
        ['steam','气'],
        ['water','水'],
        ['fire','火'],
        ['embers','灬'],
        ['campfire','⺗'],
        ['heart','心'],
        ['claw','爪'],
        ['father','父'],
        ['twine','爻'],
        ['nerd','礻'],
        ['picket','龷'],
        ['well','井'],
        ['litter','廿'],
        ['log','爿'],
        ['fragment','片'],
        ['fang','牙'],
        ['cow','牛'],
        ['superb','尤'],
        ['dog','犬'],
        ['aged','耂'],
        ['beforehand','予'],
        ['bill','𠫓'],
        ['blab','云'],
        ['hope','ホ'],
    ]],

    [5, [
        ['ball','玉'],
        ['melon','瓜'],
        ['tile','瓦'],
        ['sweet','甘'],
        ['birth','生'],
        ['utilize','用'],
        ['field','田'],
        ['hiker','𤴓'],
        ['tiara','龸'],
        ['disease','疒'],
        ['tent','癶'],
        ['twins','𡗗'],
        ['white','白'],
        ['peel','皮'],
        ['dish','皿'],
        ['eye','目'],
        ['net','罒'],
        ['giant','巨'],
        ['pike','矛'],
        ['arrow','矢'],
        ['stone','石'],
        ['suit','衤'],
        ['hole','穴'],
        ['stand up','立'],
        ['bamboo','竹'],
        ['display','示'],
        ['grain','禾'],
        ['rice','米'],
        ['thread','糸'],
        ['mystery','玄'],
        ['shower cap','㐱'],
        ['footprint','禸'],
        ['canister','缶'],
        ['lightning','电'],
        ['lowly','氐'],
    ]],

    [6, [
        ['sheep','羊'],
        ['feather','羽'],
        ['rake','而'],
        ['plough','耒'],
        ['ear','耳'],
        ['brush','聿'],
        ['meat','肉'],
        ['official','臣'],
        ['oneself','自'],
        ['destination','至'],
        ['mortar','臼'],
        ['tongue','舌'],
        ['ballet','舛'],
        ['boat','舟'],
        ['adequate','艮'],
        ['bureaucracy','㠯'],
        ['color','色'],
        ['feline','虍'],
        ['insect','虫'],
        ['blood','血'],
        ['to go','行'],
        ['clothes','衣'],
        ['boar','亥'],
        ['lumberjack','𠂢'],
        ['west','西'],
        ['Wild West','覀'],
        ['penetrate','𢦏'],
        ['coil','龹'],
    ]],

    [7, [
        ['see','見'],
        ['money','貝'],
        ['corner','角'],
        ['say','言'],
        ['valley','谷'],
        ['bean','豆'],
        ['pig','豕'],
        ['badger','豸'],
        ['red','赤'],
        ['run','走'],
        ['foot','足'],
        ['flesh','身'],
        ['car','車'],
        ['spicy','辛'],
        ['dawn','辰'],
        ['booze','酉'],
        ['divide','釆'],
        ['village','里'],
        ['perimeter','甬'],
    ]],

    [8, [
        ['gold','金'],
        ['prefecture','㑒'],
        ['cognition','侖'],
        ['long','長'],
        ['gate','門'],
        ['dam','阜'],
        ['slave','隶'],
        ['suffocate','奄'],
        ['chimp', '禺'],
        ['rooster','隹'],
        ['rain','雨'],
        ['knoll','岡'],
        ['blue','青'],
        ['mistake','非'],
        ['similar','斉'],
        ['clear','龺'],
        ['shout','咅'],
    ]],

    [9, [
        ['surface','面'],
        ['pelt','革'],
        ['leather','韋'],
        ['dam','阜'],
        ['onion','韭'],
        ['sound','音'],
        ['wind','風'],
        ['fly','飛'],
        ['eat','食'],
        ['dusk', '复'],
        ['neck','首'],
        ['page','頁'],
        ['fragrance','香'],
        ['pullstring','兹'],
    ]],

    [10, [
        ['horse','馬'],
        ['eagle','隺'],
        ['bone','骨'],
        ['tall','高'],
        ['hair','髟'],
        ['tripod','鬲'],
        ['hen','隹'],
        ['demon','鬼'],
        ['dragon','竜'],
        ['hardship','𦰩'],
    ]],

    [11, [
        ['fish','魚'],
        ['horse','鳥'],
        // ['salt','鹵'],
        ['deer','鹿'],
        // ['hemp','麻'],
        ['yellow','黄'],
        ['black','黒'],
        ['turtle','亀'],
    ]],

    [12, [
        ['tooth','歯'],
        // ['embroidery','黹'],
        ['helmet','尞'],
    ]],

    // [13, [
    //     ['frog','黽'],
    //     ['nose','鼻'],
    //   ],
    // ]
];

export default strokesAndRadicals;