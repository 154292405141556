import React from 'react';
import Odometer from 'react-odometerjs';

interface IScoreOdometerProps {
    digits: number[];
}

export default function ScoreOdometer({ digits }: IScoreOdometerProps): JSX.Element {
    return (
        <div className="score-odometer">
            <div className="score-odometer-digit">
                <Odometer value={digits[0]} format="(,ddd)" duration={1000}/>
            </div>
            <div className="score-odometer-digit">
                <Odometer value={digits[1]} format="(,ddd)" duration={1000}/>
            </div>
            <div className="score-odometer-digit">
                <Odometer value={digits[2]} format="(,ddd)" duration={1000}/>
            </div>
            <div className="score-odometer-digit">
                <Odometer value={digits[3]} format="(,ddd)" duration={1000}/>
            </div>
            <div className="score-odometer-digit">
                <Odometer value={digits[4]} format="(,ddd)" duration={1000}/>
            </div>
            <div className="score-odometer-digit">
                <Odometer value={digits[5]} format="(,ddd)" duration={1000}/>
            </div>
            <div className="score-odometer-digit">
                <Odometer value={digits[6]} format="(,ddd)" duration={1000}/>
            </div>
            <div className="score-odometer-digit">
                <Odometer value={digits[7]} format="(,ddd)" duration={1000}/>
            </div>
            <div className="score-odometer-digit">
                <Odometer value={digits[8]} format="(,ddd)" duration={1000}/>
            </div>
            <div className="score-odometer-digit">
                <Odometer value={digits[9]} format="(,ddd)" duration={1000}/>
            </div>
        </div>
    );
};