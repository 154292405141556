import React, { useState, Dispatch, MouseEvent, SetStateAction } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import pseudoRadicals from 'helpers/pseudoRadicals';
import { IThemedComponentProps } from 'interfaces/interfaces';

interface IKanjiPickerProps extends IThemedComponentProps {
    allKanji: string[];
    selectedKanjis: string;
    setSelectedKanjis: Dispatch<SetStateAction<string>>;
}

export default function KanjiPicker({ allKanji, selectedKanjis, setSelectedKanjis, darkMode }: IKanjiPickerProps): JSX.Element {
    const [displayBeginnerKanji, setDisplayBeginnerKanji] = useState(true);
    const [displayIntermediateKanji, setDisplayIntermediateKanji] = useState(false);
    const [displayAdvancedKanji, setDisplayAdvancedKanji] = useState(false);
    const iconColor = '#5f5f5f';

    function handleClickKanjiPicker(e: MouseEvent<HTMLButtonElement>, kanji: string): void {
        e.preventDefault();
        if (!selectedKanjis.includes(kanji)) {
            setSelectedKanjis(selectedKanjis => `${selectedKanjis}${kanji},`);
        }
    }

    interface IKanjiTabButtonProps {
        kanji: string;
    }

    function KanjiTabButton({ kanji }: IKanjiTabButtonProps): JSX.Element {
        return (
            <button className="custom-kanji-button" onClick={e => handleClickKanjiPicker(e, kanji)}>
                {pseudoRadicals.kanji.includes(kanji) ?
                    <img src={pseudoRadicals.images[pseudoRadicals.kanji.indexOf(kanji)][darkMode ? 'gray' : 'black']} alt="kanji character"/> :
                    <>{kanji}</>
                }
            </button>
        );
    }

    interface IKanjiTabProps {
        kanji: string;
        index: number;
        increment: number;
    }

    function KanjiTab({ kanji, index, increment }: IKanjiTabProps): JSX.Element {
        if (index % 100 === 0) {
            //SET NUMBER ON TOP FOLLOWED BY FIRST KANJI
            return (
                <>
                    <h5>{`SET ${index/100 + 1 + increment}`}</h5>
                    <KanjiTabButton kanji={kanji}/>
                </>
            );
        }
        // REMAINDER OF KANJI IN THAT SET
        return <KanjiTabButton kanji={kanji}/>;
    }

    return (
        <div className="custom-kanji-picker">
            <button className="tab-button expand-kanji-list beginner" onClick={() => setDisplayBeginnerKanji(!displayBeginnerKanji)}>
                {displayBeginnerKanji ? 'Hide' : 'Show'} Beginner Kanji
                {!displayBeginnerKanji
                    ? <MdExpandMore size="25px" color={iconColor}/>
                    : <MdExpandLess size="25px" color={iconColor}/>
                }
            </button>

            {displayBeginnerKanji &&
                <>
                    <div className="heading-beginner kanji-picker--top">BEGINNER</div>
                    {allKanji.slice(0, 1000).map((kanji, index) => (
                        <KanjiTab kanji={kanji} index={index} increment={0} key={`CustomKanjiBtn_${index}_${kanji}`}/>
                    ))}
                </>
            }

            {/* INTERMEDIATE */}
            <button className="tab-button expand-kanji-list" onClick={() => setDisplayIntermediateKanji(!displayIntermediateKanji)}>
                {displayIntermediateKanji ? 'Hide' : 'Show'} Intermediate Kanji
                {!displayIntermediateKanji
                    ? <MdExpandMore size="25px" color={iconColor}/>
                    : <MdExpandLess size="25px" color={iconColor}/>
                }
            </button>

            {displayIntermediateKanji &&
                <>
                    <div className="heading-intermediate kanji-picker--bottom">INTERMEDIATE</div>
                    {allKanji.slice(1000, 2000).map((kanji, index) => (
                        <KanjiTab kanji={kanji} index={index} increment={10} key={`CustomKanjiBtn_${index}_${kanji}`}/>
                    ))}
                </>
            }

            {/* ADVANCED */}
            <button className="tab-button expand-kanji-list" onClick={() => setDisplayAdvancedKanji(!displayAdvancedKanji)}>
                {displayAdvancedKanji ? 'Hide' : 'Show'} Advanced Kanji
                {!displayAdvancedKanji
                    ? <MdExpandMore size="25px" color={iconColor}/>
                    : <MdExpandLess size="25px" color={iconColor}/>
                }
            </button>

            {displayAdvancedKanji &&
                <>
                    <div className="heading-advanced kanji-picker--bottom">ADVANCED</div>
                    {allKanji.slice(2000, 3000).map((kanji, index) => (
                        <KanjiTab kanji={kanji} index={index} increment={20} key={`CustomKanjiBtn_${index}_${kanji}`}/>
                    ))}
                </>
            }
        </div>
    );
}