import React, { Component, ErrorInfo, ReactNode } from 'react';

interface IProps {
    children: ReactNode;
}
  
interface IState {
    hasError: boolean;
}

export default class ErrorBoundary extends Component<IProps, IState> {
    public state: IState = {
        hasError: false
    };
    
    public static getDerivedStateFromError(_: Error): IState {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    
    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // Catch errors in any components below and re-render with error message
        console.error('Uncaught error:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // Error path
            return (
                <div className="error-boundary">
                    <div className="tips-search">
                        <h3>Opps! Something went wrong. Please reload the page to try again!</h3>
                        <br/>
                        <h5>ごめん! ブラウザをリロードして再度お試しください。</h5>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}