import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdSearch, MdLibraryBooks, MdAttachMoney } from 'react-icons/md';
import { FaBrain } from 'react-icons/fa';
import { Context } from 'context/context';
import {
    studyFlashcardLight,
    studyFlashcardDark,
    testFlashcardLight,
    testFlashcardDark
} from 'assets/images';
import { IThemedComponentProps, IContext } from 'interfaces/interfaces';

interface IFeature {
    text: string;
}

interface Section {
    heading: string;
    path: string;
    className: string;
    features: IFeature[];
};

interface ISectionProps extends Section {
    icon: JSX.Element;
}

interface ISections {
    [key: string]: Section;
}

const sections: ISections = {
    flashcards: {
        heading: 'FLASHCARDS',
        path: '/language/flashcards',
        className: 'flashcards',
        features: [
            { text: `Learn the 3,000 most common Kanji in one year with the most advanced flashcard sytem for FREE!` },
            { text: `The flashcard web app version is 100% browser based. No app download required!` },
        ],
    },

    kanjiSearch: {
        heading: 'KANJI SEARCH',
        path: '/language/kanjisearch',
        className: 'kanjisearch',
        features: [
            { text: `Kanji Search is an exclusive dictionary of the 3,000 most common kanji which appear in the flashcard app.` },
            { text: `A one-of-a-kind search feature also returns all other kanji derived from the searched kanji or radical.` },
        ]
    },

    theory: {
        heading: 'THEORY',
        path: '/language/theory',
        className: 'theory',
        features: [
            { text: `Learn the simple theory behind Kanji Remastered's flashcards so you can remember kanji for life!` },
            { text: `Learn how to combine Kanji Remastered with other Japanese language resources.` },
        ]
    },

    donate: {
        heading: 'DONATE',
        path: '/siteinfo/donate',
        className: 'donate',
        features: [
            { text: `Donate to help keep this content free for all and expanding!` },
            { text: `Kanji Remastered is an independently owned and managed web project.` },
        ]
    }
};

function Section({
    className,
    heading,
    path,
    icon,
    features,
}: ISectionProps): JSX.Element {
    return (
        <div className={`home-section ${className}`}>
            <div className="home-heading">
                <Link to={path}>{heading}</Link>
            </div>

            <div className="home-section-subcontainer">
                {features.map((feature, index) =>
                    <Link to={path} key={`${feature.text}_${index}`}>
                        <div className="home-feature">{feature.text}</div>
                    </Link>
                )}
                {icon}
            </div>
        </div>
    )
}

export default function Home({ darkMode }: IThemedComponentProps): JSX.Element {
    const { setFlashcardsPage } = useContext(Context) as IContext;

    useEffect(() => {
        setFlashcardsPage(false);
    }, []);

    const iconSize = '175px';

    return (
        <div className="home-container">
            <div className="home-splash">
                <div className="home-intro desktop">
                    <Link to="/language/flashcards">
                        LEARN 2,500
                        <br/>
                        JAPANESE KANJI
                    </Link>
                    <div className="home-intro-description">
                        <h4>Learn the most commonly used Kanji in one year and a half with the most advanced flashcard system for FREE!</h4>
                    </div>
                    <Link to="/language/flashcards">
                        <div className="home-boxes-desktop">
                            <div className="home-kanji-box">漢字</div>
                            <h4>Learn Kanji</h4>
                            <h4>Read Japanese</h4>
                        </div>
                    </Link>
                </div>
            </div>

            <div className="home-intro mobile">
                <Link to="/language/flashcards">
                    LEARN 2,500
                    <br/>
                    JAPANESE KANJI
                </Link>
                <div className="home-intro-description">
                    <h4>Learn the most commonly used Kanji in one year and three months with the most advanced flashcard system for FREE!</h4>
                </div>
                <Link to="/language/flashcards">
                    <div className="home-boxes-desktop">
                        <div className="home-kanji-box">漢字</div>
                        <h4>Learn Kanji</h4>
                        <h4>Read Japanese</h4>
                    </div>
                </Link>
            </div>

            <div className="home-screenshots">
                <div className="home-screenshots-box">
                    STUDY NEW KANJI
                    <Link to={sections.flashcards.path}>
                        <img className="home-screenshots-img" src={darkMode ? studyFlashcardDark : studyFlashcardLight} alt="study mode flashcards screenshot"/>
                    </Link>
                </div>

                <div className="home-screenshots-box">
                    TEST YOUR KNOWLEDGE
                    <Link to={sections.flashcards.path}>
                        <img className="home-screenshots-img" src={darkMode ? testFlashcardDark : testFlashcardLight} alt="test mode flashcards screenshot"/>
                    </Link>
                </div>
            </div>

            <div className="home-sections-features">
                <Section
                    key={sections.flashcards.heading}
                    className={sections.flashcards.className}
                    heading={sections.flashcards.heading}
                    path={sections.flashcards.path}
                    icon={<MdLibraryBooks className="home-section-icon" size={iconSize}/>}
                    features={sections.flashcards.features}
                />

                <Section
                    key={sections.kanjiSearch.heading}
                    className={sections.kanjiSearch.className}
                    heading={sections.kanjiSearch.heading}
                    path={sections.kanjiSearch.path}
                    icon={<MdSearch className="home-section-icon" size={iconSize}/>}
                    features={sections.kanjiSearch.features}
                />

                <Section
                    key={sections.theory.heading}
                    className={sections.theory.className}
                    heading={sections.theory.heading}
                    path={sections.theory.path}
                    icon={<FaBrain className="home-section-icon" size={iconSize}/>}
                    features={sections.theory.features}
                />
            </div>

            <div className="home-section donate">
                <div className="home-heading">
                    <Link to={sections.donate.path}>{sections.donate.heading}</Link>
                </div>

                <div className="home-section-subcontainer donate">
                    {sections.donate.features.map((feature, index) =>
                        <Link to={sections.donate.path} key={`${feature.text}_${index}}`}>
                            <div className="home-feature">{feature.text}</div>
                        </Link>
                    )}
                    <Link to={sections.donate.path}>
                        <MdAttachMoney className="home-section-icon" size={iconSize}/>
                    </Link>
                </div>
            </div>
        </div>
    );
};