import {
    beastBlack,
    beastGray,
    beastWhite,
    ventBlack,
    ventGray,
    ventWhite,
    hushBlack,
    hushGray,
    hushWhite,
    oneMouthBlack,
    oneMouthGray,
    oneMouthWhite,
    impactBlack,
    impactGray,
    impactWhite,
    sphinxBlack,
    sphinxGray,
    sphinxWhite,
    steakBlack,
    steakGray,
    steakWhite,
    bansheeBlack,
    bansheeGray,
    bansheeWhite,
    kangarooBlack,
    kangarooGray,
    kangarooWhite,
} from '../assets/images/pseudoRadicals';

const pseudoRadicals = {
    kanji: [
        'BEAST',
        'VENT',
        'HUSH',
        'ONEMOUTH',
        'IMPACT',
        'SPHINX',
        'STEAK',
        'BANSHEE',
        'KANGAROO',
    ],

    meanings: [
        'beast',
        'vent',
        'hush',
        'one mouth',
        'impact',
        'sphinx',
        'steak',
        'banshee',
        'kangaroo',
    ],

    images: [
        {
            black: beastBlack,
            gray: beastGray,
            white: beastWhite,
        },
        {
            black: ventBlack,
            gray: ventGray,
            white: ventWhite,
        },
        {
            black: hushBlack,
            gray: hushGray,
            white: hushWhite,
        },
        {
            black: oneMouthBlack,
            gray: oneMouthGray,
            white: oneMouthWhite,
        },
        {
            black: impactBlack,
            gray: impactGray,
            white: impactWhite,
        },
        {
            black: sphinxBlack,
            gray: sphinxGray,
            white: sphinxWhite,
        },
        {
            black: steakBlack,
            gray: steakGray,
            white: steakWhite,
        },
        {
            black: bansheeBlack,
            gray: bansheeGray,
            white: bansheeWhite,
        },
        {
            black: kangarooBlack,
            gray: kangarooGray,
            white: kangarooWhite,
        }
    ],
};

export default pseudoRadicals;