import React, { useContext, useEffect} from 'react';
import { MdAttachMoney, MdLink } from 'react-icons/md';
import { SiBuymeacoffee, SiPatreon } from 'react-icons/si';
import { Context } from 'context/context';
import { IContext } from 'interfaces/interfaces';

export default function Donate(): JSX.Element {
    const { setFlashcardsPage } = useContext(Context) as IContext;

    useEffect(() => {
        setFlashcardsPage(false);
    }, []);

    return (
        <div className="page-container">
            <div className="page-content">
                <h6>DONATE</h6>
                <MdAttachMoney size="110px" className="siteinfo-red-icon"/>

                <p>Please support Kanji Remastered with a donation to keep this site growing. Even a little is greatly appreciated!</p>
                <br/>
                <p>Kanji Remastered is an independently managed web project.</p>
                <br/>

                <h5>PATREON</h5>
                <br/>
                <a
                    className="donate-link"
                    href="https://www.patreon.com/kanjiremastered"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className="donate-box">
                        <SiPatreon size="45px" className="donate-link-icon"/>
                        <br/>
                        <span className="donate-link-text">Patreon.com/KanjiRemastered</span>
                    </div>
                </a>

                <h5>BUY ME A COFFEE</h5>
                <br/>
                <a
                    className="donate-link"
                    href="https://www.buymeacoffee.com/kanjiremastered"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className="donate-box">
                        <SiBuymeacoffee size="50px" className="donate-link-icon"/>
                        <br/>
                        <span className="donate-link-text">BuyMeaCoffee.com/KanjiRemastered</span>
                    </div>
                </a>
                <br/><br/>

                <h6>LINK TO US</h6>
                <MdLink size="100px" className="siteinfo-red-icon"/>

                <p>If you like this site, please link us on yours. That's even better than a cash donation (sort of)!</p>
                <br/>

                <p>https://www.kanjiresmastered.com</p>
                <br/>

                <p>どもありがとうございます!</p>
                <p>Domo arigatō gozaimasu!</p>
            </div>
        </div>
    );
};